import { Component, Inject } from '@angular/core';
import { HttpEditionService } from "../services/http/edition.service";
import { EditionModelConfiguration, EditionRecordInterface } from "../models/edition";
import { Router } from "@angular/router";
import { AdSpendingModelConfiguration, AdSpendingRecordInterface } from "../models/ad_spending";
import { HttpAdSpendingsService } from "../services/http/ad_spendings.service";

@Component({
    selector: 'wefra-dashboard',
    templateUrl: './dashboard.component.html',
    standalone: false
})
export class DashboardComponent {


  adspendingModelConfig = AdSpendingModelConfiguration
  adspendingsLimitToFields: string[] = ["id", "headline_id", "magazine_id", "product_id", "date", "is_multi", "created_by", "modified_by", "created_at", "modified_at"];
  adspendingsMotiveProductMismatch?: AdSpendingRecordInterface[]
  adspendingsProductCompanyMismatch?: AdSpendingRecordInterface[]

  editionModelConfig = EditionModelConfiguration
  editionsWithoutPubdate?: EditionRecordInterface[]
  editionsNotCaptured?: EditionRecordInterface[]
  editionsCapturing?: EditionRecordInterface[]
  editionsLastHandled?: EditionRecordInterface[]
  editionsLimitToFields: string[] = ['capture_state', 'id', 'file_basename', 'wasmuth_edition_name', 'magazine_id', 'wasmuth_publication_date']

  constructor(
    @Inject(HttpEditionService) public editionService: HttpEditionService,
    @Inject(HttpAdSpendingsService) public adspendingService: HttpAdSpendingsService,
    private router: Router,
  ) {

    this.adspendingService.getProductCompanyMismatchRecords().subscribe(records => {
      this.adspendingsProductCompanyMismatch = records
    })

    this.adspendingService.getMotiveProductMismatchRecords().subscribe(records => {
      this.adspendingsMotiveProductMismatch = records
    })

    this.editionService.getWithoutPublicationDate().subscribe(records => {
      this.editionsWithoutPubdate = records
    })

    this.editionService.getLastHandled().subscribe(records => {
      this.editionsLastHandled = records
    })

    this.editionService.list({
      filters: {
        capture_state: 0
      },
      paging: {
        page: 1, size: 10
      },
      sorting: [
        { field: "id", dir: "desc" }
      ]
    }).subscribe(list => {
      this.editionsNotCaptured = list.data
    })

    this.editionService.list({
      filters: {
        capture_state: 1
      },
      paging: {
        page: 1, size: 10
      },
      sorting: [
        { field: "id", dir: "desc" }
      ]
    }).subscribe(list => {
      this.editionsCapturing = list.data
    })
  }

  onSelectAdspending(ad: AdSpendingRecordInterface) {
    this.router.navigateByUrl('/ad-spendings/capture/' + ad.data.edition_id + '?adspendingId=' + ad.data.id)
  }

  onSelectRecord(edition: EditionRecordInterface) {
    if (edition.data.capture_state != 0 && ! edition.resolved["wasmuth_publication_date"]) {
      this.router.navigate(['/ad-spendings/edition/' + edition.data.id])
    } else if (edition.data.capture_state == 0) {
      this.router.navigate(['/ad-spendings/edition/' + edition.data.id])
    } else if (edition.data.capture_state >= 1) {
      this.router.navigate(['/ad-spendings/capture/' + edition.data.id])
    }
  }
}
