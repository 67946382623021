import { Component, Input, OnInit, Optional } from "@angular/core";
import { FormGroup, FormGroupDirective, NgForm, ValidationErrors } from "@angular/forms";

@Component({
    selector: 'form-field-error',
    template: `
    <div *ngIf="errorMessages" class="form-field-error">
      <div *ngFor="let errorMessage of errorMessages">
        {{ errorMessage }}
      </div>
    </div>`,
    standalone: false
})
export class FormFieldErrorComponent implements OnInit {
  @Input('path') path = ''
  @Input('text') text = ''

  private form!: FormGroup

  constructor(
    @Optional() private ngForm: NgForm,
    @Optional() private formGroup: FormGroupDirective,
  ) {
  }

  ngOnInit() {
    this.form = this.ngForm ? this.ngForm.form : this.formGroup.form
  }

  get errorMessages(): string[] | null {
    const control = this.form.get(this.path)
    if (! control || !(control.touched) || !control.errors) {
      return null
    }
    return this.getDisplayMessages(control.errors)
  }

  private getDisplayMessages(errors: ValidationErrors): string[] {
    return Object.entries(errors).map(([errorCode, error]) => {
      switch (errorCode) {
        case 'mismatch':
          return 'Die Passwörter müssen übereinstimmen'
        case 'required':
          return "Dieses Feld muss ausgefüllt werden!"
        case 'min':
          return "Der Wert ist zu klein!"
        default:
          return `Fehler: "${errorCode}"`
      }
    })
  }
}
