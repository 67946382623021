import { Component, Input, OnInit } from '@angular/core';
import {
  CrudGridPersistentInterface,
  RecordDataInterface,
  RecordInterface,
  SearchCriteriasInterface
} from "../models/main";
import { GenericFormComponent, SaveGenericRecordEventInterface } from "../form/generic-form/generic-form.component";
import { ModelConfigFactory } from "../models/factory";
import { CrudGridDeleteComponent } from "../crud-grid/delete.component";
import { record } from "ngx-bootstrap-icons";
import { AbstractGridComponent } from "../abstract-grid/abstract-grid.component";

@Component({
    selector: 'wefra-mini-grid',
    templateUrl: './mini-grid.component.html',
    styleUrls: [],
    standalone: false
})
export class MiniGridComponent<T extends RecordInterface<K>, K extends RecordDataInterface>
  extends AbstractGridComponent<T, K> implements OnInit {

  @Input() externalRecords = false
  @Input() showPagination = false

  override ngOnInit(): void {
    super.ngOnInit()
    if (! this.controls) {
      this.controls = [
        {
          label: "Neuer Datensatz",
          method: this.onAddRecord,
          icon: "plus-circle",
          identifier: 'add',
          scope: this
        }
      ]
    }

    if (! this.actions) {
      this.actions = [
        {
          label: "Datensatz löschen",
          method: this.onDelete,
          icon: "x-circle",
          identifier: 'delete',
          scope: this
        }
      ]
    }

    if (! this.externalRecords) {
      this.load()
    }
  }

  onSelect(record: T) {
    this.selectRecordEvent.emit(record)
  }

  onAddRecord() {
    if (this.api) {
      this.openEditorModal(this.api.getEmptyRecord(this.newRecordDefaults as K))
    }
  }

  openEditorModal(record: T) {
    if (this.openedModal) return
    if (! this.modelConfig) return
    let that = this
    this.openedModal = this.modalService.show(GenericFormComponent, {
      title: this.modelConfig.titleSingular + (record.data.id ? ' bearbeiten' + ( ModelConfigFactory.getIdFieldProperty(this.modelConfig.serviceSlug) ? (' (' + record.data[ModelConfigFactory.getIdFieldProperty(this.modelConfig.serviceSlug)] + ')' ) : '') : ' erstellen'),
      inputs: {
        record: record,
        modelConfig: this.modelConfig,
        hideFields: this.hideFieldsInEditor
      },
      subscribers: {
        updateRecordEvent: (response: SaveGenericRecordEventInterface<K>) => {
          this.saveRecord(response)
        },
        closeClickEvent: () => { that.closeModal() },
      }
    })
    this.openedModal.dismissed.subscribe(() => { that.openedModal = undefined })
    this.openedModal.closed.subscribe(() => { that.openedModal = undefined })
  }

  saveRecord(o: SaveGenericRecordEventInterface<K>) {
    this.isLoading = true
    if (! this.api) return
    if (o.record.id === null) {
      this.api.create(o.record).subscribe(response => {
        if (o.closeEditor) this.closeModal()
        this.toastService.showSuccess('Datensatz wurde erfolgreich angelegt!')
        this.load()
      }, error => {
        this.isLoading = false
        if (o.closeEditor) this.closeModal()
        this.toastService.showApiError(error,'Datensatz konnte nicht angelegt werden!')
        this.load()
      })
    } else {
      this.api.update(o.record).subscribe(response => {
        this.toastService.showSuccess('Datensatz wurde erfolgreich aktualisiert!')
        if (o.closeEditor) this.closeModal()
        this.load()
      }, error => {
        this.isLoading = false
        this.toastService.showApiError(error,'Datensatz konnte nicht aktualisiert werden!')
        if (o.closeEditor) this.closeModal()
        this.load()
      })
    }
  }

  closeModal() {
    if (this.openedModal) this.openedModal?.close()
    this.openedModal = undefined
  }

  public load(): void {
    if (this.api) {
      this.isLoading = true
      this.api.list(this.getCurrentSearchCriterias(), this.additionalQueryParams).subscribe(response => {
        this.records = response.data
        this.totalRecordsCount = response.total
        this.lastPage = response.last_page
        this.isLoading = false
      }, error => {
        this.toastService.showError('Es ist ein Fehler beim Aufrufen der Datensätze aufgetreten!')
      })

      if (this.persistentStateKey) {
        let persistentSettings: CrudGridPersistentInterface = {
          filterRegistry: {},
          sortRegistry: [],
          currentPageSize: this.currentPageSize,
          currentPage: this.currentPage
        }

        this.localStorageService.set(this.modelConfig!.serviceSlug + '_grid_' + this.persistentStateKey, persistentSettings)
      }
    }
  }

  getCurrentSearchCriterias(): SearchCriteriasInterface {
    return {
      sorting: [],
      filters: this.fixedFilters,
      paging: {
        page: this.currentPage,
        size: this.currentPageSize
      }
    }
  }

  public override onDelete(record: T) {
    let that = this
    if (this.modelConfig?.readonly || ! this.api) return
    let modal = this.modalService.show(CrudGridDeleteComponent, {
      subscribers: {
        decisionEvent: function(decision: boolean) {
          if (decision) {
            that.api?.delete(record).subscribe(response => {
              that.toastService.showSuccess('Der Datensatz wurde gelöscht.')
              that.load()
            }, error => {
              that.toastService.showError('Es ist ein Fehler beim Aufrufen der Datensätze aufgetreten!')
            })
          }
          modal.close()
        }
      }
    })
  }

  protected readonly record = record;
}
