import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface MarketRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface MarketRecordInterface extends RecordInterface<MarketRecordDataInterface> {
}

export const MarketModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "markets",
  allowExportXlsx: true,
  title: "Branchen",
  titleSingular: "Branche",
  defaultSort: [{ field: 'name', dir: 'asc' }],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { type: "text", title: 'Name', prop: 'name', isNameProperty: true, validators: [ Validators.required ]},
    { prop: "created_by", ability: "spa-userinfo", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-userinfo", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-userinfo", type: "date", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-userinfo", type: "date", title: 'Bearbeitet am', edit: false},
  ]
}

