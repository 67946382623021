import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecordDataInterface, RecordInterface } from "../models/main";
import { Subject } from "rxjs";
import { AbstractGridComponent } from "../abstract-grid/abstract-grid.component";

export interface EditGridComponentFieldCoordinatesInterface {
  id?: number | string
  prop: string
}

export type ResponseErrorType = "success" | "error" | "warning";
export type ResponseErrorContext = "record" | "records";

// export interface EditGridComponentUpdateResponseInterface {
//   field: EditGridComponentFieldCoordinatesInterface
//   record: null | RecordInterface<RecordDataInterface>
//   success: boolean
// }

@Component({
    selector: 'wefra-edit-grid',
    templateUrl: './edit-grid.component.html',
    styleUrls: [],
    standalone: false
})
export class EditGridComponent<T extends RecordInterface<K>, K extends RecordDataInterface> extends AbstractGridComponent<T, K> implements OnInit {

  updating: boolean = false
  updateSuccess?: boolean = undefined
  lastActionRecordId: string | number | undefined = undefined

  @Input() editableFields: string[] = []

  @Output("updateRecord") updateRecordEvent = new EventEmitter<K>()

  public clickFieldSubject: Subject<EditGridComponentFieldCoordinatesInterface> = new Subject<EditGridComponentFieldCoordinatesInterface>();
  // public saveResponseSubject: Subject<EditGridComponentUpdateResponseInterface> = new Subject<EditGridComponentUpdateResponseInterface>();

  override ngOnInit(): void {
    super.ngOnInit()
    if (! this.controls) {
      this.controls = [
        {
          label: "Zeile hinzufügen",
          method: this.onAddLine,
          icon: "plus-circle",
          identifier: 'add',
          scope: this
        }
      ]
    }

    if (! this.actions) {
      this.actions = [
        {
          label: "speichern",
          method: this.onSaveRecord,
          icon: "check-circle",
          identifier: 'save',
          scope: this
        }, {
          label: "löschen",
          method: this.onDeleteRecord,
          icon: "x-circle",
          identifier: 'delete',
          scope: this
        }
      ]
    }

    this.load()
  }

  onClickField(prop: string, id: number | string | undefined) {
    this.clickFieldSubject.next({prop: prop, id: id})
  }

  onFieldChangeRecordEvent(record: T) {

  }

  onFieldTrySaveRecordEvent(record: T) {
    this.onSaveRecord(record)
  }

  onSaveRecord(record: T) {
    console.warn('onsave', record);
    if (! record.data.id) {
      this.onCreateRecord(record);
    } else {
      this.onUpdateRecord(record);
    }
  }

  onCreateRecord(record: T) {
    console.warn('oncreate', record)
    this.api?.create(record.data).subscribe(responseRecord => {
      this.load()
      this.toastService.showSuccess( this.modelConfig?.titleSingular + ' wurde erfolgreich erstellt!')
      this.afterRecordActionViewHandler(responseRecord, "success")
      // this.saveResponseSubject.next({ success: true, record: response, field: { id: record.data.id!, prop: prop }})
      // this.updateRecordEvent.emit(record.data)
    }, (error) => {
      this.toastService.showApiError(error, this.modelConfig?.titleSingular + ' konnte nicht erstellt werden!')
      this.afterRecordActionViewHandler(record, "error")
      //this.saveResponseSubject.next({ success: false, record: record, field: { id: record.data.id!, prop: prop }})
    })
  }

  onUpdateRecord(record: T) {
    console.warn('update', record)
    this.api?.update(record.data).subscribe(responseRecord => {
      //this.viewRecordUpdate(response)
      this.toastService.showSuccess( this.modelConfig?.titleSingular + ' wurde erfolgreich aktualisiert!')
      this.afterRecordActionViewHandler(responseRecord, "success")
      // this.saveResponseSubject.next({ success: true, record: response, field: { id: record.data.id!, prop: prop }})
      this.updateRecordEvent.emit(record.data)
    }, (error) => {
      this.toastService.showApiError(error, this.modelConfig?.titleSingular + ' konnte nicht aktualisiert werden!')
      this.afterRecordActionViewHandler(record, "error")
      // this.saveResponseSubject.next({ success: false, record: record, field: { id: record.data.id!, prop: prop }})
    })
  }

  onDeleteRecord(record: T) {
    if (! this.records) return

    if (! record.data.id) {
      this.records.pop()
      return
    }

    if (! this.api) return

    this.api.delete(record).subscribe(response => {
      this.toastService.showSuccess( this.modelConfig?.titleSingular + ' wurde erfolgreich gelöscht!')
      this.load()
    }, (error) => {
      this.toastService.showApiError(error, this.modelConfig?.titleSingular + ' konnte nicht gelöscht werden!')
      this.afterRecordActionViewHandler(record, "error")
    })
  }

  private afterRecordActionViewHandler(record: T, type: ResponseErrorType, context: ResponseErrorContext = "record") {
    this.lastActionRecordId = record.data.id
    this.updating = false

    switch (type) {
      case "warning":
        break
      case "success":
        this.updateSuccess = true
        setTimeout(() => {
          this.updateSuccess = undefined
        }, 2000)
        break
      case "error":
        this.updateSuccess = false
        setTimeout(() => {
          this.updateSuccess = undefined
        }, 2000)
    }
  }

  onAddLine() {
    if (this.api) {
      if (! this.records) this.records = []

      // find out if there is a new line already
      let isaddingAlready = false
      for (const record of this.records) {
        if (! record.data.id) isaddingAlready = true
      }

      if (! isaddingAlready) {
        if (this.newRecordDefaults) {
          let r = this.api.getEmptyRecord()
          r.data = { ...r.data, ...this.newRecordDefaults }
          this.records.push(r)
        } else {
          this.records.push(this.api.getEmptyRecord())
        }
      }
    }
  }

  public load(): void {
    this.records = []
    this.api?.list({
      filters: this.fixedFilters
    }).subscribe(response => {
      this.records = response.data
    }, error => {
      this.toastService.showError('Es ist ein Fehler beim Aufrufen der Datensätze aufgetreten!')
    })
  }
}
