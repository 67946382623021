<wefra-generic-form
  [record]="record"
  [modelConfig]="modelConfig"
  (saveRecord)="onMagazineRecordSave($event)"
  (closeClick)="onCloseClick($event)"
></wefra-generic-form>
<wefra-mini-grid #miniGridComponent
  heading="Zielgruppen - Zuordnung der Perioden"
  [hideFieldsInEditor]="['magazine_id']"
  [newRecordDefaults]="newRecordDefaults"
  [records]="targetAudiencePeriods"
  [modelConfig]="targetAudiencePeriodModelConfig"
  (selectRecord)="onSelectTargetAudiencePeriod($event)"
  [fixedFilters]="miniGridFixedFilters"
></wefra-mini-grid>
