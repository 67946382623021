import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModelConfigurationFieldInterface, RecordDataInterface, RecordInterface } from "../models/main";
import { Subject } from "rxjs";
import { EditGridComponentFieldCoordinatesInterface } from "./edit-grid.component";

@Component({
    selector: 'wefra-edit-grid-field-renderer',
    templateUrl: './field-renderer.html',
    standalone: false
})
export class EditGridFieldRendererComponent<T extends RecordInterface<RecordDataInterface>> implements OnInit {

  @Input({ required: true }) public record?: T
  @Input({ required: true }) public fieldConfig?: ModelConfigurationFieldInterface
  @Input() clickFieldSubject?: Subject<EditGridComponentFieldCoordinatesInterface>

  @Output("trySaveRecord") trySaveRecordEvent = new EventEmitter<T>()
  @Output("changeRecord") changeRecordEvent = new EventEmitter<T>()

  @ViewChild('focusEl') focusEl?: ElementRef<HTMLInputElement>

  editing: boolean = false
  value: string | number = ""

  onFocusOut(e: FocusEvent) {
    if (! this.fieldConfig || ! this.record) return

    this.editing = false
    const input = e.target as HTMLInputElement
    this.record.data[this.fieldConfig.prop] = input.value
    this.value = input.value
    this.changeRecordEvent.emit(this.record)
  }

  trySave() {
    if (! this.fieldConfig || ! this.record) return
    this.editing = false
    this.trySaveRecordEvent.emit(this.record)
  }

  onKeyUp(e: KeyboardEvent) {
    if (! this.fieldConfig || ! this.record) return

    if (e.key === "Escape") {
      e.stopPropagation()
      this.editing = false
      return
    }

    // set record value to current input value
    const input = e.target as HTMLInputElement
    this.record.data[this.fieldConfig.prop] = input.value
    this.value = input.value

    if (e.key === "Enter") {
      this.trySave()
      return
    }

    this.changeRecordEvent.emit(this.record)
  }

  public onSelectConstraintRecord(record: RecordInterface<RecordDataInterface>, fieldConfig: ModelConfigurationFieldInterface) {
    if (! this.record || ! this.record.data) { return }
    if (record.data.id) {
      this.record.data[fieldConfig.prop] = record.data.id
      this.value = record.data.id
    }
  }

  ngOnInit(): void {
    if (! (this.record && this.fieldConfig && this.fieldConfig.prop)) return

    this.value = this.record!.data[this.fieldConfig!.prop]
    let that = this

    if (this.clickFieldSubject) {
      this.clickFieldSubject.subscribe(field => {
        if (this.record && this.record.data.id == field.id && this.fieldConfig?.prop == field.prop) {
          this.editing = true
          setTimeout( () => { that.focusEl?.nativeElement.focus() }, 200)
        } else {
          this.editing = false
        }
      })
    }
  }
}
