import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'wefra-grid-pagination',
    templateUrl: './pagination.component.html',
    standalone: false
})
export class PaginationComponent implements OnInit, OnChanges {
  @Output("setPage") setPageEvent = new EventEmitter<number>()

  @Input() public totalRecordsCount: number = 0
  @Input() public currentPage: number = 1
  @Input() public pageSize: number = 20

  public lastPage: number = 1

  ngOnInit(): void {
    this.lastPage = Math.ceil(this.totalRecordsCount / this.pageSize)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalRecordsCount']) {
      this.lastPage = Math.ceil(changes['totalRecordsCount'].currentValue / this.pageSize)
    } else if (changes['pageSize']) {
      this.lastPage = Math.ceil(this.totalRecordsCount / changes['pageSize'].currentValue)
    }
  }

  jumpTo(e: Event) {
    let el = e.target as HTMLInputElement
    const v = parseInt(el.value)

    if (v > this.lastPage) {
      el.value = this.currentPage+''
      return
    }

    this.setPage(v)
    this.setInputWidth(el)
  }

  onKeyUp(e: Event) {
    let el = e.target as HTMLInputElement
    this.setInputWidth(el)
  }

  setInputWidth(el: HTMLInputElement) {
    const width = ((el.value+'').length) + 4
    el.style.width = width + "ch"
  }

  getInputWidth() {
    const width = (this.currentPage+'').length + 4
    return width + 'ch'
  }

  setPage(number?: number) {
    if (number !== undefined) {
      this.currentPage = number
      this.setPageEvent.emit(number)
    }
  }

  showsAll() {
    if (this.pageSize >= this.totalRecordsCount) return true
    return false
  }
  showPaginationItem(index: number) {
    if (this.pageSize >= this.totalRecordsCount) return false
    if (index === 0) return false
    if (this.getLastPage() && (this.getLastPage()! < 6)) return true
    if (this.currentPage < (index + 3) && (this.currentPage > (index - 3))) {
      return true
    }
    return false
  }
  showNextDots() {
    if (this.currentPage < this.lastPage - 2) return true
    return false
  }
  showPreviousDots() {
    if ((this.currentPage - 3) > 0) return true
    return false
  }

  getPreviousPage() {
    let v = this.currentPage - 1
    if (v < 1) return undefined
    if (this.pageSize >= this.totalRecordsCount) return undefined
    return v
  }
  getFirstPage() {
    if (this.currentPage == 1) return undefined
    if (this.pageSize >= this.totalRecordsCount) return undefined
    return 1
  }
  getNextPage(): number | undefined {
    let v = this.currentPage + 1
    if (v > this.lastPage) return undefined
    return v
  }
  getLastPage() {
    if ((this.lastPage) == this.currentPage) return undefined
    if (this.pageSize >= this.totalRecordsCount) return undefined
    return this.lastPage
  }

  getCenterItems(): number[] {
    let ret: number[] = []
    let i = 0
    while (i <= this.lastPage) {
      if (this.showPaginationItem(i)) {
        ret.push(i)
      }
      i++
    }
    return ret
  }
}
