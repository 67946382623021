<span class="wrapper">
  <ng-container *ngIf="editing; else noedit">
    <ng-container *ngIf="(fieldConfig && fieldConfig.type == 'constraint'); else noconstraint">
      <wefra-constraint-form-field #focusEl
        [fieldConfig]="fieldConfig"
        [record]="record"
        (selectRecord)="onSelectConstraintRecord($event, fieldConfig)"
      ></wefra-constraint-form-field>
    </ng-container>
    <ng-template #noconstraint>
      <input type="text" (focusout)="onFocusOut($event)" (keyup)="onKeyUp($event)" [value]="value" #focusEl>
    </ng-template>
  </ng-container>
  <ng-template #noedit>
    <wefra-crud-grid-field-renderer
      [fieldConfig]="fieldConfig"
      [record]="record"
      [context]="'mini-grid'"
    ></wefra-crud-grid-field-renderer>
  </ng-template>
</span>
