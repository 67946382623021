import { Component, inject } from '@angular/core';
import { AbstractRecordComponent } from "../../abstract-records/abstract-record.component";
import { ApilogModelConfiguration, ApilogRecordDataInterface, ApilogRecordInterface } from "../../models/apilog";
import { ModelConfigurationInterface } from "../../models/main";
import { HttpApilogService } from "../../services/http/apilog.service";

@Component({
    selector: 'wefra-apilogs-component',
    templateUrl: '../../abstract-records/abstract-record.component.html',
    standalone: false
})
export class ApilogsComponent extends AbstractRecordComponent<ApilogRecordInterface, ApilogRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = ApilogModelConfiguration
  api = inject(HttpApilogService)

  constructor(
  ) {
    super()
  }
}
