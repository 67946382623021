import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { ModelConfigurationInterface } from "../../models/main";
import { SaveGenericRecordEventInterface } from "../../form/generic-form/generic-form.component";
import {
  TargetAudiencePeriodRecordDataInterface,
  TargetAudiencePeriodRecordInterface
} from "../../models/target_audience_period";
import { ModalService } from "../../services/modal.service";
import {
  TargetAudiencePeriodEntryModelConfiguration,
  TargetAudiencePeriodEntryRecordInterface
} from "../../models/target_audience_period_entry";
import { HttpTargetAudiencePeriodService } from "../../services/http/target_audience_period.service";
import { ToastService } from "../../services/toast.service";

@Component({
    selector: 'wefra-target-audience-period-form-component',
    templateUrl: './form.component.html',
    standalone: false
})
export class TargetAudiencePeriodFormComponent implements OnInit {

  @Input() record?: TargetAudiencePeriodRecordInterface
  @Input() modelConfig?: ModelConfigurationInterface

  @Output("saveRecord") updateRecordEvent = new EventEmitter<SaveGenericRecordEventInterface<TargetAudiencePeriodRecordDataInterface>>()
  @Output("closeClick") closeClickEvent = new EventEmitter<boolean>()

  public targetAudiencePeriodEntries?: TargetAudiencePeriodEntryRecordInterface[]
  public targetAudiencePeriodEntryModelConfig: ModelConfigurationInterface = TargetAudiencePeriodEntryModelConfiguration

  constructor(
    @Inject(HttpTargetAudiencePeriodService) private api: HttpTargetAudiencePeriodService,
    @Inject(ModalService) private modalService: ModalService,
    @Inject(ToastService) private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    if (this.record && this.record.beside && this.record.beside["entries"]) {
      this.targetAudiencePeriodEntries = this.record.beside["entries"] as TargetAudiencePeriodEntryRecordInterface[]
    }
  }

  onCloseClick(data: boolean): void {
    this.closeClickEvent.emit(data)
  }

  onPeriodRecordSave(o: SaveGenericRecordEventInterface<TargetAudiencePeriodRecordDataInterface>): void {
    if (! this.api) return
    if (o.record.id === null) {
      this.api.create(o.record).subscribe(response => {
        this.toastService.showSuccess('Datensatz wurde erfolgreich angelegt!')
        this.updateRecordEvent.emit(o)
      }, error => {
        this.toastService.showApiError(error,'Datensatz konnte nicht angelegt werden!')
      })
    } else {
      this.api.update(o.record).subscribe(response => {
        this.toastService.showSuccess('Datensatz wurde erfolgreich aktualisiert!')
        this.updateRecordEvent.emit(o)
      }, error => {
        this.toastService.showApiError(error,'Datensatz konnte nicht aktualisiert werden!')
      })
    }
  }
}
