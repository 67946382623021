<h1>Dashboard</h1>
<div class="dashboard-tiles d-flex align-content-around flex-wrap">
  <div class="tile">
    <div class="heading">
      <h6>Zuletzt von mir erfasst</h6>
      <p>Hier werden Printmedien angezeigt, die vom aktuellen Benutzer zuletzt bearbeitet worden sind, oder zu denen ein Adspending angelegt worden ist:</p>
    </div>
    <div class="body">
      <wefra-mini-grid
        [actions]="[]"
        [controls]="[]"
        [externalRecords]="true"
        [modelConfig]="editionModelConfig"
        [records]="editionsLastHandled"
        [limitToFields]="editionsLimitToFields"
        (selectRecord)="onSelectRecord($event)"
      ></wefra-mini-grid>
    </div>
  </div>
  <div class="tile">
    <div class="heading">
      <h6>Printmedien ohne Publication Date</h6>
      <p>Wasmuth löscht manchmal Publication-Dates und ersetzt diese mit neuen, dann stimmt die Zuordnung nicht mehr. Bitte überarbeiten:</p>
    </div>
    <div class="body">
      <wefra-mini-grid class="alert-items"
        [actions]="[]"
        [controls]="[]"
        [externalRecords]="true"
        [modelConfig]="editionModelConfig"
        [records]="editionsWithoutPubdate"
        [limitToFields]="editionsLimitToFields"
        (selectRecord)="onSelectRecord($event)"
      ></wefra-mini-grid>
    </div>
  </div>

  <div class="tile">
    <div class="heading">
      <h6>Nicht erfasste Printmedien</h6>
      <p>Hier werden Printmedien angezeigt, die nur gescannt, aber noch nicht zugeordnet sind:</p>
    </div>
    <div class="body">
      <wefra-mini-grid
        [controls]="[]"
        [actions]="[]"
        [externalRecords]="true"
        [modelConfig]="editionModelConfig"
        [records]="editionsNotCaptured"
        [limitToFields]="['id', 'capture_state', 'file_basename']"
        (selectRecord)="onSelectRecord($event)"
      ></wefra-mini-grid>
    </div>
  </div>

  <div class="tile">
    <div class="heading">
      <h6>Printmedien in Erfassung</h6>
      <p>Hier werden Printmedien angezeigt, die gescannt und zugeordnet sind, aber noch nicht komplett erfasst worden sind:</p>
    </div>
    <div class="body">
      <wefra-mini-grid
        [controls]="[]"
        [actions]="[]"
        [externalRecords]="true"
        [modelConfig]="editionModelConfig"
        [records]="editionsCapturing"
        [limitToFields]="editionsLimitToFields"
        (selectRecord)="onSelectRecord($event)"
      ></wefra-mini-grid>
    </div>
  </div>

  <div class="tile fw">
    <div class="heading">
      <h6>Adspendings mit falscher Zuordnung von Motiven zu Produkten</h6>
      <p>Nach dem Historisieren von Produkten oder dem Duplizieren bei der Erfassung kann es Adspendings geben, die ein Motiv zugewiesen haben, bei denen das Produkt und die Firma das veraltete haben. Bitte überarbeiten:</p>
    </div>
    <div class="body">
      <wefra-mini-grid class="alert-items"
         [showPagination]="false"
         [externalRecords]="true"
         [controls]="[]"
         [actions]="[]"
         [modelConfig]="adspendingModelConfig"
         [records]="adspendingsMotiveProductMismatch"
         [limitToFields]="adspendingsLimitToFields"
         (selectRecord)="onSelectAdspending($event)"
      ></wefra-mini-grid>
    </div>
  </div>

  <div class="tile fw">
    <div class="heading">
      <h6>Adspendings mit falscher Zuordnung von Produkten zu Firmen</h6>
      <p>Wenn es Adspendings gibt, die Produkte haben, die der falschen Firma zugewiesen haben, werden sie hier angezeigt. Bitte überarbeiten:</p>
    </div>
    <div class="body">
      <wefra-mini-grid class="alert-items"
       [showPagination]="false"
       [externalRecords]="true"
       [controls]="[]"
       [actions]="[]"
       [modelConfig]="adspendingModelConfig"
       [records]="adspendingsProductCompanyMismatch"
       [limitToFields]="adspendingsLimitToFields"
       (selectRecord)="onSelectAdspending($event)"
      ></wefra-mini-grid>
    </div>
  </div>

</div>

<!-- [limitToFields]="['name2', 'name1', 'initial', 'edition', 'name_long', 'ratecard_number']" -->
