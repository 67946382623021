import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MyDate } from "../../../helpers/main";

export type DateContexts = "day" | "month" | "year" | "hour" | "minute" | "second";

export type DateContextsStringInterface = {
  [key in DateContexts]: string | undefined
};

@Component({
    selector: 'wefra-date-time-field',
    templateUrl: './datetime.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})

export class DateTimeFieldComponent implements OnInit, OnChanges {
  @Input({required: false}) public value?: string
  @Input({required: false}) public readonly?: boolean
  @Input({required: false}) public disabled?: boolean
  @Input({required: false}) public showTime: boolean = true
  @Input({required: false}) public showSecond: boolean = false

  @Output("onChange") onChangeEvent = new EventEmitter<string>()

  public invalid = false
  public dateNumbers: DateContextsStringInterface = {
    day: undefined,
    month: undefined,
    year: undefined,
    hour: undefined,
    minute: undefined,
    second: undefined
  }

  constructor(
    private cd: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {
    if (this.value) {
      this.fromPartialUTCString(this.value)
      this.render()
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('value')) {
      if (changes["value"].currentValue === undefined || changes["value"].currentValue === "" || changes["value"].currentValue === "undefined") {
        this.clearFields()
        this.render()
      }
    }
  }

  private fromPartialUTCString(date: string) {
    this.clearFields()
    let split = date.split(" ");
    let virtualUTCTimeString = ""
    let dateSplit: undefined | string[]
    let timeSplit: undefined | string[]

    if (split[0]) {
      dateSplit = split[0].split("-")

      // if no time is given, do not convert to localtime
      if (dateSplit[0]) {
        this.dateNumbers['year'] = dateSplit[0]
      }
      if (dateSplit[1]) {
        this.dateNumbers['month'] = dateSplit[1]
      }
      if (dateSplit[2]) {
        this.dateNumbers['day'] = dateSplit[2]
      }
    }

    if (dateSplit && dateSplit.length == 3 && split[1]) {
      virtualUTCTimeString = dateSplit.join("-")
      timeSplit = split[1].split(":")
      if (timeSplit[0]) {
        this.dateNumbers['hour'] = timeSplit[0]
        virtualUTCTimeString += ' ' + timeSplit[0]
      } else {
        virtualUTCTimeString += ' ' + '12'
      }
      if (timeSplit[1]) {
        this.dateNumbers['minute'] = timeSplit[1]
        virtualUTCTimeString += ':' + timeSplit[1]
      } else {
        virtualUTCTimeString += ':' + '30'
      }
      if (timeSplit[2]) {
        this.dateNumbers['second'] = timeSplit[2]
        virtualUTCTimeString += ':' + timeSplit[2]
      } else {
        virtualUTCTimeString += ':' + '30'
      }
    }

    if (virtualUTCTimeString) {
      const virtualUTCTime = new MyDate(virtualUTCTimeString + ' UTC')
      if (virtualUTCTime && timeSplit) {

        this.dateNumbers['year'] = '' + virtualUTCTime.getFullYear()
        this.dateNumbers['month'] = '' + virtualUTCTime.getMonthOfYear()
        this.dateNumbers['day'] = '' + virtualUTCTime.getDay()

        if (timeSplit[0]) {
          this.dateNumbers['hour'] = '' + (virtualUTCTime.getHours() < 10 ? '0' : '') + virtualUTCTime.getHours()
        } else {
          this.dateNumbers['hour'] = undefined
        }
        if (timeSplit[1]) {
          this.dateNumbers['minute'] = '' + (virtualUTCTime.getMinutes() < 10 ? '0' : '') + virtualUTCTime.getMinutes()
        } else {
          this.dateNumbers['minute'] = undefined
        }
        if (timeSplit[2]) {
          this.dateNumbers['second'] = '' + (virtualUTCTime.getSeconds() < 10 ? '0' : '') + virtualUTCTime.getSeconds()
        } else {
          this.dateNumbers['second'] = undefined
        }
      }
    }
    this.render()
  }

  private toPartialUTCString(date?: string) {
    if (! date) return ''

    let virtualLocalTimeString = ""

    let split = date.split(" ");
    let dateSplit: undefined | string[]
    let timeSplit: undefined | string[]
    let virtualLocalTime: MyDate | undefined = undefined
    let partialUTCTimeString = ""

    if (split[0]) {
      dateSplit = split[0].split("-")

      if (dateSplit[0]) {
        virtualLocalTimeString += dateSplit[0]
      }
      if (dateSplit[1]) {
        virtualLocalTimeString += '-' + dateSplit[1]
      }
      if (dateSplit[2]) {
        virtualLocalTimeString += '-' + dateSplit[2]
      }
    }

    if (dateSplit && dateSplit.length == 3 && split[1]) {

      timeSplit = split[1].split(":")

      if (timeSplit[0]) {
        virtualLocalTimeString += ' ' + timeSplit[0]
      } else {
        virtualLocalTimeString += ' ' + '12'
      }

      if (timeSplit[1]) {
        virtualLocalTimeString += ':' + timeSplit[1]
      } else {
        virtualLocalTimeString += ':' + '30'
      }

      if (timeSplit[2]) {
        virtualLocalTimeString += ':' + timeSplit[0]
      } else {
        virtualLocalTimeString += ':' + '30'
      }

      virtualLocalTime = new MyDate(virtualLocalTimeString)
    } else {
      virtualLocalTimeString += " 12:00:00"
      virtualLocalTime = new MyDate(virtualLocalTimeString)
    }

    // now set partial utc string by local date, but only with defined parts
    if (dateSplit) {
      if (dateSplit[0]) {
        partialUTCTimeString += '' + (virtualLocalTime.getUTCFullYear() < 10 ? '0' : '') + virtualLocalTime.getUTCFullYear()
        if (dateSplit[1]) {
          const m = virtualLocalTime.getUTCMonth() + 1
          partialUTCTimeString += '-' + (m < 10 ? '0' : '') + m
          if (dateSplit[2]) {
            partialUTCTimeString += '-' + (virtualLocalTime.getUTCDate() < 10 ? '0' : '') + virtualLocalTime.getUTCDate()
          }

          if (timeSplit) {
            if (timeSplit[0]) {
              partialUTCTimeString += ' ' + (virtualLocalTime.getUTCHours() < 10 ? '0' : '') + virtualLocalTime.getUTCHours()
              if (timeSplit[1]) {
                partialUTCTimeString += ':' + (virtualLocalTime.getUTCMinutes() < 10 ? '0' : '') + virtualLocalTime.getUTCMinutes()
                if (timeSplit[2]) {
                  partialUTCTimeString += ':' + (virtualLocalTime.getUTCSeconds() < 10 ? '0' : '') + virtualLocalTime.getUTCSeconds()
                }
              }
            }
          }
        }
      }
    }

    return partialUTCTimeString
  }

  private clearFields() {
    this.dateNumbers = {
      day: undefined,
      month: undefined,
      year: undefined,
      hour: undefined,
      minute: undefined,
      second: undefined
    }
    this.render()
  }

  render() {
    this.cd.detectChanges()
  }


  // Date Input

  onKeyUp(e: KeyboardEvent, context: DateContexts) {
    if (this.disabled || this.readonly) return

    const input = e.target as HTMLInputElement
    const value = input.value.trim()

    e.stopPropagation()
    e.preventDefault()

    switch (e.key) {
      case "Enter":
        const v = this.toPartialUTCString(this.value)
        if (v == 'NaN') return
        this.onChangeEvent.emit(v)
        break
      case "Escape":
        this.dateNumbers[context] = undefined
        this.validateAndCombine()
        break
      case "Tabulator":
        break
      default:
        this.dateNumbers[context] = value
        this.validateAndCombine()
    }
  }

  onFocusOut(e: FocusEvent, scope: DateContexts) {
    if (this.disabled || this.readonly || ! this.value || this.value.length == 0) return
    const val = this.toPartialUTCString(this.value)

    if (val == "NaN") return

    if (scope == "second" && this.showTime && this.showSecond) {
      this.onChangeEvent.emit(val)
      return
    }
    if (scope == "minute" && this.showTime && ! this.showSecond) {
      this.onChangeEvent.emit(val)
      return
    }
    if (scope == "year" && ! this.showTime) {
      this.onChangeEvent.emit(val)
      return
    }
  }

  validateAndCombine() {
    let year = this.dateNumbers['year']
    let month = this.dateNumbers['month']
    let day = this.dateNumbers['day']
    let hour = this.dateNumbers['hour']
    let minute = this.dateNumbers['minute']
    let second = this.dateNumbers['second']

    if (! year) {
      this.dateNumbers['year'] = undefined
      this.value = ''
      this.invalid = false
      return
    }

    if (year.length == 2) year = "20" + year
    this.value = year + '-'

    if (! month) return
    if (parseInt(month, 10) > 12 || parseInt(month, 10) < 1) return
    if (month.length == 1) month = "0" + month
    this.value += month + '-'


    if (! day) return
    if (parseInt(day, 10) > 31 || parseInt(day, 10) < 1) return
    if (day.length == 1) day = "0" + day
    // test if date is valid
    const testvalue = this.value + day
    let testdate = new MyDate(testvalue)
    if (testdate) {
      this.value = testvalue
      this.invalid = false
    } else {
      this.invalid = true
      return
    }

    if (! hour) return
    if (parseInt(hour, 10) > 23 || parseInt(hour, 10) < 0) return
    if (hour.length == 1) hour = "0" + hour
    this.value += ' ' + hour

    if (! minute) return
    if (parseInt(minute, 10) > 59 || parseInt(minute, 10) < 0) return
    if (minute.length == 1) minute = "0" + minute
    this.value += ':' + minute

    if (! second) return
    if (parseInt(second, 10) > 59 || parseInt(second, 10) < 0) return
    if (second.length == 1) second = "0" + second
    this.value += ':' + second
  }
}
