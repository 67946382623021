import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  FiltersInterface,
  ModelConfigurationFieldInterface,
  RecordDataInterface,
  RecordInterface,
  RecordResolvedInterface,
  SearchCriteriasInterface
} from "../../../models/main";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { debounceTime, Subject } from "rxjs";
import { HttpAbstractRecordService } from "../../../services/http/record.service.abstract";
import { HttpServiceFactory } from "../../../services/http/factory";
import { CrudGridComponent } from "../../../crud-grid/crud-grid.component";
import { ModalService } from "../../../services/modal.service";
import { ModelConfigFactory } from "../../../models/factory";
import { ToastService } from "../../../services/toast.service";
import { GenericFormComponent, SaveGenericRecordEventInterface } from "../generic-form.component";

@Component({
    selector: 'wefra-constraint-form-field',
    templateUrl: './constraint.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ConstraintComponent
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConstraintComponent<T extends RecordInterface<RecordDataInterface>, K extends RecordDataInterface> implements OnInit, ControlValueAccessor {

  @Input({required: true}) public record?: T
  @Input({required: true}) public fieldConfig?: ModelConfigurationFieldInterface
  @Input({required: false}) public readonly: boolean = false
  @Input() fixedFilters?: FiltersInterface
  @Input() gridPresetFilters?: FiltersInterface
  @Input() gridLimitToFields?: string[]
  @Input() gridShowAddRecordButton: boolean = false
  @Input() valueProperty?: string
  @Input() value?: number | string
  @Input() viewValue?: string | number = ''

  @Output("selectRecord") selectRecordEvent = new EventEmitter<RecordInterface<RecordDataInterface>>()
  @Output("clear") clearEvent = new EventEmitter<boolean>()

  @ViewChild('swordInput', {read: TemplateRef}) swordInput!: TemplateRef<any>
  @ViewChild('searchResults') searchResults!: ElementRef<HTMLDivElement>

  private swordSubject: Subject<string> = new Subject<string>()
  public keyboardNavigationIndex: number = -1

  disabled: boolean = false
  sword: string = ''
  searchItems?: T[]
  recordService?: HttpAbstractRecordService<T, K>
  searching: boolean = false
  open: boolean = false
  touched: boolean = false
  nameProperty: string = 'name'
  tooltipText: string = ''

  private constraintNameFieldConfig?: ModelConfigurationFieldInterface

  constructor(
    @Inject(HttpServiceFactory) private serviceFactory: HttpServiceFactory,
    @Inject(ModalService) private modalService: ModalService,
    @Inject(ToastService) private toastService: ToastService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('value')) {
      if (changes["value"].currentValue === undefined || changes["value"].currentValue === "") {
        this.clearField()
      }
    }
    this.cd.detectChanges()
  }

  openGridSelectorModal() {
    if (! this.fieldConfig?.constraint?.table) return
    let that = this

    if (this.sword.length) {
      if (! this.gridPresetFilters) {
        this.gridPresetFilters = {}
      }
      this.gridPresetFilters[this.nameProperty] = this.sword
    }

    const modelconfig = ModelConfigFactory.getConfig(that.fieldConfig!.constraint!.table)
    let modal = this.modalService.show(CrudGridComponent, {
      inputs: {
        modelConfig: modelconfig,
        api: this.recordService,
        selectOnly: true,
        fixedFilters: this.fixedFilters,
        limitToFields: this.gridLimitToFields,
        showAddRecordButton: this.gridShowAddRecordButton,
        presetFilters: this.gridPresetFilters
      },
      closeBtnText: undefined,
      title: this.fieldConfig?.title + ' wählen',
      modalOptions: {
        windowClass: 'grid-modal',
      },
      subscribers: {
        newRecordEvent: function(record: T) {
          let editorModal = that.modalService.show(GenericFormComponent, {
            inputs: {
              modelConfig: modelconfig,
              record: that.prepareEditRecord()
            },
            title: 'Neuer Datensatz in: ' + modelconfig.title,
            modalOptions: {
              windowClass: 'editor-modal',
            },
            subscribers: {
              updateRecordEvent: function (data: SaveGenericRecordEventInterface<K>) {
                that.saveRecord(data.record)
                if (data.closeEditor) {
                  editorModal.close()
                  modal.close()
                }
              },
              closeClickEvent: function () {
                editorModal.close()
              },
            }
          })
        },
        selectRecordEvent: function(record: T) {
          that.selectRecord(record)
          modal.close()
        }
      }
    })
  }

  scrollIntoView() {
    if (this.keyboardNavigationIndex == -1) {
      this.searchResults.nativeElement.scrollTo({ top: 0, behavior: 'smooth' })
      return
    }

    const el = this.getSearchItemElement()
    if (el) {
      const topPos = el.offsetTop - 20
      if (topPos) {
        this.searchResults.nativeElement.scrollTo({top: topPos, behavior: 'smooth'})
      }
    }
  }
  getSearchItemElement(): HTMLDivElement | null {
    let el: HTMLDivElement | null = null
    if (this.keyboardNavigationIndex == 0) {
      el = this.searchResults.nativeElement.querySelector('.item:first-child') as HTMLDivElement
    } else {
      el = this.searchResults.nativeElement.querySelector('.item:nth-child(' + (this.keyboardNavigationIndex + 1) + ')') as HTMLDivElement
    }

    return el
  }
  prepareEditRecord(): T {
    let resolved: RecordResolvedInterface = {}
    if (this.fixedFilters) {
      Object.entries(this.fixedFilters).forEach(([ prop, val ]) => {
        if (this.record?.resolved[prop]) {
          resolved[prop] = this.record!.resolved[prop]!
        }
      })
    }
    let record = this.recordService!.getEmptyRecord(this.fixedFilters as K)
    record.resolved = resolved
    return record
  }
  onChange = (value: any) => {
    this.setViewValueByResolvedRelation()
  }

  onTouched = () => {}

  ngOnInit(): void {

    if (! this.fieldConfig) return
    this.constraintNameFieldConfig = ModelConfigFactory.getFieldConfig(this.fieldConfig.constraint!.table, ModelConfigFactory.getNameFieldProperty(this.fieldConfig.constraint!.table))

    const modelconfig = ModelConfigFactory.getConfig(this.fieldConfig.constraint!.table)
    const sorting = modelconfig.defaultSort ? modelconfig.defaultSort : []

    this.setViewValueByResolvedRelation()

    if (this.readonly) return

    // @ts-ignore
    this.recordService = this.serviceFactory.getTypeInstance(this.fieldConfig.constraint?.table)

    const np = this.recordService?.getNameProperty()
    if (np) {
      this.nameProperty = np
    }
    const filterProperty = this.recordService?.getFilterProperty() ?? "name"
    this.swordSubject.pipe(
      debounceTime(400)).subscribe((sword: string) => {
        if (sword.length < 2 || ! this.recordService) return
        this.searching = true
        this.sword = sword

        let searchCriterias: SearchCriteriasInterface = {
          sorting: sorting,
          filters: { ... { [filterProperty]: this.sword }, ... this.fixedFilters },
          paging: {
            page: 1,
            size: 20
          }
        }

        this.recordService.list(searchCriterias).subscribe((result: { data: T[] | undefined; }) => {
          this.searchItems = result.data
          this.searching = false
          this.cd.detectChanges()
        }, error => {
          this.searching = false
          this.cd.detectChanges()
        })
      }
    )
  }

  render() {
    this.cd.detectChanges()
  }

  setViewValueByResolvedRelation() {

    if (! this.record || ! this.fieldConfig || ! this.record.resolved || ! this.record.resolved[this.fieldConfig.prop]) {
      this.viewValue = ""
      this.tooltipText = ""
      this.cd.detectChanges()
      return
    }

    this.tooltipText = this.fieldConfig?.title + ' (ID): ' + this.record.data[this.fieldConfig?.prop]

    if (this.fieldConfig.hasOwnProperty("renderer")) {
      try {
        // @ts-ignore
        this.viewValue = this.fieldConfig.renderer(this.record)
        this.cd.detectChanges()
        return
      } catch (e) {
        console.error(e)
      }
    } else if (this.record.resolved[this.fieldConfig.prop] !== undefined) {
      this.viewValue = this.record.resolved[this.fieldConfig.prop]?.data[this.nameProperty]
      if (this.viewValue === undefined) this.viewValue = "<keine Angabe>"
      this.cd.detectChanges()
      return
    }

    this.viewValue = ""

    this.cd.detectChanges()
  }

  onKeyEnter(e: Event) {
    if (this.disabled || this.readonly) return
    if (! this.searchItems) return
    e.stopPropagation()
    e.preventDefault()
    let record = this.searchItems[this.keyboardNavigationIndex]
    if (record) {
      this.selectRecord(record)
    } else {
      console.warn("could not find record")
    }
  }

  onKeyUp(e: KeyboardEvent) {
    if (this.disabled || this.readonly) return
    if (e.key == "Enter") {
      return
    } else if (e.key == "Escape") {
      this.searchItems = undefined
      this.keyboardNavigationIndex = 0
      this.setViewValueByResolvedRelation()
    } else if (e.key == 'ArrowDown' || e.key == 'ArrowUp') {
      if (! this.searchItems) return
      if (e.key == 'ArrowDown') {
        if (this.keyboardNavigationIndex == -1) {
          this.keyboardNavigationIndex = 0
        } else if (this.keyboardNavigationIndex + 1 < this.searchItems.length) {
          this.keyboardNavigationIndex = this.keyboardNavigationIndex + 1
        } else {
          this.keyboardNavigationIndex = this.searchItems.length - 1
        }
      } else if (e.key == 'ArrowUp') {
        if (this.keyboardNavigationIndex > -1) {
          this.keyboardNavigationIndex = this.keyboardNavigationIndex - 1
          if (this.keyboardNavigationIndex < 0) this.keyboardNavigationIndex = -1
        }
      }

      this.scrollIntoView()

    } else {
      const el = e.target as HTMLInputElement
      this.keyboardNavigationIndex = -1
      this.swordSubject.next(el.value)
    }
  }
  selectRecord(record: RecordInterface<RecordDataInterface>) {
    if (this.readonly) return

    const valueProperty = this.valueProperty ?? ModelConfigFactory.getIdFieldProperty(this.fieldConfig?.constraint?.table)
    this.record!.resolved[this.fieldConfig!.prop] = record
    this.record!.data[this.fieldConfig!.prop] = record.data[valueProperty]
    this.value = record.data[valueProperty]
    this.searchItems = undefined
    this.keyboardNavigationIndex = -1
    this.selectRecordEvent.emit(record)
    this.onChange(record.data[valueProperty])
    this.setViewValueByResolvedRelation()
  }

  clearField() {
    if (this.readonly) return
    const idProperty = ModelConfigFactory.getIdFieldProperty(this.fieldConfig?.constraint?.table)
    try {
      delete this.record!.resolved[this.fieldConfig!.prop]
    } catch (e) {}
    try {
      this.record!.data[this.fieldConfig!.prop] = undefined
    } catch (e) {}
    this.value = undefined
    this.searchItems = undefined
    this.keyboardNavigationIndex = -1
    this.selectRecordEvent.emit(undefined)
    this.onChange(undefined)
    this.clearEvent.emit(true)
    this.setViewValueByResolvedRelation()
  }

  onFocusOut(e: FocusEvent) {
    if (this.readonly) return

    this.searchItems = undefined
    this.keyboardNavigationIndex = -1
    this.setViewValueByResolvedRelation()
  }

  onFocusIn(e: FocusEvent) {
    if (this.readonly) return
    this.viewValue = ''
  }

  registerOnChange(onChange: any): void {
    if (this.readonly) return
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any) {
    if (this.readonly) return
    this.onTouched = onTouched
  }

  setDisabledState(disabled: boolean): void {
    if (this.readonly) return
    this.disabled = disabled
  }

  writeValue(id: number | undefined): void {
    this.value = id
    if (! id) this.clearField()
    else this.setViewValueByResolvedRelation()
  }

  markAsTouched() {
    if (this.readonly) return

    if (! this.touched) {
      this.onTouched()
      this.touched = true
    }
  }

  getVisibleName(record: RecordInterface<RecordDataInterface>) {

    if (! this.constraintNameFieldConfig) return record.data[this.nameProperty]

    if (this.constraintNameFieldConfig.hasOwnProperty("constraintComponentFieldRenderer")) {
      if (this.constraintNameFieldConfig.constraintComponentFieldRenderer) return this.constraintNameFieldConfig.constraintComponentFieldRenderer(record)
    }

    return record.data[this.nameProperty]
  }

  saveRecord(record: K) {
    this.recordService!.create(record).subscribe(response => {
      this.selectRecord(response as T)
      this.toastService.showSuccess('Datensatz wurde erfolgreich angelegt!')
    }, error => {
      this.toastService.showApiError(error, 'Datensatz konnte nicht angelegt werden!')
    })
  }
}
