<wefra-edit-grid
  [modelConfig]="pulloutModelConfig"
  [fixedFilters]="fixedFilters"
  [limitToFields]="[ 'pullout_id', 'mutual_price', 'unpayed_pages' ]"
  [editableFields]="[ 'unpayed_pages' ]"
  [actions]="[]"
  [controls]="[]"
  (keyup)="onKeyUp($event)"
  (updateRecord)="onUpdateSuccess($event)"
></wefra-edit-grid>
