import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import {
  PublisherModelConfiguration,
  PublisherRecordDataInterface,
  PublisherRecordInterface
} from "../models/publisher";
import { HttpPublisherService } from "../services/http/publisher.service";

@Component({
    selector: 'wefra-publishers',
    templateUrl: '../abstract-records/abstract-record.component.html',
    standalone: false
})
export class PublishersComponent extends AbstractRecordComponent<PublisherRecordInterface, PublisherRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = PublisherModelConfiguration
  api = inject(HttpPublisherService)

  constructor(
  ) {
    super()
  }
}
