import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegExp } from "../helpers/main";

@Pipe({
    name: "htmlToText",
    standalone: false
})

export class HtmlToTextPipe implements PipeTransform {
  transform(value: string | undefined) {
    if (typeof value == "undefined") {
      return ''
    }

    return value.replace(new RegExp(escapeRegExp('&shy;')), '')
  }
}
