import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "price",
    standalone: false
})

export class EuroPricePipe implements PipeTransform {
  transform(value: number | string | undefined) {
    if (typeof value == "undefined") {
      value = 0
    }
    if (typeof value == "string") {
      value = parseFloat(value)
    }

    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    });

    return formatter.format(value);
  }
}
