import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  TargetAudiencePeriodEntryRecordDataInterface,
  TargetAudiencePeriodEntryRecordInterface
} from "../../models/target_audience_period_entry";

@Injectable()
export class HttpTargetAudiencePeriodEntryService extends HttpAbstractRecordService<TargetAudiencePeriodEntryRecordInterface, TargetAudiencePeriodEntryRecordDataInterface> {
  slug: ServiceSlugProperty = 'target_audience_period_entries'
}
