import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import { ColorModelConfiguration, ColorRecordDataInterface, ColorRecordInterface } from "../models/color";
import { HttpColorService } from "../services/http/color.service";

@Component({
    selector: 'wefra-Colors',
    templateUrl: '../abstract-records/abstract-record.component.html',
    standalone: false
})
export class ColorsComponent extends AbstractRecordComponent<ColorRecordInterface, ColorRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = ColorModelConfiguration
  api = inject(HttpColorService)
}
