import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RecordDataInterface, RecordInterface, ServiceSlugProperty } from "../models/main";

@Component({
    selector: 'wefra-crud-grid-historicize',
    templateUrl: './historicize.component.html',
    standalone: false
})
export class CrudGridHistoricizeComponent<T extends RecordInterface<K>, K extends RecordDataInterface> {

  @Output("historicizeWith") historicizeWithEvent = new EventEmitter<T>()
  @Output("cancel") cancelEvent = new EventEmitter<boolean>()

  @Input() recordToHistoricize?: RecordInterface<RecordDataInterface>
  @Input() serviceSlug: ServiceSlugProperty = "not_defined"

  recordToHistoricizeWith?: RecordInterface<RecordDataInterface>
  invalid = false

  onSubmit() {
    if (! this.recordToHistoricizeWith) {
      this.invalid = true
      return
    }
    this.historicizeWithEvent.emit(this.recordToHistoricizeWith as T)
  }

  onCancel() {
    this.cancelEvent.emit(true)
  }

  onSelectRecordToHistoricizeWith(recordToHistoricizeWith?: RecordInterface<RecordDataInterface>) {
    this.recordToHistoricizeWith = recordToHistoricizeWith
    this.invalid = ! recordToHistoricizeWith
  }
}
