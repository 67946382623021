import { Component, Inject } from '@angular/core';
import { AuthService } from "./services/auth.service";
import { WasmuthRatecardNumberModelConfiguration } from "./models/wasmuth_ratecard_numbers";
import { WasmuthRatecardModelConfiguration } from "./models/wasmuth_ratecards";
import { ModelConfigFactory } from "./models/factory";
import { WasmuthMediaRatecardModelConfiguration } from "./models/wasmuth_media_ratecards";
import { ProductCategoryModelConfiguration } from "./models/product_category";
import { CompanyCategoryModelConfiguration } from "./models/company_category";
import { ProductModelConfiguration } from "./models/product";
import { MarketModelConfiguration } from "./models/market";
import { GroupModelConfiguration } from "./models/group";
import { MotiveModelConfiguration } from "./models/motive";
import { EditionModelConfiguration } from "./models/edition";
import { CategoryModelConfiguration } from "./models/category";
import { WasmuthMediaModelConfiguration } from "./models/wasmuth_media";
import { CompanyModelConfiguration } from "./models/company";
import { WasmuthPublisherModelConfiguration } from "./models/wasmuth_publisher";
import { WasmuthPublicationDateModelConfiguration } from "./models/wasmuth_publication_dates";
import { MagazineModelConfiguration } from "./models/magazine";
import { MediaTypeModelConfiguration } from "./models/media_type";
import { FormatModelConfiguration } from "./models/format";
import { PriceModelConfiguration } from "./models/price";
import { AdvertisingFormModelConfiguration } from "./models/advertising_form";
import { AdSpendingModelConfiguration } from "./models/ad_spending";
import { ColorModelConfiguration } from "./models/color";
import { WasmuthFktPlacementModelConfiguration } from "./models/wasmuth_fkt_placement";
import { WasmuthFktFormModelConfiguration } from "./models/wasmuth_fkt_form";
import { WasmuthAdModelConfiguration } from "./models/wasmuth_ads";
import { WasmuthFktColourModelConfiguration } from "./models/wasmuth_fkt_colour";
import { WasmuthAdSizeModelConfiguration } from "./models/wasmuth_ad_size";
import { WasmuthFktCostTypeModelConfiguration } from "./models/wasmuth_fkt_cost_type";
import { WasmuthFktPageFormatCodeModelConfiguration } from "./models/wasmuth_fkt_page_format_code";
import { WasmuthAdFormatModelConfiguration } from "./models/wasmuth_ad_format";
import { WasmuthAdCostModelConfiguration } from "./models/wasmuth_ad_cost";
import { WasmuthFktAppearingWeeklyModelConfiguration } from "./models/wasmuth_fkt_appearing_weekly";
import { WasmuthFktFromToModelConfiguration } from "./models/wasmuth_fkt_from_to";
import { WasmuthFktPriceTypeModelConfiguration } from "./models/wasmuth_fkt_price_type";
import { WasmuthFktUnitModelConfiguration } from "./models/wasmuth_fkt_unit";
import { WasmuthAdspecialModelConfiguration } from "./models/wasmuth_adspecials";
import { WasmuthFktAdspecialTypeModelConfiguration } from "./models/wasmuth_fkt_adspecial_type";
import { WasmuthAdspecialCostModelConfiguration } from "./models/wasmuth_adspecial_cost";
import { WasmuthFktAdspecialCostTypeModelConfiguration } from "./models/wasmuth_fkt_adspecial_cost_type";
import { SubmarketModelConfiguration } from "./models/submarket";
import { PriceTypeModelConfiguration } from "./models/price_type";
import { TargetAudienceModelConfiguration } from "./models/target_audience";
import { WasmuthMediaEditionModelConfiguration } from "./models/wasmuth_media_editions";
import { PublisherModelConfiguration } from "./models/publisher";
import { ApilogModelConfiguration } from "./models/apilog";
import { UserModelConfiguration } from "./models/user";
import { WefraPubdateModelConfiguration } from "./models/wefra_pubdate";
import { WefraRatecardModelConfiguration } from "./models/wefra_ratecard";
import { ImportedCirculationModelConfiguration } from "./models/imported_circulation";
import { PubdateNoticeModelConfiguration } from "./models/pubdate_notice";
import { PubdateAliasModelConfiguration } from "./models/pubdate_alias";
import { PulloutModelConfiguration } from "./models/pullout";
import { TargetAudiencePeriodModelConfiguration } from "./models/target_audience_period";
import { TargetAudiencePeriodEntryModelConfiguration } from "./models/target_audience_period_entry";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  title = 'pharmamonitor';
  authenticated: boolean = false

  constructor(
    @Inject(AuthService) private authService: AuthService
  ) {
    ModelConfigFactory.register('wasmuth_ratecard_numbers', WasmuthRatecardNumberModelConfiguration)
    ModelConfigFactory.register('wasmuth_ratecards', WasmuthRatecardModelConfiguration)
    ModelConfigFactory.register('wasmuth_media_ratecards', WasmuthMediaRatecardModelConfiguration)
    ModelConfigFactory.register('product_categories', ProductCategoryModelConfiguration)
    ModelConfigFactory.register('company_categories', CompanyCategoryModelConfiguration)
    ModelConfigFactory.register('markets', MarketModelConfiguration)
    ModelConfigFactory.register('groups', GroupModelConfiguration)
    ModelConfigFactory.register('motives', MotiveModelConfiguration)
    ModelConfigFactory.register('submarkets', SubmarketModelConfiguration)
    ModelConfigFactory.register('products', ProductModelConfiguration)
    ModelConfigFactory.register('editions', EditionModelConfiguration)
    ModelConfigFactory.register('categories', CategoryModelConfiguration)
    ModelConfigFactory.register('wasmuth_media', WasmuthMediaModelConfiguration)
    ModelConfigFactory.register('companies', CompanyModelConfiguration)
    ModelConfigFactory.register('wasmuth_publishers', WasmuthPublisherModelConfiguration)
    ModelConfigFactory.register('wasmuth_publication_dates', WasmuthPublicationDateModelConfiguration)
    ModelConfigFactory.register('magazines', MagazineModelConfiguration)
    ModelConfigFactory.register('media_types', MediaTypeModelConfiguration)
    ModelConfigFactory.register('formats', FormatModelConfiguration)
    ModelConfigFactory.register('advertising_forms', AdvertisingFormModelConfiguration)
    ModelConfigFactory.register('prices', PriceModelConfiguration)
    ModelConfigFactory.register('ad_spendings', AdSpendingModelConfiguration)
    ModelConfigFactory.register('colors', ColorModelConfiguration)
    ModelConfigFactory.register('price_types', PriceTypeModelConfiguration)
    ModelConfigFactory.register('target_audiences', TargetAudienceModelConfiguration)
    ModelConfigFactory.register('wasmuth_ads', WasmuthAdModelConfiguration)
    ModelConfigFactory.register('wasmuth_fkt_forms', WasmuthFktFormModelConfiguration)
    ModelConfigFactory.register('wasmuth_fkt_placements', WasmuthFktPlacementModelConfiguration)
    ModelConfigFactory.register('wasmuth_fkt_colours', WasmuthFktColourModelConfiguration)
    ModelConfigFactory.register('wasmuth_ad_sizes', WasmuthAdSizeModelConfiguration)
    ModelConfigFactory.register('wasmuth_fkt_cost_types', WasmuthFktCostTypeModelConfiguration)
    ModelConfigFactory.register('wasmuth_fkt_page_format_codes', WasmuthFktPageFormatCodeModelConfiguration)
    ModelConfigFactory.register('wasmuth_ad_formats', WasmuthAdFormatModelConfiguration)
    ModelConfigFactory.register('wasmuth_ad_costs', WasmuthAdCostModelConfiguration)
    ModelConfigFactory.register('wasmuth_fkt_appearing_weekly', WasmuthFktAppearingWeeklyModelConfiguration)
    ModelConfigFactory.register('wasmuth_fkt_from_to', WasmuthFktFromToModelConfiguration)
    ModelConfigFactory.register('wasmuth_fkt_price_types', WasmuthFktPriceTypeModelConfiguration)
    ModelConfigFactory.register('wasmuth_fkt_units', WasmuthFktUnitModelConfiguration)
    ModelConfigFactory.register('wasmuth_adspecials', WasmuthAdspecialModelConfiguration)
    ModelConfigFactory.register('wasmuth_fkt_adspecial_types', WasmuthFktAdspecialTypeModelConfiguration)
    ModelConfigFactory.register('wasmuth_adspecial_costs', WasmuthAdspecialCostModelConfiguration)
    ModelConfigFactory.register('wasmuth_fkt_adspecial_cost_types', WasmuthFktAdspecialCostTypeModelConfiguration)
    ModelConfigFactory.register("wasmuth_media_editions", WasmuthMediaEditionModelConfiguration)
    ModelConfigFactory.register("publishers", PublisherModelConfiguration)
    ModelConfigFactory.register("apilogs", ApilogModelConfiguration)
    ModelConfigFactory.register("users", UserModelConfiguration)
    ModelConfigFactory.register("wefra_pubdates", WefraPubdateModelConfiguration)
    ModelConfigFactory.register("wefra_ratecards", WefraRatecardModelConfiguration)
    ModelConfigFactory.register("imported_circulations", ImportedCirculationModelConfiguration)
    ModelConfigFactory.register("pubdate_notices", PubdateNoticeModelConfiguration)
    ModelConfigFactory.register("pubdate_aliases", PubdateAliasModelConfiguration)
    ModelConfigFactory.register("pullouts", PulloutModelConfiguration)
    ModelConfigFactory.register("target_audience_periods", TargetAudiencePeriodModelConfiguration)
    ModelConfigFactory.register("target_audience_period_entries", TargetAudiencePeriodEntryModelConfiguration)

    this.authService.userSubject.subscribe(user => {
      if (user) {
        this.authenticated = true
      } else {
        this.authenticated = false
      }
    })
  }
}
