import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IndexSignature, ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "../../models/main";

@Component({
    selector: 'wefra-abstract-record-view',
    templateUrl: './view.component.html',
    standalone: false
})
export class AbstractRecordsViewComponent<T extends RecordInterface<K>, K extends RecordDataInterface> implements OnInit {
  @Input({ required: true }) public record?: T
  @Input({ required: true }) public modelConfig?: ModelConfigurationInterface

  @Output("closeClick") closeClickEvent = new EventEmitter<boolean>()

  ngOnInit(): void {

    if (! this.modelConfig) return
    if (! this.record) return

    const record = this.record

    let controls: IndexSignature = {}
    Object.entries(this.modelConfig.fields).forEach(([prop, fieldConfig]) => {
      let control = [ record.data[prop] ]
      if (fieldConfig.validators) {
        control.push(fieldConfig.validators)
      }
      controls[prop] = control
    })

    let options: IndexSignature = {}
    if (this.modelConfig.validators) {
      options["validators"] = this.modelConfig.validators
    }
  }
  onClose() {
    this.closeClickEvent.emit(true)
  }
}
