import { Component, inject } from '@angular/core';
import { AbstractRecordComponent } from "../../../abstract-records/abstract-record.component";
import { ModelConfigurationInterface } from "../../../models/main";
import {
  WasmuthMediaRatecardModelConfiguration,
  WasmuthMediaRatecardRecordDataInterface,
  WasmuthMediaRatecardRecordInterface
} from "../../../models/wasmuth_media_ratecards";
import { HttpWasmuthMediaRatecardService } from "../../../services/http/wasmuth_media_ratecard.service";

@Component({
    selector: 'wefra-wasmuth-tables-media-ratecard',
    templateUrl: '../../../abstract-records/abstract-record.component.html',
    standalone: false
})
export class WasmuthTablesMediaRatecardComponent extends AbstractRecordComponent<WasmuthMediaRatecardRecordInterface, WasmuthMediaRatecardRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = WasmuthMediaRatecardModelConfiguration
  api = inject(HttpWasmuthMediaRatecardService)

  constructor(
  ) {
    super()
  }
}
