import { Pipe, PipeTransform } from '@angular/core';
import { MyDate } from "../helpers/main";

@Pipe({
    name: "mydatetime",
    standalone: false
})

export class MyDateTimePipe implements PipeTransform {
  transform(value: string | undefined) {
    if (typeof value == "undefined") {
      value = '1970-01-01 00:00:00'
    }

    const date = new MyDate(value)
    return date.getDateTimeString()
  }
}
