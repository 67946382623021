import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FieldRecordInterface,
  FieldRendererContext,
  ModelConfigurationFieldInterface,
  RecordDataInterface,
  RecordInterface
} from "../models/main";
import { CrudGridComponent } from "./crud-grid.component";
import { MiniGridComponent } from "../mini-grid/mini-grid.component";

@Component({
    selector: 'wefra-crud-grid-json-renderer',
    templateUrl: './field-renderer-json.html',
    standalone: false
})
export class CrudGridJsonFieldRendererComponent<T extends RecordInterface<K>, K extends RecordDataInterface> {
  @Input({ required: true }) public record?: T
  @Input({ required: true }) public fieldConfig?: ModelConfigurationFieldInterface
  @Input({ required: false }) public grid?: CrudGridComponent<T, K> | MiniGridComponent<T, K>
  @Input({ required: false }) public context: FieldRendererContext = "crud-grid";

  @Output("showInFullWidth") showInFullWidthEvent = new EventEmitter<FieldRecordInterface<T, K>>()

  isCollapsed: boolean = true

  onClick() {
    if (! this.fieldConfig || ! this.record) return
    this.isCollapsed = ! this.isCollapsed
    this.showInFullWidthEvent.emit({ field: this.fieldConfig, record: this.record })
  }
}
