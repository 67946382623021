import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'wefra-crud-grid-delete',
    templateUrl: './delete.component.html',
    standalone: false
})
export class CrudGridDeleteComponent {
  @Output("decision") decisionEvent = new EventEmitter<boolean>()
}
