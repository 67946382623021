import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, signal } from '@angular/core';
import { MagazineListResponseItemInterface, MonitorResponseItemInterface } from "../models/main";
import { ToastService } from "../services/toast.service";
import { Router } from "@angular/router";
import { MyDate, renderDateFromString } from "../helpers/main";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MonitorService } from "../services/monitor.service";
import { ModalService } from "../services/modal.service";
import { MonitorNoticeComponent } from "./notice.component";
import { AuthService } from "../services/auth.service";
import { MagazineRecordInterface } from "../models/magazine";
import { MonitorAliasComponent } from "./alias.component";
import { PubdateAliasRecordDataInterface } from "../models/pubdate_alias";
import { HttpPubdateAliasService } from "../services/http/pubdate_alias.service";

@Component({
    selector: 'wefra-monitor-component',
    templateUrl: './monitor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MonitorComponent implements OnInit {

  from: MyDate = new MyDate()
  to: MyDate = new MyDate()
  magazine?: string
  status?: number

  now: MyDate = new MyDate()

  days: string[] = []
  weeks: number[] = []
  weekspan: number[] = []
  months: string[] = []

  futureMags = 0
  completedMags = 0
  partialMags = 0
  scannedMags = 0
  userIsPowerUser: boolean = false
  userIsSpaUser: boolean = false
  magazines?: MagazineListResponseItemInterface[]
  loading: boolean = false
  animationRefreshIntervalId?: number
  hoverDate: string = 'nothingelsehovers'
  filterForm?: FormGroup
  publisher: string = ""
  publishers: {id: number, name: string}[] = []
  withAliases = false

  hydratedMagazines$ = signal<MagazineListResponseItemInterface[] | undefined>([])

  constructor(
    @Inject(MonitorService) protected monitorService: MonitorService,
    @Inject(ToastService) protected toastService: ToastService,
    @Inject(ModalService) protected modalService: ModalService,
    @Inject(AuthService) protected authService: AuthService,
    @Inject(HttpPubdateAliasService) private pdAliasService: HttpPubdateAliasService,
    private router: Router,
    private cd: ChangeDetectorRef,
    public fb: FormBuilder,
  ) {
    this.from.setDate(1)
    this.from.setHours(this.from.getTimezoneOffset() / -60)
    this.from.setMinutes(0)
    this.from.setSeconds(0)

    this.to = new MyDate(this.from.valueOf())
    this.to.addMonth(1)
    this.to.subDay(1)

    this.filterForm = this.fb.group({
      from: [ this.from ],
      to: [ this.to ],
      year: [ 0 ],
      magazine: [ this.magazine ],
      publisher: [ this.publisher ],
      status: [ this.status ],
      withAliases: [ this.withAliases ]
    })
  }

  ngOnInit(): void {
    this.userIsPowerUser = this.authService.hasAbility('spa-poweruser')
    this.userIsSpaUser = this.authService.hasAbility('spa-user')
    this.load()
  }

  onSubmit(filters: any) {
    if (filters.from) this.from = filters.from
    if (filters.to) this.to = filters.to
    if (filters.status) {
      this.status = filters.status
    } else {
      this.status = undefined
    }
    if (filters.magazine) {
      this.magazine = filters.magazine
    } else {
      this.magazine = undefined
    }
    if (filters.publisher) {
      this.publisher = filters.publisher
    } else {
      this.publisher = ""
    }

    this.withAliases = filters.withAliases
    this.load()
  }

  showMagazine(magazine:MagazineListResponseItemInterface) {
    let show = true

    if (this.publisher) {
      if (this.publisher.toLowerCase() != magazine.publisher_name.substring(0, this.publisher.length).toLowerCase()) show = false
    }

    if (this.magazine) {
      if (this.magazine.toLowerCase() != magazine.name.substring(0, this.magazine.length).toLowerCase()) show = false
    }

    return show
  }

  getCssStateClass(magazine: MagazineListResponseItemInterface) {
    switch (magazine.worst_capture_state) {
      case null: return 'null'
      case undefined: return 'null'
      case 0: return "scanned"
      case 1: return "capturing"
      case 2: return "completed"
      case 3: return "future"
    }

    return "future"
  }

  renderEditionNumber(m: MonitorResponseItemInterface) {
    return m.edition_name.split('/')[0]
  }

  onClickAlias(m: MonitorResponseItemInterface) {
    if (! this.userIsSpaUser) {
      return
    }

    let that = this

    if (m.alias_id) {
      that.pdAliasService.deleteById(m.alias_id).subscribe(response => {
        that.toastService.showSuccess('Das Erscheinungsdatum wurde wieder eingeblendet!')
        that.load()
      }, () => {
        that.toastService.showError('Das Erscheinungsdatum konnte nicht wieder eingeblendet werden!')
      })
    } else {
      let modal = this.modalService.show(MonitorAliasComponent, {
        title: "Erscheinungsdatum ausblenden",
        inputs: {
          monitorItem: m,
          readonly: !that.userIsSpaUser
        },
        modalOptions: {
          windowClass: 'alias-select',
        },
        closeBtnText: undefined,
        subscribers: {
          closeEvent: function () {
            modal.close()
          },
          saveAliasEvent: function (record: PubdateAliasRecordDataInterface) {
            that.pdAliasService.create(record).subscribe(response => {
              that.toastService.showSuccess('Das Duplikat wurde markiert!')
            }, () => {
              that.toastService.showError('Das Duplikat konnte nicht markiert werden!')
            }, () => {
              modal.close()
              that.load()
            })
          },
          hideRecordEvent: function (record: PubdateAliasRecordDataInterface) {
            that.pdAliasService.create(record).subscribe(response => {
              that.toastService.showSuccess('Das Erscheinungsdatum wurde ausgeblendet!')
            }, () => {
              that.toastService.showError('Das Erscheinungsdatum konnte nicht ausgeblendet werden!')
            }, () => {
              modal.close()
              that.load()
            })
          }
        }
      })
    }
  }

  onClickAdspending(m: MonitorResponseItemInterface) {
    if (m.edition_id && m.capture_state !== null && m.capture_state >= 1) {
      this.router.navigateByUrl("ad-spendings/capture/" + m.edition_id)
    }
  }

  onClickNotice(m: MagazineListResponseItemInterface) {
    if (! this.userIsSpaUser) {
      return
    }

    let that = this
    let modal = this.modalService.show(MonitorNoticeComponent, {
      inputs: {
        magazineItem: m,
        readonly: ! that.userIsSpaUser
      },
      closeBtnText: undefined,
      modalOptions: {

      },
      title: 'Notiz für "' + m.name + '" bearbeiten',
      subscribers: {
        closeEvent: function(c: boolean) {
          modal.close()
        },
        saveMessageEvent: function(m: MagazineRecordInterface) {
          that.hydratedMagazines$.update(mh => { mh?.map(item => {
              if (item.id == m.data.id) {
                item.notice = m.data.notice ?? ''
              }
            })
            return mh
          })
          that.cd.detectChanges()
        }
      }
    })
  }

  onClickEdition(m: MonitorResponseItemInterface) {
    if (m.edition_id && m.capture_state !== null) {
      this.router.navigateByUrl("ad-spendings/edition/" + m.edition_id)
    }
  }

  dateIsInFuture(data: MonitorResponseItemInterface) {
    const date = new Date(data.publication_date)
    return date > this.now
  }

  renderMonth(monthofyear: string): string {
    let monthandyear = monthofyear.split('-').map((v) => { return parseInt(v, 10)})
    if (monthandyear.length !== 2) return ''
    let date = new Date(monthandyear[0]!, monthandyear[1]! - 1, 1)
    return date.toLocaleDateString('de-DE', { month: 'long', year: 'numeric' })
  }

  /**
   * calculates the to date, days and weeks by from date
   */
  calculateDates() {
    let from = new MyDate(this.from.valueOf())
    this.days = []
    this.weeks = []
    this.weekspan = []
    this.months = []
    let i = 0
    while (from.valueOf() <= this.to.valueOf()) {
      this.days.push(from.getISO8601String())

      const week = from.getWeekOfYear()
      if (! this.weeks.includes(week)) this.weeks.push(week)
      if (! this.weekspan[week]) this.weekspan[week] = 1
      else this.weekspan[week]++

      const month = from.getYearAndMonth()
      // @ts-ignore
      if (! this.months[month]) this.months[month] = 1
      // @ts-ignore
      else this.months[month]++

      from.addDay(1)
    }
    this.cd.detectChanges()
  }

  startLoadingAnimation() {
    this.loading = true
    this.animationRefreshIntervalId = window.setInterval(() => {
      this.cd.detectChanges()
    }, 50)
  }

  stopLoadingAnimation() {
    this.loading = false
    if (this.animationRefreshIntervalId) clearInterval(this.animationRefreshIntervalId)
    this.cd.detectChanges()
  }

  load() {
    this.startLoadingAnimation()
    this.calculateDates()
    this.monitorService.getMonitorData({
      from: this.from,
      to: this.to,
      magazine: this.magazine,
      publisher: this.publisher,
      withAliases: this.withAliases ? '1' : '0'
    }).subscribe(response => {

      this.futureMags = 0
      this.completedMags = 0
      this.partialMags = 0
      this.scannedMags = 0

      this.magazines = response.magazines

      if (this.magazines) {
        for (let magazine of this.magazines) {
          magazine.worst_capture_state = 3
          let magazineEntries = response.entries.filter(v => {
              if (magazine.wasmuth_id) return v.ratecard_id == magazine.wasmuth_id+''
              else return v.magazine_id == magazine.id
          })
          magazine.editions = {}
          if (magazineEntries.length) {
            for (let entry of magazineEntries) {
              if (this.dateIsInFuture(entry)) {
                this.futureMags++
                if (magazine.worst_capture_state === null) magazine.worst_capture_state = 3
              } else if (entry.capture_state === null) {
                this.scannedMags++
                magazine.worst_capture_state = 0
              } else {
                if (entry.capture_state == 1) { this.partialMags++ }
                if (entry.capture_state == 2) { this.completedMags++ }
                if (magazine.worst_capture_state !== null && entry.capture_state !== null && entry.capture_state < magazine.worst_capture_state) {
                  magazine.worst_capture_state = entry.capture_state
                }
              }
              const key = entry.year + '-' + (entry.month < 10 ? '0' : '') + entry.month + '-' + (entry.day < 10 ? '0' : '') + entry.day
              if (! (key in magazine.editions)) {
                magazine.editions[key] = []
              }
              magazine.editions[key]!.push(entry)
            }
          } else {
            magazine.worst_capture_state = 3
          }
        }
      }

      this.hydratedMagazines$.set(this.magazines)
      this.stopLoadingAnimation()
      this.cd.detectChanges()
    }, error => {
      this.toastService.showError('Die Daten konnten nicht geladen werden.')
      this.hydratedMagazines$.set(undefined)
      this.stopLoadingAnimation()
      this.cd.detectChanges()
    })
  }

  protected readonly renderDateFromString = renderDateFromString;
}
