import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import {
  AdvertisingFormModelConfiguration,
  AdvertisingFormRecordDataInterface,
  AdvertisingFormRecordInterface
} from "../models/advertising_form";
import { HttpAdvertisingFormService } from "../services/http/advertising_form.service";

@Component({
    selector: 'wefra-advertisingforms',
    templateUrl: '../abstract-records/abstract-record.component.html',
    standalone: false
})
export class AdvertisingFormsComponent extends AbstractRecordComponent<AdvertisingFormRecordInterface, AdvertisingFormRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = AdvertisingFormModelConfiguration
  api = inject(HttpAdvertisingFormService)

  constructor(
  ) {
    super()
  }
}
