import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface PubdateNoticeRecordDataInterface extends RecordDataInterface {
  id?: number
  medium_id: string
  ratecard_id: string
  ratecard_number: string
  publication_date: string
  edition_number: number
  format_key: number
  format_number: number
  notice: string
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface PubdateNoticeRecordInterface extends RecordInterface<PubdateNoticeRecordDataInterface> {
}

export const PubdateNoticeModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "pubdate_notices",
  allowExportXlsx: false,
  allowReplace: false,
  title: "Pubication Date Notizen",
  titleSingular: "Pubication Date Notiz",
  defaultSort: [{ field: 'id', dir: 'asc' }],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { type: "text", title: 'Notiz', prop: 'notice', isNameProperty: true, validators: [ Validators.required ]},
    // If needed, complete constraints
    // { prop: 'product_id', type: "constraint", title: 'Produkt', constraint: { table: 'products' }, validators: [ Validators.required ]},

    { prop: "created_by", ability: "spa-userinfo", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-userinfo", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-userinfo", type: "date", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-userinfo", type: "date", title: 'Bearbeitet am', edit: false},
  ]
}

