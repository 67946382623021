import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration, withIncrementalHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from "./navigation/navigation.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LeftnavComponent } from "./leftnav/leftnav.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { ToastService } from "./services/toast.service";
import { HttpAuthService } from "./services/http/auth.service";
import { LocalStorageService } from "./services/localstorage.service";
import { AuthService } from "./services/auth.service";
import { ReactiveFormsModule } from "@angular/forms";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {
  arrowClockwise,
  arrowDown,
  arrowDownUp,
  arrowLeftSquare,
  arrowRightSquare,
  arrowUp,
  bookmarkCheck,
  boxArrowRight,
  boxes,
  calendar2Date,
  cardList,
  chatRightText,
  chatRightTextFill,
  check, checkCircle,
  checkSquare,
  chevronDoubleLeft,
  chevronDoubleRight,
  chevronLeft,
  chevronRight,
  circle,
  clipboard2,
  clipboard2Check,
  clipboard2Plus,
  coin,
  collection,
  cupHot,
  eye,
  fileEarmarkArrowDown,
  files,
  filetypeJson,
  filetypeXlsx,
  listCheck,
  NgxBootstrapIconsModule,
  pencil,
  plusCircle,
  repeat,
  toggleOff,
  toggleOn,
  x,
  xCircle,
  xLg,
  xOctagon
} from 'ngx-bootstrap-icons';
import { FormFieldErrorComponent } from "./form/field.error.component";
import { ToastsContainer } from "./services/toast.service.container";
import { HttpInterceptorProviders } from "./services/http/interceptors/registry";
import { CrudGridComponent } from './crud-grid/crud-grid.component';
import { CompaniesComponent } from './companies/companies.component';
import { MarketsComponent } from './markets/markets.component';
import { HttpMarketService } from "./services/http/market.service";
import { GenericFormComponent } from './form/generic-form/generic-form.component';
import { ControlsComponent } from './crud-grid/controls/controls.component';
import { SubmarketsComponent } from "./submarkets/submarkets.component";
import { HttpSubmarketService } from "./services/http/submarket.service";
import { CrudGridFieldRendererComponent } from "./crud-grid/field-renderer";
import { ConstraintComponent } from "./form/generic-form/field/constraint.component";
import { HttpServiceFactory } from "./services/http/factory";
import { HttpGroupService } from "./services/http/group.service";
import { HttpProductService } from "./services/http/product.service";
import { HttpCompanyCategoryService } from "./services/http/company_category.service";
import { HttpCompanyService } from "./services/http/company.service";
import { HttpCategoryService } from "./services/http/category.service";
import { PaginationComponent } from "./abstract-grid/pagination/pagination.component";
import { AdSpendingsComponent } from './ad-spendings/ad-spendings.component';
import { UploadComponent } from './ad-spendings/upload/upload.component';
import { AdSpendingsGridComponent } from './ad-spendings/edition/grid.component';
import { WasmuthComponent } from './wasmuth/wasmuth.component';
import { WasmuthTablesMediaComponent } from './wasmuth/tables/media/media.component';
import { HttpWasmuthMediaService } from "./services/http/wasmuth_media.service";
import { AbstractRecordsViewComponent } from './abstract-records/view/view.component';
import { WasmuthTablesMediaRatecardComponent } from "./wasmuth/tables/media_ratecard/media_ratecard.component";
import { HttpWasmuthMediaRatecardService } from "./services/http/wasmuth_media_ratecard.service";
import { HttpMotiveService } from "./services/http/motive.service";
import { MotivesComponent } from "./motives/motives.component";
import { PublishersComponent } from "./publishers/publishers.component";
import { ProductCategoriesComponent } from "./product_categories/product_categories.component";
import { HttpProductCategoryService } from "./services/http/product_category.service";
import { HttpEditionService } from "./services/http/edition.service";
import { HttpWasmuthRatecardNumberService } from "./services/http/wasmuth_ratecard_number.service";
import { HttpWasmuthRatecardService } from "./services/http/wasmuth_ratecard.service";
import { HttpWasmuthPublisherService } from "./services/http/wasmuth_publisher.service";
import { HttpWasmuthPublicationDateService } from "./services/http/wasmuth_publication_date.service";
import { HttpAdSpendingsService } from "./services/http/ad_spendings.service";
import { HttpMediaTypeService } from "./services/http/mediy_type.service";
import { HttpMagazineService } from "./services/http/magazine.service";
import { HttpFormatService } from "./services/http/format.service";
import { HttpPriceService } from "./services/http/price.service";
import { HttpAdvertisingFormService } from "./services/http/advertising_form.service";
import { HttpColorService } from "./services/http/color.service";
import { HttpPriceTypeService } from "./services/http/price_type.service";
import { AdSpendingsCaptureComponent } from './ad-spendings/capture/capture.component';
import { HttpWasmuthAdService } from "./services/http/wasmuth_ad.service";
import { HttpWasmuthFktPlacementService } from "./services/http/wasmuth_fkt_placement.service";
import { HttpWasmuthFktFormService } from "./services/http/wasmuth_fkt_form.service";
import { MultiComponent } from "./form/generic-form/field/multi.component";
import { HttpWasmuthFktColourService } from "./services/http/wasmuth_fkt_colour.service";
import { HttpWasmuthAdSizeService } from "./services/http/wasmuth_ad_size.service";
import { HttpExportService } from "./services/http/export.service";
import { HttpWasmuthFktCostTypeService } from "./services/http/wasmuth_fkt_cost_type.service";
import { HttpWasmuthFktPageFormatCodeService } from "./services/http/wasmuth_fkt_page_format_code.service";
import { HttpWasmuthAdFormatService } from "./services/http/wasmuth_ad_format.service";
import { HttpWasmuthAdCostService } from "./services/http/wasmuth_ad_cost.service";
import { HttpWasmuthFktAppearingWeeklyService } from "./services/http/wasmuth_fkt_appearing_weekly.service";
import { HttpWasmuthFktFromToService } from "./services/http/wasmuth_fkt_from_to.service";
import { HttpWasmuthFktPriceTypeService } from "./services/http/wasmuth_fkt_price_type.service";
import { HttpWasmuthFktUnitService } from "./services/http/wasmuth_fkt_unit.service";
import { HttpWasmuthFktAdspecialTypeService } from "./services/http/wasmuth_fkt_adspecial_type.service";
import { HttpWasmuthFktAdspecialCostTypeService } from "./services/http/wasmuth_fkt_adspecial_cost_type.service";
import { HttpWasmuthAdspecialService } from "./services/http/wasmuth_adspecial.service";
import { HttpWasmuthAdspecialCostService } from "./services/http/wasmuth_adspecial_cost.service";
import { WheelDirective } from "./directives/wheel.directive";
import { NgbdModalContent } from "./services/modal.service.container";
import { AdSpendingsIsMultiQuestionComponent } from "./ad-spendings/is-multi-question/is-multi-question.component";
import { CategoriesComponent } from "./categories/categories.component";
import { FormatsComponent } from "./formats/formats.component";
import { PricesComponent } from "./prices/prices.component";
import { ColorsComponent } from "./colors/colors.component";
import { AdvertisingFormsComponent } from "./advertising_forms/advertising_forms.component";
import { HttpTargetAudienceService } from "./services/http/target_audience.service";
import { StateService } from "./services/state.service";
import { EditionShowComponent } from "./ad-spendings/edition/show.component";
import { PinchZoomComponent } from "@meddv/ngx-pinch-zoom";
import { HttpWasmuthMediaEditionService } from "./services/http/wasmuth_media_edition.service";
import { EuroPricePipe } from "./pipes/price.pipe";
import { MyDatePipe } from "./pipes/date.pipe";
import { CrudGridDeleteComponent } from "./crud-grid/delete.component";
import { NgxModalDraggableDirective } from "./directives/draggable.directive";
import { AdSpendingsTableComponent } from "./ad-spendings/table/table.component";
import { CompanyCategoryComponent } from "./company_categories/company_categories.component";
import { MagazinesComponent } from "./magazines/magazines.component";
import { MonitorComponent } from "./monitor/monitor.component";
import { HttpPublisherService } from "./services/http/publisher.service";
import { ProductsComponent } from "./products/products.component";
import { TargetAudiencesComponent } from "./target_audiences/target_audiences.component";
import { HttpMonitorService } from "./services/http/monitor.service";
import { SwitchComponent } from "./form/generic-form/field/switch.component";
import { EditionsComponent } from "./editions/editions.component";
import { HttpElasticsearchService } from "./services/http/elasticsearch.service";
import { AdSpendingsCaptureSearchGridComponent } from "./ad-spendings/capture/search-grid.component";
import { AdSpendingsPullOutQuestionComponent } from './ad-spendings/pull-out-question/pull-out-question.component';
import { AdminComponent } from "./admin/admin.component";
import { HttpApilogService } from "./services/http/apilog.service";
import { HttpUserService } from "./services/http/user.service";
import { DisableControlDirective } from "./directives/form-control-disabled.directive";
import { WefraRatecardsComponent } from "./wefra_ratecards/wefra_ratecards.component";
import { WefraPubdatesComponent } from "./wefra_pubdates/wefra_pubdates.component";
import { HttpWefraRatecardService } from "./services/http/wefra_ratecard.service";
import { HttpWefraPubdateService } from "./services/http/wefra_pubdate.service";
import { HttpImportedCirculationService } from "./services/http/imported_circulation.service";
import { ClickNoBubble } from "./directives/click-no-bubble.directive";
import { RecordValueSelectComponent } from "./form/generic-form/field/record-value-select.component";
import { CrudGridReplaceComponent } from "./crud-grid/replace.component";
import { DateValueAccessor } from "./directives/date-value-accessor";
import { MyDateStringPipe } from "./pipes/dateStringDay.pipe";
import { MonitorService } from "./services/monitor.service";
import { GenericAdSpendingsComponent } from "./ad-spendings/generic.component";
import { PdfPaginationComponent } from "./ad-spendings/pdf-pagination/pdf-pagination.component";
import { PdfPaginationService } from "./ad-spendings/pdf-pagination/pdf-pagination.service";
import { HttpPubdateNoticeService } from "./services/http/pubdate_notice.service";
import { MonitorNoticeComponent } from "./monitor/notice.component";
import { CrudGridHistoricizeComponent } from "./crud-grid/historicize.component";
import { CrudGridHistoricizePreviewComponent } from "./crud-grid/historicize/preview.component";
import { MiniGridComponent } from './mini-grid/mini-grid.component';
import { MonitorAliasComponent } from "./monitor/alias.component";
import { HttpPubdateAliasService } from "./services/http/pubdate_alias.service";
import { HttpPulloutService } from "./services/http/pullout.service";
import { AdSpendingsCapturePulloutsComponent } from "./ad-spendings/capture/pullouts/pullouts.component";
import { EditGridComponent } from "./edit-grid/edit-grid.component";
import { EditGridFieldRendererComponent } from "./edit-grid/field-renderer";
import { MyDateTimePipe } from "./pipes/datetime.pipe";
import { DateTimeFieldComponent } from "./form/generic-form/field/datetime.component";
import { CrudGridJsonFieldRendererComponent } from "./crud-grid/field-renderer-json";
import { JsonRendererComponent } from "./ad-spendings/json-renderer/json-renderer.component";
import { ApilogsComponent } from "./admin/apilogs/apilogs.component";
import { ApilogsActivityFilterComponent } from "./admin/apilogs/apilogs-activity-filter.component";
import { MagazinesFormComponent } from "./magazines/form/form.component";
import { HttpTargetAudiencePeriodService } from "./services/http/target_audience_period.service";
import { HttpTargetAudiencePeriodEntryService } from "./services/http/target_audience_period_entry.service";
import { TargetAudiencePeriodFormComponent } from "./target_audience_periods/form/form.component";
import { HtmlToTextPipe } from "./pipes/htmlToText.pipe";


@NgModule({ declarations: [
        AppComponent,
        NavigationComponent,
        LeftnavComponent,
        DashboardComponent,
        LoginComponent,
        FormFieldErrorComponent,
        ToastsContainer,
        CrudGridComponent,
        CompaniesComponent,
        MarketsComponent,
        GenericFormComponent,
        ControlsComponent,
        SubmarketsComponent,
        CrudGridFieldRendererComponent,
        ConstraintComponent,
        PaginationComponent,
        AdSpendingsComponent,
        UploadComponent,
        AdSpendingsGridComponent,
        WasmuthComponent,
        WasmuthTablesMediaComponent,
        WasmuthTablesMediaRatecardComponent,
        AbstractRecordsViewComponent,
        MotivesComponent,
        ProductsComponent,
        ProductCategoriesComponent,
        AdSpendingsCaptureComponent,
        MultiComponent,
        WheelDirective,
        DisableControlDirective,
        NgbdModalContent,
        AdSpendingsIsMultiQuestionComponent,
        CategoriesComponent,
        FormatsComponent,
        PricesComponent,
        ColorsComponent,
        AdvertisingFormsComponent,
        EditionShowComponent,
        EuroPricePipe,
        MyDatePipe,
        MyDateStringPipe,
        MyDateTimePipe,
        HtmlToTextPipe,
        CrudGridDeleteComponent,
        NgxModalDraggableDirective,
        AdSpendingsTableComponent,
        CompanyCategoryComponent,
        MagazinesComponent,
        MonitorComponent,
        PublishersComponent,
        TargetAudiencesComponent,
        SwitchComponent,
        EditionsComponent,
        AdSpendingsCaptureSearchGridComponent,
        AdSpendingsPullOutQuestionComponent,
        AdminComponent,
        ApilogsComponent,
        WefraRatecardsComponent,
        WefraPubdatesComponent,
        ClickNoBubble,
        RecordValueSelectComponent,
        CrudGridReplaceComponent,
        CrudGridHistoricizeComponent,
        DateValueAccessor,
        GenericAdSpendingsComponent,
        PdfPaginationComponent,
        MonitorNoticeComponent,
        CrudGridHistoricizePreviewComponent,
        MiniGridComponent,
        MonitorAliasComponent,
        AdSpendingsCapturePulloutsComponent,
        EditGridComponent,
        EditGridFieldRendererComponent,
        DateTimeFieldComponent,
        CrudGridJsonFieldRendererComponent,
        JsonRendererComponent,
        ApilogsActivityFilterComponent,
        MagazinesFormComponent,
        TargetAudiencePeriodFormComponent
    ],
    bootstrap: [AppComponent],
      imports: [
        BrowserModule,
        NgxBootstrapIconsModule.pick({
            check, boxArrowRight, pencil, xCircle, plusCircle, arrowClockwise, circle, arrowDown, arrowUp, arrowDownUp,
            chevronDoubleLeft, chevronLeft, chevronDoubleRight, chevronRight, eye, bookmarkCheck, cardList, filetypeJson,
            fileEarmarkArrowDown, x, xLg, clipboard2Plus, listCheck, files, clipboard2, clipboard2Check, cupHot, coin,
            arrowLeftSquare, arrowRightSquare, toggleOff, toggleOn, filetypeXlsx, boxes, repeat, xOctagon, checkSquare,
            chatRightText, chatRightTextFill, calendar2Date, collection, checkCircle
        }),
        NgbModule,
        AppRoutingModule,
        ReactiveFormsModule,
        PinchZoomComponent
      ],
      providers: [
        ToastService,
        LocalStorageService,
        HttpInterceptorProviders,
        HttpAuthService,
        AuthService,
        HttpMarketService,
        HttpTargetAudienceService,
        HttpSubmarketService,
        HttpServiceFactory,
        HttpProductService,
        HttpProductCategoryService,
        HttpGroupService,
        HttpCategoryService,
        HttpCompanyService,
        HttpCompanyCategoryService,
        HttpAdSpendingsService,
        HttpWasmuthMediaService,
        HttpWasmuthRatecardService,
        HttpWasmuthMediaRatecardService,
        HttpWasmuthRatecardNumberService,
        HttpMotiveService,
        HttpEditionService,
        HttpWasmuthPublisherService,
        HttpWasmuthPublicationDateService,
        HttpAdSpendingsService,
        HttpMediaTypeService,
        HttpMagazineService,
        HttpFormatService,
        HttpPriceService,
        HttpAdvertisingFormService,
        HttpColorService,
        HttpPriceTypeService,
        HttpWasmuthAdService,
        HttpWasmuthFktPlacementService,
        HttpWasmuthFktFormService,
        HttpWasmuthFktColourService,
        HttpWasmuthAdSizeService,
        HttpExportService,
        HttpWasmuthFktCostTypeService,
        HttpWasmuthFktPageFormatCodeService,
        HttpWasmuthAdFormatService,
        HttpWasmuthAdCostService,
        HttpWasmuthFktAppearingWeeklyService,
        HttpWasmuthFktCostTypeService,
        HttpWasmuthFktFromToService,
        HttpWasmuthFktPriceTypeService,
        HttpWasmuthFktUnitService,
        HttpWasmuthFktAdspecialTypeService,
        HttpWasmuthFktAdspecialCostTypeService,
        HttpWasmuthAdspecialService,
        HttpWasmuthAdspecialCostService,
        StateService,
        HttpWasmuthMediaEditionService,
        HttpPublisherService,
        HttpMonitorService,
        HttpElasticsearchService,
        HttpApilogService,
        HttpUserService,
        HttpWefraRatecardService,
        HttpWefraPubdateService,
        HttpImportedCirculationService,
        MonitorService,
        PdfPaginationService,
        HttpPubdateNoticeService,
        HttpPubdateAliasService,
        HttpPulloutService,
        HttpTargetAudiencePeriodService,
        HttpTargetAudiencePeriodEntryService,
        provideHttpClient(withInterceptorsFromDi()),
        provideClientHydration(withIncrementalHydration())
      ]
    }
)
export class AppModule { }
