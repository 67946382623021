import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { AdSpendingRecordDataInterface, AdSpendingRecordInterface } from "../../models/ad_spending";
import { ServiceSlugProperty } from "../../models/main";
import { Observable } from "rxjs";

@Injectable()
export class HttpAdSpendingsService extends HttpAbstractRecordService<AdSpendingRecordInterface, AdSpendingRecordDataInterface> {
  slug: ServiceSlugProperty = 'ad_spendings';

  findIsMultiSiblings(record: AdSpendingRecordDataInterface) {
    const url: string = this.getMainApiBaseUrl() + this.slug + '/findIsMultiSiblings'
    if (! record.id) {
      return this.http.post<AdSpendingRecordInterface[]>(url, record)
    }
    let obs = new Observable<AdSpendingRecordInterface[]>(observer => {
      this.http.post<AdSpendingRecordInterface[]>(url, record).subscribe(records => {
        observer.next(records.filter((r) => { return record.id != r.data.id}))
      })
    })

    return obs
  }

  findPullOutSiblings(record: AdSpendingRecordDataInterface) {
    const url: string = this.getMainApiBaseUrl() + this.slug + '/findPullOutSiblings'
    if (! record.id) {
      return this.http.post<AdSpendingRecordInterface[]>(url, record)
    }
    let obs = new Observable<AdSpendingRecordInterface[]>(observer => {
      this.http.post<AdSpendingRecordInterface[]>(url, record).subscribe(records => {
        observer.next(records.filter((r) => { return record.id != r.data.id}))
      })
    })

    return obs
  }

  getMotiveProductMismatchRecords() {
    return this.http.get<AdSpendingRecordInterface[]>(this.getMainApiBaseUrl() + this.slug + '/motiveProductMismatchRecords')
  }

  getProductCompanyMismatchRecords() {
    return this.http.get<AdSpendingRecordInterface[]>(this.getMainApiBaseUrl() + this.slug + '/productCompanyMismatchRecords')
  }
}
