<div class="row">
  <div class="col-12">
    <h2>Monitor</h2>
  </div>
  <div class="col-12">
    <table>
      <thead>
        <tr>
          <th class="noborder filter-form" rowspan="3">
            <ng-container *ngIf="! loading; else isloading">
            <!--
            <i-bs (click)="prevMonth()" class="pointer" height="2em" width="2em" name="arrow-left-square"></i-bs>
            <span class="dis">{{ from.toLocaleDateString('de-DE', {month: 'long', year: 'numeric'}) }}</span>
            <i-bs (click)="nextMonth()" class="pointer" height="2em" width="2em" name="arrow-right-square"></i-bs>
            -->
              <form *ngIf="filterForm" novalidate [formGroup]="filterForm" (ngSubmit)="onSubmit(filterForm.value)">
                <div class="">
                  <div class="mb-2">
                    <label class="fixedwith">Zeitraum:</label>
                    <input type="date" formControlName="from" useValueAsDate class="from form-control-sm" />
                    <input type="date" formControlName="to" useValueAsDate class="to form-control-sm" />
                  </div>
                  <div class="mb-2">
                    <label class="fixedwith">Magazin:</label>
                    <input type="text" formControlName="magazine" class="form-control-sm magazine" />
                  </div>
<!--                </div>-->
<!--                <div class="w-40">-->
                  <div class="mb-2">
                    <label class="fixedwith">Verlag:</label>
                    <input type="text" formControlName="publisher" class="form-control-sm publisher" />
<!--                    <select formControlName="publisher" class="form-control-sm publisher">-->
<!--                      <option value=""></option>-->
<!--                      <option *ngFor="let publisher of publishers" [value]="publisher.id">{{ publisher.name }}</option>-->
<!--                    </select>-->
                  </div>
                </div>

                <div class="last d-flex">
                  <div ngbTooltip="Noch nicht veröffentlicht" class="sumtile future">{{ this.futureMags }}</div>
                  <div ngbTooltip="Vollständig" class="sumtile completed">{{ this.completedMags }}</div>
                  <div ngbTooltip="Teilweise erfasst" class="sumtile capturing">{{ this.partialMags }}</div>
                  <div ngbTooltip="Nicht erfasst" class="sumtile null">{{ this.scannedMags }}</div>
                  <span class="duplicates flex-grow-1"><span><input type="checkbox" [attr.checked]="withAliases" formControlName="withAliases" /><span>Ausgeblendete anzeigen</span></span></span>
                  <button type="submit" class="btn btn-sm btn-black float-end">Filter anwenden</button>
                </div>

<!--              Jahr: <select formControlName="year" (change)="onSetYearFilter($event)">-->
<!--                <option value="0"></option>-->
<!--                <option>2020</option>-->
<!--                <option>2021</option>-->
<!--                <option>2022</option>-->
<!--                <option>2023</option>-->
<!--                <option>2024</option>-->
<!--              </select>-->
              </form>

            </ng-container>

            <ng-template #isloading>
              <div class="d-flex justify-content-center h-100">
                <div class="spinner-border" role="status">
                </div>
              </div>
            </ng-template>

          </th>
          <ng-container *ngIf="! loading">
            <th class="noborder"></th>
            <th *ngFor="let month of months | keyvalue" [colSpan]="month.value">{{ renderMonth(month.key) }}</th>
          </ng-container>
        </tr>
        <tr *ngIf="! loading">
          <th class="noborder"></th>
          <th [colSpan]="weekspan[week]" *ngFor="let week of weeks">KW {{ week }}</th>
        </tr>
        <tr *ngIf="! loading">
          <th class="noborder"></th>
          <th *ngFor="let day of days" (mouseover)="hoverDate = day" [class.hoverday]="hoverDate && hoverDate === day">{{ day | dateStringDay }}</th>
        </tr>
      <tr>
        <th [colSpan]="days.length + 2" class="noborder"></th>
      </tr>
      </thead>
      <tbody (mouseleave)="hoverDate = 'nohover'" *ngIf="! loading">
        <ng-container *ngFor="let magazine of hydratedMagazines$()">
          <tr *ngIf="showMagazine(magazine)">
            <td class="name">
              <div class="d-flex">
                <span class="color {{ getCssStateClass(magazine)}}"></span>
                <span class="text">{{ magazine.name }} ({{ magazine.publisher_name }})</span>
                <i-bs container="body" [ngbTooltip]="magazine.notice" [name]="magazine.notice ? 'chat-right-text-fill' : 'chat-right-text'" (click)="onClickNotice(magazine)">Notiz</i-bs>
              </div>
            </td>
            <td class="noborder"></td>
            <td *ngFor="let day of days" (mouseover)="hoverDate = day" [class.hoverday]="hoverDate && day === hoverDate">
              <ng-container *ngIf="magazine.editions">
                <ng-container *ngFor="let editions of magazine.editions | keyvalue">
                  <ng-container *ngIf="editions.key === day">
                    <ng-container *ngFor="let edition of editions.value">

                      <div class="pointer entry"
                            ngbDropdown
                        [ngbTooltip]="magazine.name + ', ' + edition.edition_name + ', Nummer ' + edition.edition_number + ', ' + renderDateFromString(edition.publication_date) + ', Ratecard ' + edition.ratecard_id + ', Ratecard Number ' + edition.ratecard_number + ' (' + edition.oid + ')'"
                           container="body"
                        [class.null]="edition.capture_state === null || edition.capture_state === undefined"
                        [class.scanned]="edition.capture_state === 0"
                        [class.capturing]="edition.capture_state === 1"
                        [class.completed]="edition.capture_state === 2"
                        [class.future]="dateIsInFuture(edition)"
                      >
                        <div ngbDropdownToggle [id]="edition.oid">{{ renderEditionNumber(edition) }}</div>
                          <div ngbDropdownMenu [attr.aria-labelledby]="edition.oid">
                          <!-- items as buttons -->
                          <button ngbDropdownItem (click)="onClickEdition(edition)" [disabled]="! userIsSpaUser || !! (edition.edition_id && edition.capture_state)">Printmedium erfassen</button>
                          <button ngbDropdownItem (click)="onClickEdition(edition)" [disabled]="! userIsSpaUser || ! (edition.edition_id && edition.capture_state !== null && edition.capture_state >= 1)">Printmedium bearbeiten</button>
                          <button ngbDropdownItem (click)="onClickAdspending(edition)" [disabled]="! userIsSpaUser || ! (edition.edition_id && edition.capture_state !== null && edition.capture_state >= 1)">Adspendings erfassen</button>
                          <hr />
                          <button ngbDropdownItem *ngIf="! edition.alias_id" (click)="onClickAlias(edition)" [disabled]="! userIsSpaUser || !! (edition.edition_id && edition.capture_state)">Erscheinungsdatum ausblenden</button>
                          <button ngbDropdownItem *ngIf="edition.alias_id" (click)="onClickAlias(edition)" [disabled]="! userIsSpaUser || !! (edition.edition_id && edition.capture_state)">Erscheinungsdatum wieder einblenden</button>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
