import { Component } from '@angular/core';

@Component({
    selector: 'wefra-ad-spending-edition-show-grid',
    templateUrl: './show.component.html',
    standalone: false
})
export class EditionShowComponent {

  constructor(
  ) {
  }
}
