import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import { EditionModelConfiguration, EditionRecordDataInterface, EditionRecordInterface } from "../models/edition";
import { HttpEditionService } from "../services/http/edition.service";

@Component({
    selector: 'wefra-editions',
    templateUrl: '../abstract-records/abstract-record.component.html',
    standalone: false
})
export class EditionsComponent extends AbstractRecordComponent<EditionRecordInterface, EditionRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = EditionModelConfiguration
  api = inject(HttpEditionService)

  constructor(
  ) {
    super()
  }
}
