import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { ModelConfigFactory } from "./factory";

export interface ApilogRecordDataInterface extends RecordDataInterface {
  id: number
  created_at: string
  updated_at: string
  method: string
  uri: string
  files: string
  body: string
  headers: string
  user_id: number
}

export interface ApilogRecordInterface extends RecordInterface<ApilogRecordDataInterface> {

}

export const ApilogModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "apilogs",
  allowExportXlsx: false,
  title: "Apilogs",
  titleSingular: "Apilog",
  readonly: true,
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    { prop: 'activity', type: "virtual", title: 'Aktion',
      renderer: (record: RecordInterface<RecordDataInterface>) => {
        record = record as ApilogRecordInterface
        for (const call of ModelConfigFactory.getLoggedApiCalls()) {
          if (call.method == record.data["method"]) {
            if (call.regex.test(record.data["uri"])) {
              return call.title
            }
          }
        }

        return "<span>! Unknown action" + record.data["method"] + " " + record.data["uri"] + "</span>"
      },
      filterComponent: "wefra-apilogs-activity-filter-component",
      filterProperty: "activity",
    },
    { prop: 'body', type: "json", title: 'Payload' },
    { prop: 'headers', type: "json", title: 'Headers', list: true },
    { prop: 'uri', type: "text", title: 'Pfad', isNameProperty: true },
    { prop: 'user_id', type: "constraint", title: 'Benutzer', constraint: { table: 'users' }},
    { prop: 'method', type: "text", title: 'Methode' },
    { prop: 'files', type: "text", title: 'Dateien', list: false },
    { prop: "created_at", type: "datetime", title: 'Zeitpunkt', list: true },
  ]
}

