<ng-container *ngIf="editionRecord && (! recordCompleted)">
  <h2>Werbeträger erfassen</h2>
  <div class="row">
    <div class="col-{{ leftColumnCount }}">
      <wefra-grid-pagination
        [totalRecordsCount]="editionRecord.data.pagecount"
        [pageSize]="1"
        [currentPage]="currentPage"
        (setPage)="currentPage = $event"
      ></wefra-grid-pagination>

      <div class="image-container mb-3">
        <ng-container *ngFor="let pageIndex of pageArray">
          <pinch-zoom *ngIf="pageIndex == (currentPage - 1)" wheelCtrl (onSpin)="handleCtrlSpin($event)" [disableZoomControl]="'disable'">
            <img [src]="'/files/editions/' + editionRecord.data.file_reference + '/' + (currentPage - 1) + '/large.webp'">
          </pinch-zoom>
          <ng-container *ngIf="pageIndex == (currentPage - 1) && currentPage < editionRecord.data.pagecount">
            <hr />
            <pinch-zoom wheelCtrl (onSpin)="handleCtrlSpin($event)" [disableZoomControl]="'disable'">
              <img [src]="'/files/editions/' + editionRecord.data.file_reference + '/' + (currentPage) + '/large.webp'">
            </pinch-zoom>
          </ng-container>
        </ng-container>
      </div>

      <wefra-grid-pagination
        [totalRecordsCount]="editionRecord.data.pagecount"
        [pageSize]="1"
        [currentPage]="currentPage"
        (setPage)="currentPage = $event"
      ></wefra-grid-pagination>

    </div>

    <div class="col-{{ rightColumnCount }}">
      <div class="row">
        <h3 class="mt-5">Ratecard & Ausgabe zuordnen</h3>
        <div class="col-6">

          <label class="d-block">Wasmuth Ratecard</label>
          <wefra-multi-form-field [record]="editionRecord"
            [foreignRecord]="mediaRateCard"
            [selectedRecordTemplate]="wrRecordTemplate"
            [fieldConfig]="{title: 'Wasmuth Ratecard', prop: 'egal', type: 'constraint', constraint: { table: 'wasmuth_media_ratecards'}}"
            (selectRecord)="onSelectMediaRateCard($event)"
          ></wefra-multi-form-field>
          <div class="form-field-error" *ngIf="touched && ! isWasmuthRatecardValid()">
            <div>Es muss eine Ratecard gewählt werden!</div>
          </div>

          <ng-template #wrRecordTemplate>
            <dl *ngIf="mediaRateCard">
              <ng-container *ngIf="mediaRateCard">
                <dt>Ausgabe</dt>
                <dd>{{ mediaRateCard.data.name_long }} {{ mediaRateCard.data.ratecard_number }}</dd>
              </ng-container>
              <ng-container *ngIf="publisher">
                <dt>Herausgeber</dt>
                <dd>{{ publisher.data.publisher_name }}</dd>
              </ng-container>
              <ng-container *ngIf="mediaEdition && mediaEdition.data && mediaEdition.data.edition">
                <dt>Auflage</dt>
                <dd>{{ mediaEdition.data.edition }}</dd>
              </ng-container>
            </dl>
          </ng-template>
        </div>

<!--        <div class="col-6">-->
<!--          <label>Wefra Ratecard</label>-->
<!--          <wefra-constraint-form-field [record]="editionRecord"-->
<!--             [fieldConfig]="ModelConfigFactory.getFieldConfig('editions', 'wefra_ratecard_id')"-->
<!--             [gridShowAddRecordButton]="true"-->
<!--             (selectRecord)="onSelectWefraRateCard($event)"-->
<!--             (clear)="onRemoveWefraRateCard()"-->
<!--          ></wefra-constraint-form-field>-->
<!--          <div class="form-field-error" *ngIf="touched && ! isWefraRatecardValid()">-->
<!--            <div>Es muss mindestens eine Wasmuth- oder Wefra-Ratecard gewählt werden!</div>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->

<!--      <div class="row">-->
        <div class="col-6">
          <label class="d-block">Wasmuth Publication Date</label>

          <wefra-multi-form-field [record]="editionRecord"
            [foreignRecord]="publicationDateRecord"
            [selectedRecordTemplate]="wpdRecordTemplate"
            [fieldConfig]="{title: 'Wasmuth Publication Date', prop: 'egal', type: 'constraint', constraint: { table: 'wasmuth_publication_dates'}}"
            (selectRecord)="onSelectPublicationDate($event)"
            [fixedFilters]="getFixedFilters('wasmuth_publication_date')"
          ></wefra-multi-form-field>
          <div class="form-field-error" *ngIf="touched && ! isWasmuthPdValid()">
            <div>Wenn eine Wasmuth-Ratecard gewählt wurde, muss auch ein Wasmuth Publication-Date gewählt werden!</div>
          </div>
          <ng-template #wpdRecordTemplate>
            <ng-container *ngIf="publicationDateRecord?.data">
              <dl>
                <dt>Erscheinungstermin</dt><dd>{{ publicationDateRecord?.data?.publication_date }}</dd>
                <dt>Heft</dt><dd>{{ publicationDateRecord?.data?.edition_number }}</dd>
                <dt>Nummer</dt><dd>{{ publicationDateRecord?.data?.edition_name }}</dd>
              </dl>
            </ng-container>
          </ng-template>

        </div>
<!--        <div class="col-6">-->
<!--          <label>Wefra Erscheinungsdatum</label>-->
<!--          <wefra-constraint-form-field [record]="editionRecord"-->
<!--           [fieldConfig]="ModelConfigFactory.getFieldConfig('editions', 'wefra_pubdate_id')"-->
<!--           [gridShowAddRecordButton]="false"-->
<!--           [value]="editionRecord.data.wefra_pubdate_id"-->
<!--           (clear)="onRemoveWefraPubdate()"-->
<!--          ></wefra-constraint-form-field>-->
<!--          <div class="form-field-error" *ngIf="touched && ! isWefraPdValid()">-->
<!--            <div>Wenn eine Wefra-Ratecard gewählt wurde, muss auch ein Wefra Erscheinungsdatum gewählt werden!</div>-->
<!--          </div>-->
<!--        </div>-->
      </div>

      <div class="row">
        <div class="col-4">
          <label class="mt-5"><strong>Magazin</strong></label>
          <p>Bitte noch das Magazin aus den WEFRA Stammdaten zuweisen:</p>
          <wefra-constraint-form-field [record]="editionRecord" #magazineSelector
             [fieldConfig]="ModelConfigFactory.getFieldConfig('editions', 'magazine_id')"
             [gridShowAddRecordButton]="true"
             (selectRecord)="onSelectMagazine($event)"
          ></wefra-constraint-form-field>
          <div class="form-field-error" *ngIf="touched && ! isMagazineValid()">
            <div>Dieses Feld muss ausgefüllt werden!</div>
          </div>
        </div>
        <div class="col-4">
          <label class="mt-5"><strong>Auflage</strong></label>
          <p>Wurde die Auflage nicht gefunden, kann sie hier gewählt werden.</p>

          <wefra-record-value-select-form-field
             [value]="editionRecord.data.circulation_print"
             serviceSlug="imported_circulations"
             valueProperty="circulation_print"
             [fixedFilters]="getFixedFilters('imported_circulations')"
             (onChange)="onChangeCirculation($event)"
          ></wefra-record-value-select-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-4 d-flex flex-column">
          <label class="mt-5"><strong>Seitenanzahl voranstellen</strong></label>
          <p class="flex-grow-1">Hat das Magazin eine fortlaufende Seitennummerierung (über die Printmedien hinweg), muss hier der Wert der letzten Seite der vorherigen Ausgabe angegeben werden.</p>
          <input class="form-control" type="number" [value]="editionRecord.data.prepend_pages" (change)="onChangePrependPages($event)" />
        </div>

        <div class="col-4 d-flex flex-column">
          <label class="mt-5"><strong>PDF-Seiten voranstellen</strong></label>
          <p class="flex-grow-1">Fängt das PDF nicht mit der Seite 1 an, sondern es gibt eingescannte Anzeigen, die davor eingescannt worden sind, muss hier der Wert entsprechend angepasst werden.</p>
          <input class="form-control" type="number" [value]="editionRecord.data.prepend_adspages" (change)="onChangePrependPdfPages($event)" />
        </div>

        <div class="col-4 d-flex flex-column">
          <label class="mt-5"><strong>PDF-Seiten anhängen</strong></label>
          <p class="flex-grow-1">Sind dem PDF noch Seiten angehängt, denen keine Seitennummern zugewiesen sind, muss hier die Anzahl angegeben werden.</p>
          <input class="form-control" type="number" [value]="editionRecord.data.append_adspages" (change)="onChangeAppendPdfPages($event)" />
        </div>

      </div>

<!--      [fieldConfig]="circulationFieldConfig"-->
<!--      [gridShowAddRecordButton]="false"-->
<!--      (selectRecord)="onChangeCirculation($event)"-->
<!--      [valueProperty]="'circulation_print'"-->

      <div class="mt-5 row">
        <div class="col-6">
          <button (click)="submit()" *ngIf="recordWasNew" class="btn btn-green">Speichern</button>
          <button (click)="submit(true)" *ngIf="! recordWasNew" class="btn btn-green">Speichern</button>
          <button (click)="submitAndCapture()" class="ms-3 btn btn-green">Speichern und Anzeige erfassen</button>
        </div>
        <div class="col-6">
          <button  [routerLink]="['/ad-spendings/editions/']" class="btn btn-red float-end">Abbrechen</button>
        </div>
      </div>

    </div>
  </div>
</ng-container>

<ng-container *ngIf="editionRecord && recordCompleted">
  <p>Das Printmedium wurde erfolgreich angelegt.</p>
  <p><strong class="pointer" [routerLink]="['/ad-spendings/capture/' + editionRecord.data.id ]">Anzeigen erfassen</strong></p>
</ng-container>
