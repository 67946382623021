import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MagazineRecordDataInterface, MagazineRecordInterface } from "../../models/magazine";
import { KeyValueInterface, ModelConfigurationInterface, RecordDataInterface } from "../../models/main";
import { SaveGenericRecordEventInterface } from "../../form/generic-form/generic-form.component";
import {
  TargetAudiencePeriodModelConfiguration,
  TargetAudiencePeriodRecordDataInterface,
  TargetAudiencePeriodRecordInterface
} from "../../models/target_audience_period";
import { HttpTargetAudiencePeriodService } from "../../services/http/target_audience_period.service";
import { ModalService } from "../../services/modal.service";
import { TargetAudiencePeriodFormComponent } from "../../target_audience_periods/form/form.component";
import { MiniGridComponent } from "../../mini-grid/mini-grid.component";

@Component({
    selector: 'wefra-magazines-form-component',
    templateUrl: './form.component.html',
    standalone: false
})
export class MagazinesFormComponent implements OnInit {

  @Input() record?: MagazineRecordInterface
  @Input() modelConfig?: ModelConfigurationInterface

  @Output("saveRecord") updateRecordEvent = new EventEmitter<SaveGenericRecordEventInterface<MagazineRecordDataInterface>>()
  @Output("closeClick") closeClickEvent = new EventEmitter<boolean>()

  public targetAudiencePeriods?: TargetAudiencePeriodRecordInterface[]
  public targetAudiencePeriodModelConfig: ModelConfigurationInterface = TargetAudiencePeriodModelConfiguration
  public newRecordDefaults: KeyValueInterface = {}
  public miniGridFixedFilters: KeyValueInterface = {}

  @ViewChild('miniGridComponent') miniGridComponent?: MiniGridComponent<TargetAudiencePeriodRecordInterface, TargetAudiencePeriodRecordDataInterface>

  constructor(
    @Inject(HttpTargetAudiencePeriodService) private targetAudiencePeriodService: HttpTargetAudiencePeriodService,
    @Inject(ModalService) private modalService: ModalService
  ) {
  }

  ngOnInit(): void {
    // if (this.record && this.record.beside && this.record.beside["target_audience_periods"]) {
    //   this.targetAudiencePeriods = this.record.beside["target_audience_periods"] as TargetAudiencePeriodRecordInterface[]
    // }
    if (this.record) {
      this.newRecordDefaults = { magazine_id: this.record.data.id }
      this.miniGridFixedFilters = { magazine_id: this.record.data.id}
    }
  }

  onCloseClick(data: boolean): void {
    this.closeClickEvent.emit(data)
  }

  onMagazineRecordSave(data: SaveGenericRecordEventInterface<RecordDataInterface>): void {
    this.updateRecordEvent.emit(data as SaveGenericRecordEventInterface<MagazineRecordDataInterface>)
  }

  onSelectTargetAudiencePeriod(period: TargetAudiencePeriodRecordInterface): void {
    let that = this
    if (period) {
      this.targetAudiencePeriodService.get(period.data.id).subscribe(period => {
        let modal = this.modalService.show(TargetAudiencePeriodFormComponent, {
          title: "Zielgruppen-Periode bearbeiten",
          inputs: {
            record: period,
            modelConfig: TargetAudiencePeriodModelConfiguration
          },
          modalOptions: {
            windowClass: 'advanced-edit',
          },
          closeBtnText: undefined,
          subscribers: {
            cancelEvent: function (cancel: boolean) {
              modal.close()
            },
            changeEvent: function (cancel: boolean) {
              //that.loadAdSpendings()
            },
            updateRecordEvent(o: SaveGenericRecordEventInterface<TargetAudiencePeriodRecordDataInterface>) {
              if (that.miniGridComponent) {
                that.miniGridComponent.load()
              }
            }
          }
        })
      }, error => {
        //that.toastService.showError("Die Beihefter konnten nicht geladen werden!")
      })
    }
  }
}
