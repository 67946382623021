import {
  ApilogsActionInterface,
  ModelConfigurationFieldInterface,
  ModelConfigurationInterface,
  RecordDataInterface,
  RecordInterface,
  ServiceSlugProperty
} from "./main";
import { escapeRegExp } from "../helpers/main";

export interface ModelconfigKeyIndex {
  [identifier: string]: ModelConfigurationInterface
}

export class ModelConfigFactory {

  public static configs: ModelconfigKeyIndex = {}
  public static loggedApiCalls: ApilogsActionInterface[] = [
    { title: 'Benutzer anmelden', slug: '/api/auth/login', method: 'POST', 'regex': new RegExp('^' + escapeRegExp('/api/auth/login') + '$')},
    { title: 'Benutzer abmelden', slug: '/api/auth/logout', method: 'POST', 'regex': new RegExp('^' + escapeRegExp('/api/auth/logout') + '$')}
  ]

  public static register(key: ServiceSlugProperty, config: ModelConfigurationInterface){
    ModelConfigFactory.configs[key] = config
    const slug = "/api/" + key
    if (! config.readonly) {
      ModelConfigFactory.loggedApiCalls.push({ title: config.titleSingular + ' erstellen', slug: slug, method: 'POST', 'regex': new RegExp('^' + escapeRegExp(slug) + '$')})
      ModelConfigFactory.loggedApiCalls.push({ title: config.titleSingular + ' aktualisieren', slug: slug, method: 'PUT', 'regex': new RegExp('^' + escapeRegExp(slug + "/") + '\\d+$') })
      ModelConfigFactory.loggedApiCalls.push({ title: config.titleSingular + ' löschen', slug: slug, method: 'DELETE', 'regex': new RegExp('^' + escapeRegExp(slug + "/") + '\\d+$') })
    }
    if (config.allowHistoricize) {
      ModelConfigFactory.loggedApiCalls.push({ title: config.titleSingular + ' historisieren', slug: slug + "/historicize/", method: 'POST', 'regex': new RegExp('^' + escapeRegExp(slug + "/historicize/") + '$') })
      ModelConfigFactory.loggedApiCalls.push({ title: config.titleSingular + ' historisieren (Vorschau)', slug: slug + "/historicizePreview/", method: 'POST', 'regex': new RegExp('^' + escapeRegExp(slug + "/historicizePreview/") + '$') })
    }
    if (config.allowReplace) {
      ModelConfigFactory.loggedApiCalls.push({ title: config.titleSingular + ' zusammenlegen', slug: slug + "/historicize/", method: 'POST', 'regex': new RegExp('^' + escapeRegExp(slug + "/historicize/") + '$') })
    }
  }

  public static getAllRegisteredModelConfigs() {
    return ModelConfigFactory.configs
  }

  public static getLoggedApiCalls(): ApilogsActionInterface[] {
    return ModelConfigFactory.loggedApiCalls.sort((a, b) => {
      if (a.title < b.title) return -1
      if (a.title > b.title) return 1
      return 0
    })
  }

  public static getConfig(key: ServiceSlugProperty): ModelConfigurationInterface {
    if (key == 'not_defined') {
      throw new Error("The service-slug has not been defined in your component!")
    }
    if (! ModelConfigFactory.configs[key] || ModelConfigFactory.configs[key] === undefined) {
      throw new Error("Modelconfig by key " + key + " not found")
    }

    return ModelConfigFactory.configs[key]
  }

  public static getFieldConfig(model: ServiceSlugProperty, prop: string): ModelConfigurationFieldInterface | undefined {
    const mc = this.getConfig(model)

    if (! mc) return undefined
    for (const field of mc.fields) {
      if (field.prop == prop) return field
    }

    return undefined
  }

  public static getMultiSelectRenderFieldProperties(model: ServiceSlugProperty) {
    const mc = this.getConfig(model)
    let fields: string[] = []
    if (! mc) return fields
    for (const field of mc.fields) {
      if (field.renderInMultiselect) fields.push(field.prop)
    }

    if (fields.length == 0) {
      for (const field of mc.fields) {
        if (field.isIdProperty) fields.push(field.prop)
        if (field.isNameProperty) fields.push(field.prop)
      }
    }

    return fields
  }

  public static getIdFieldProperty(model?: ServiceSlugProperty): string {
    if (! model) return "id"
    const mc = this.getConfig(model)
    if (! mc) return "id"
    for (const field of mc.fields) {
      if (field.isIdProperty) return field.prop
    }

    return "id"
  }

  public static getNameFieldProperty(model?: ServiceSlugProperty): string {
    if (! model) return "name"
    const mc = this.getConfig(model)
    if (! mc) return "name"
    for (const field of mc.fields) {
      if (field.isNameProperty) return field.prop
    }

    return "name"
  }

  public static getSearchProperty(field: ModelConfigurationFieldInterface) {
    let prop = ''
    if (field.filterProperty) {
      prop = field.filterProperty
    } else if (field.type == "constraint") {
      prop = ModelConfigFactory.snakeToCamel(field.prop) + '.' + ModelConfigFactory.getNameFieldProperty(field.constraint?.table)
    } else {
      prop = field.prop
    }

    return prop
  }

  public static snakeToCamel(str: string) {
    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    )
  }
}
