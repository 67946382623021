<ng-container *ngIf="showPagination">
  <div class="row">
    <div class="col-4">
      <wefra-grid-pagination
        (setPage)="this.setCurrentPage($event)"
        [totalRecordsCount]="totalRecordsCount"
        [currentPage]="currentPage"
        [pageSize]="currentPageSize"
      ></wefra-grid-pagination>
    </div>

    <div class="col-4">
      <div class="pagination-wrapper">
        <div class="total">{{ totalRecordsCount }} Datensätze</div>
      </div>
    </div>

    <div class="col-4">
    <nav class="pager" aria-label="Pagesize">
      <ul class="pagination pagination-sm justify-content-end">
        <li *ngFor="let size of pageSizes"
            [class.active]="currentPageSize == size"
        >
          <span class="pointer page-link" (click)="setPageSize(size)">{{ (size > 10000) ? '∞' : size }}</span>
        </li>
      </ul>
    </nav>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="records == undefined; else loaded">
  <div class="heading" *ngIf="heading">
    {{ heading }}
  </div>
  <div class="load-mask">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
        </div>
      </div>
  </div>
</ng-container>
<ng-template #loaded>
  <div class="top-container"
       [class.with-controls]="controls && controls.length"
       [class.with-heading]="heading"
  >
    <div class="heading" *ngIf="heading">
      {{ heading }}
    </div>
    <div class="controls" *ngIf="controls && controls.length">
      <div class="control" *ngFor="let control of controls">
        <i-bs [ngbTooltip]="control.label" [name]="control.icon" class="pointer"
              width="1.5rem" height="1.5rem" (click)="control.method.call(control.scope)" />
      </div>
    </div>
  </div>
  <table class="table table-hover" [class]="modelConfig?.serviceSlug">
    <thead>
    <tr>
      <ng-container *ngFor="let field of getFields()">
        <th scope="col" [class]="field.prop" [class.name-property]="field.isNameProperty" [class.id-property]="field.isIdProperty" >
            {{ field.title }}
        </th>
      </ng-container>
      <td *ngIf="actions && actions.length"></td>
    </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="records?.length; else emptylist">

        <tr *ngFor="let record of records">
          <ng-container *ngFor="let field of getFields()">
            <td class="{{ field.prop }} pointer" (click)="onSelect(record)">
              <wefra-crud-grid-field-renderer [context]="'mini-grid'" [record]="record" [fieldConfig]="field"></wefra-crud-grid-field-renderer>
            </td>
          </ng-container>
          <td class="actions" *ngIf="actions && actions.length">
            <ng-container *ngFor="let action of actions">
                <span class="{{ action.identifier }} pointer" (click)="action.method.call(action.scope, record)">
                    <i-bs [name]="action.icon" width="1.2em" height="1.2em" ngbTooltip="{{ action.label }}"></i-bs>
                  </span>
            </ng-container>
          </td>
        </tr>

      </ng-container>
      <ng-template #emptylist>
        <tr class="empty"><td [attr.colspan]="getFields().length">Es konnten keine {{ modelConfig?.title }} gefunden werden!</td></tr>
      </ng-template>
    </tbody>
  </table>
</ng-template>
