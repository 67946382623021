import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import {
  SubmarketModelConfiguration,
  SubmarketRecordDataInterface,
  SubmarketRecordInterface
} from "../models/submarket";
import { HttpSubmarketService } from "../services/http/submarket.service";

@Component({
    selector: 'wefra-submarkets',
    templateUrl: '../abstract-records/abstract-record.component.html',
    standalone: false
})
export class SubmarketsComponent extends AbstractRecordComponent<SubmarketRecordInterface, SubmarketRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = SubmarketModelConfiguration
  api = inject(HttpSubmarketService)

  constructor(
  ) {
    super()
  }
}
