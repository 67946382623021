import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { HttpElasticsearchService } from "../../services/http/elasticsearch.service";
import {
  ElasticsearchListResponseInterface,
  ESModelConfigurationFieldInterface,
  ESQueryFilterInterface,
  ESQueryMatchInterface,
  ESQueryTermInterface,
  ESQueryWildcardInterface,
  FiltersInterface,
  ModelConfigurationFieldInterface,
  PriceQueryDataInterface,
  SortDirections,
  SortingInterface
} from "../../models/main";

@Component({
    selector: 'wefra-ad-spendings-capture-grid',
    templateUrl: './search-grid.component.html',
    standalone: false
})
export class AdSpendingsCaptureSearchGridComponent implements OnInit {
  response?: ElasticsearchListResponseInterface<PriceQueryDataInterface>
  sortRegistry: SortingInterface[] = [
    {
      dir: "asc",
      field: "placement"
    }
  ]
  filterRegistry: ESQueryFilterInterface = {}
  swordRegistry: FiltersInterface = {}

  // form: FormGroup
  fields: ESModelConfigurationFieldInterface[] = [
    { prop: 'placement', type: 'text', title: 'Platzierung/Werbemittel/Format/Umfang' },
    { prop: 'format', type: 'text', title: 'Format', queryType: 'term' },
    { prop: 'cost', type: 'text', title: 'Preis' },
    { prop: 'type', type: 'enum', title: 'Typ', enums: [
      { label: "Wasmuth", value: "wa" },
        { label: "Adspecial", value: "as" },
        { label: "Wefra", value: "we" }]
    },
    { prop: 'cost_type_name', type: 'text', title: 'Kostentyp' },
    { prop: 'colour_name', type: 'text', title: 'Farbigkeit' },
  ]
  @Input() ratecardId?: number = 0
  @Input() ratecardNumber?: string = "0"

  @Output("selectPrice") selectPriceEvent = new EventEmitter<PriceQueryDataInterface>()


  constructor(
    @Inject(HttpElasticsearchService) protected searchService: HttpElasticsearchService,
  ) {
    this.filterRegistry["colour_name"] = this.getFilterForQuery('colour_name', 'vierfarbig', 'match')
    this.swordRegistry["colour_name"] = 'vierfarbig'
  }

  ngOnInit(): void {
    this.load()
  }

  getSort(fieldname: string) {
    let found = this.sortRegistry.find((item) => { return (item.field == fieldname) })
    if (found) return found.dir
    return undefined
  }

  public setSort(fieldname: string, direction: SortDirections) {
    let found = this.sortRegistry.find((item) => { return (item.field == fieldname) })
    if (found) {
      if (direction) {
        found.dir = direction
      } else {
        this.sortRegistry = this.sortRegistry.filter((item) => { return (item.field != fieldname) })
      }
    } else {
      if (direction) {
        this.sortRegistry.push({field: fieldname, dir: direction})
      }
    }

    this.load()
  }

  onSelectorSearch(field: ESModelConfigurationFieldInterface, e: Event) {
    let value: string | number | undefined = (e.target as HTMLSelectElement).value
    if (value == "undefined") value = undefined
    this.onQuickSearch(field, value)
  }

  onQuickSearch(field: ESModelConfigurationFieldInterface, value?: string | number) {
    if (value === undefined) {
      if (this.swordRegistry[field.prop]) {
        delete this.swordRegistry[field.prop]
        delete this.filterRegistry[field.prop]
        this.load()
      }
    } else {
      const type = field.queryType ?? 'wildcard'
      this.swordRegistry[field.prop] = value
      this.filterRegistry[field.prop] = this.getFilterForQuery(field.prop, value, type)
      this.load()
    }
  }

  protected getFilterForQuery(field: string, value: string | number, type: string = 'wildcard'):
    ESQueryWildcardInterface | ESQueryMatchInterface | ESQueryTermInterface {
    // @ts-ignore
    let filter: ESQueryWildcardInterface | ESQueryMatchInterface | ESQueryTermInterface = {}

    // @ts-ignore
    filter[type] = {}
    if (type == 'wildcard') {
      // @ts-ignore
      filter[type][field] = { value: value + '*' }
    } else if (type == 'match') {
      // @ts-ignore
      filter[type][field] = { query: value, "analyzer": "keyword" }
    } else {
      // @ts-ignore
      filter[type][field] = { value: value }
    }

    return filter
  }

  onQuicksearchKeyUp(event: KeyboardEvent, field: ModelConfigurationFieldInterface) {
    if (event.key == "Enter") {
      this.onQuicksearchHandleInput(field, event.target as HTMLInputElement)
    }
  }

  onQuicksearchFocusOut(event: FocusEvent, field: ModelConfigurationFieldInterface) {
    this.onQuicksearchHandleInput(field, event.target as HTMLInputElement)
  }

  onQuicksearchHandleInput(field: ModelConfigurationFieldInterface, input: HTMLInputElement) {
    if (input.value.length == 0) {
      this.onQuickSearch(field, undefined)
    } else {
      this.onQuickSearch(field, input.value)
    }
  }

  onClick(data: PriceQueryDataInterface, i: number) {
    let domEl = document.getElementsByClassName('row-' + i)[0] as HTMLTableRowElement
    domEl.classList.add('flash')
    setTimeout(()=> {domEl.classList.remove('flash')}, 500)

    this.selectPriceEvent.emit(data)
  }

  load() {
    if (! this.ratecardId) return
    if (! this.ratecardNumber) return
    this.response = undefined
    this.searchService.search(
      this.ratecardId,
      this.ratecardNumber,
      Object.values(this.filterRegistry),
      this.sortRegistry,
    ).subscribe(response => {
      this.response = response
    })
  }
}
