import {
  FiltersInterface,
  GridActionInterface,
  GridControlInterface,
  KeyValueInterface,
  ModelConfigurationFieldInterface,
  ModelConfigurationInterface,
  RecordDataInterface,
  RecordInterface
} from "../models/main";
import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { HttpAbstractRecordService } from "../services/http/record.service.abstract";
import { HttpServiceFactory } from "../services/http/factory";
import { ToastService } from "../services/toast.service";
import { ModalService } from "../services/modal.service";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { LocalStorageService } from "../services/localstorage.service";

@Component({
    selector: 'wefra-abstract-grid',
    template: `
    <p>
      abstract grid
    </p>
  `,
    standalone: false
})
export abstract class AbstractGridComponent<T extends RecordInterface<K>, K extends RecordDataInterface> implements OnInit {

  @Input() modelConfig?: ModelConfigurationInterface
  @Input() forceFields: string[] = []
  @Input() fixedFilters?: FiltersInterface
  @Input() limitToFields?: string[]
  @Input() records?: T[]
  @Input() api?: HttpAbstractRecordService<T, K>
  @Input() actions?: GridActionInterface[]
  @Input() controls?: GridControlInterface[]
  @Input() heading?: string
  @Input() newRecordDefaults?: KeyValueInterface
  @Input() hideFieldsInEditor?: string[]
  @Input() additionalQueryParams?: KeyValueInterface
  @Input() currentPageSize: number = 20

  /**
   * if this property is set, the filters, pagination and sort will be
   * saved in localstorage and loaded on next call
   *
   * @private
   */
  @Input() public persistentStateKey?: string

  @Output("selectRecord") selectRecordEvent = new EventEmitter<T>()
  @Output("deleteRecord") deleteRecordEvent = new EventEmitter<T>()

  isLoading: boolean = true
  idProperty: string = "id"
  openedModal?: NgbModalRef

  totalRecordsCount: number = 0
  currentPage: number = 1
  lastPage: number = 1
  pageSizes: number[] = [20, 50, 100, 250, 500, 1000, 99999]
  columnCount: number = 0

  public authService: AuthService
  public serviceFactory: HttpServiceFactory
  public toastService: ToastService
  public modalService: ModalService
  public localStorageService: LocalStorageService

  constructor(
  ) {
    this.authService = inject(AuthService)
    this.serviceFactory = inject(HttpServiceFactory)
    this.toastService = inject(ToastService)
    this.modalService = inject(ModalService)
    this.localStorageService = inject(LocalStorageService)
  }

  public abstract load(): void

  ngOnInit(): void {
    if (this.modelConfig && ! this.api) {
      this.api = this.serviceFactory.getTypeInstance(this.modelConfig.serviceSlug) as HttpAbstractRecordService<T, K>
    }
  }

  getFields(): ModelConfigurationFieldInterface[] {
    if (! this.modelConfig?.fields) return []
    let fields: ModelConfigurationFieldInterface[] = []

    for (let i = 0; i < this.modelConfig?.fields.length; i++) {
      if (! this.modelConfig.fields[i]) continue

      if (this.modelConfig.fields[i]!.ability) {
        if (! this.authService.hasAbility(this.modelConfig.fields[i]!.ability + '')) {
          continue
        }
      }

      // do not show fields with fixed filter if not forced
      if (this.fixedFilters &&
        (this.fixedFilters[this.modelConfig!.fields[i]!.prop]) &&
        (! this.forceFields.includes(this.modelConfig!.fields[i]!.prop))
      ) {
        continue
      }

      // always show field if it is in limitToFields
      if (this.limitToFields) {
        if (this.limitToFields.includes(this.modelConfig.fields[i]!.prop)) {
          fields.push(this.modelConfig.fields[i]!)
        }
      } else {
        if (this.modelConfig.fields[i]?.list !== false) fields.push(this.modelConfig.fields[i]!)
      }
    }

    return fields
  }

  viewRecordUpdate(replaceRecord: T) {
    this.records?.find((record, index) => {
      if (record.data[this.idProperty] == replaceRecord.data[this.idProperty]) {
        if (this.records) this.records[index] = replaceRecord
      }
    })
  }

  public onDelete(record: T) {
    if (this.modelConfig?.readonly) return
    this.deleteRecordEvent.emit(record)
  }

  setPageSize(size: number) {
    this.currentPageSize = size
    this.currentPage = 1
    this.load()
  }

  setCurrentPage(page: number) {
    this.currentPage = page
    this.load()
  }
}
