import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface AdSpendingRecordDataInterface extends RecordDataInterface {
  id?: number
  headline_id?: number
  magazine_id?: number
  year?: number
  month?: number
  day?: number
  week?: number
  issue?: number
  page?: string
  volume?: number
  price_value?: number
  color_id?: number
  approved?: number
  market_id?: number
  submarket_id?: number
  advertisingform_id?: number
  company_id?: number
  product_id?: number
  category_id?: number
  format_id?: number
  price_id?: number
  paid?: number
  is_multi?: number
  is_special?: number
  edition_id?: number
  use_wasmuth?: number
  wasmuth_ad_id?: string
  wasmuth_fkt_form_id?: number
  wasmuth_fkt_placement_id?: number
  wasmuth_fkt_color_id?: number
  wasmuth_fkt_page_format_code_id?: string
  wasmuth_ad_format_id?: string
  wasmuth_fkt_adspecial_type_id?: number
  wasmuth_ad_cost_id?: string
  wasmuth_fkt_cost_type_id?: number
  wasmuth_adspecial_cost_id?: string
  wasmuth_fkt_adspecial_cost_type_id?: number
  wasmuth_adspecial_id?: string
  mutual_price?: number
  pullout_id?: number
  placement?: string
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface AdSpendingRecordInterface extends RecordInterface<AdSpendingRecordDataInterface> {}

export const AdSpendingModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "ad_spendings",
  allowExportXlsx: true,
  title: "Adspendings",
  titleSingular: "Adspending",
  fields: [
    { prop: 'id', type: 'id', title: '#',  isIdProperty: true, edit: false },
    { prop: 'product_id', type: "constraint", title: 'Produkt', constraint: { table: 'products' }, validators: [ Validators.required ]},
    { prop: 'company_id', type: "constraint", title: 'Firma', constraint: { table: 'companies' }, validators: [ Validators.required ]},
    { prop: 'headline_id', type: "constraint", title: 'Motiv', constraint: { table: 'motives' }, validators: [ Validators.required ]},

    { prop: 'magazine_id', type: "constraint", title: 'Magazin', constraint: { table: 'magazines' }, validators: [ Validators.required ]},
    { prop: 'year', type: "int", title: "Jahr", validators: [ Validators.required ]},
    { prop: 'month', type: "int", title: "Monat", validators: [ Validators.required ]},
    { prop: 'day', type: "int", title: "Tag", validators: [ Validators.required ]},
    { prop: 'week', type: "int", title: "KW", validators: [ Validators.required ]},
    { prop: 'edition_id', type: "constraint", title: 'Heft', constraint: { table: 'editions' }, validators: [  ]},
    { prop: 'issue', type: "text", title: "Ausgabe", validators: [ Validators.required ]},
    { prop: 'placement', type: "text", title: "Platzierung (aus Index)", validators: [ Validators.required ]},
    { prop: 'page', type: "text", title: "Seite", validators: [ Validators.required ]},
    { prop: 'volume', type: "int", title: "volume", validators: [  ]},
    { prop: 'price_value', type: "int", title: "Preis", validators: [  ]},
    { prop: 'mutual_price', type: "int", title: "Preis gesamt", validators: [ ]},
    { prop: 'pullout_id', type: "int", title: "Beihefter in Printmedium" },
    { prop: 'color_id', type: "constraint", title: 'Farbigkeit', constraint: { table: 'colors' }, validators: [ ]},
    { prop: 'wasmuth_fkt_color_id', type: "constraint", title: 'Farbigkeit', constraint: { table: 'wasmuth_fkt_colours' }},
    { prop: 'approved', type: "bool", title: "approved", validators: [ ]},
    { prop: 'market_id', type: "constraint", title: 'Branche', constraint: { table: 'markets' }, validators: [ Validators.required ]},
    { prop: 'submarket_id', type: "constraint", title: 'Unterbranche', constraint: { table: 'submarkets' }},
    { prop: 'wasmuth_ad_id', type: "multi", title: 'Wasmuth Ad', constraint: { table: 'wasmuth_ads' }},
    { prop: 'advertisingform_id', type: "constraint", title: 'Werbeform', constraint: { table: 'advertising_forms' }, validators: [ ]},
    { prop: 'price_id', type: "constraint", title: 'Preis', constraint: { table: 'prices' }, validators: [ ]},
    { prop: 'paid', type: "bool", title: "Bezahlt", validators: [  ]},
    { prop: 'is_multi', type: "bool", title: "Multi", validators: [  ]},
    { prop: 'is_special', type: "bool", title: "Spezial", validators: [  ]},
    { prop: 'use_wasmuth', type: "bool", title: "Benutze Wasmuth Daten", validators: [  ]},
    { prop: 'wasmuth_fkt_form_id', type: "constraint", title: 'Wasmuth Fkt Form', constraint: { table: 'wasmuth_fkt_forms' }},
    { prop: 'wasmuth_fkt_placement_id', type: "constraint", title: 'Platzierung', constraint: { table: 'wasmuth_fkt_placements' }},
    { prop: 'category_id', type: "constraint", title: 'Kategorie', constraint: { table: 'categories' }, validators: [ ]},
    { prop: 'format_id', type: "constraint", title: 'Format', constraint: { table: 'formats' }, validators: [ ]},
    { prop: 'wasmuth_ad_size_id', type: "constraint", title: 'Größe', constraint: { table: 'wasmuth_ad_sizes' }},
    { prop: 'wasmuth_fkt_page_format_code_id', type: "constraint", title: 'Format', constraint: { table: 'wasmuth_fkt_page_format_codes' }},
    { prop: 'wasmuth_ad_format_id', type: "constraint", title: 'Anzeigenformat', constraint: { table: 'wasmuth_ad_formats' }},
    { prop: 'wasmuth_ad_cost_id', type: "constraint", title: 'Anzeigenpreise', constraint: { table: 'wasmuth_ad_costs' }},
    { prop: 'wasmuth_adspecial_id', type: "constraint", title: 'Adspecial', constraint: { table: 'wasmuth_adspecials' }},
    { prop: 'wasmuth_fkt_adspecial_type_id', type: "constraint", title: 'Adspecial Typ', constraint: { table: 'wasmuth_fkt_adspecial_types' }},
    { prop: 'wasmuth_adspecial_cost_id', type: "constraint", title: 'Adspecial Preis', constraint: { table: 'wasmuth_adspecial_costs' }},
    { prop: 'wasmuth_fkt_adspecial_cost_type_id', type: "constraint", title: 'Spezial Kostentyp', constraint: { table: 'wasmuth_fkt_adspecial_cost_types' }},

    { prop: "created_by", ability: "spa-userinfo", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-userinfo", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-userinfo", type: "date", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-userinfo", type: "date", title: 'Bearbeitet am', edit: false},
    //
    // { prop: 'date', type: 'virtual', title: 'Datum', renderer: (record) => {
    //     return record.data["year"] + '-' + ('0' + record.data["month"]).slice(-2) + '-' + ( '0' + record.data["day"]).slice(-2)
    //   }, disableSearch: true, disableSort: true },
  ]
}

