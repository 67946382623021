import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "dateStringDay",
    standalone: false
})

export class MyDateStringPipe implements PipeTransform {
  transform(value: string | undefined) {
    if (typeof value == "undefined") {
      return '0'
    }

    const parts = value.split("-")
    if (parts.length !== 3) return '0'

    return parseInt(parts[2]+'', 10)
  }
}
