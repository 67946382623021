import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface TargetAudiencePeriodEntryRecordDataInterface extends RecordDataInterface {
  id: number
  period_id: number
  targetaudience_id: number
  quota_in_percent: number
}

export interface TargetAudiencePeriodEntryRecordInterface extends RecordInterface<TargetAudiencePeriodEntryRecordDataInterface> {
}

export const TargetAudiencePeriodEntryModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "target_audience_period_entries",
  allowExportXlsx: false,
  title: "Zielgruppenperiodeneinträge",
  titleSingular: "Zielgruppenperiodeneintrag",
  fields: [
    { prop: 'id', type: 'id', title: '#', isIdProperty: true, edit: false },
    { prop: 'targetaudience_id', type: "constraint", title: 'Zielgruppe', constraint: { table: 'target_audiences' }, validators: [ Validators.required ]},
    { prop: 'quota_in_percent', type: "int", title: 'Anteil in Prozent', isNameProperty: true, validators: [ Validators.required ], defaultValue: 0 },
    { prop: 'period_id', type: "constraint", title: 'Zielgruppenperiode', constraint: { table: 'target_audience_periods' }, validators: [ Validators.required ]},
  ]
}
