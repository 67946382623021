import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import { CategoryModelConfiguration, CategoryRecordDataInterface, CategoryRecordInterface } from "../models/category";
import { HttpCategoryService } from "../services/http/category.service";

@Component({
    selector: 'wefra-categories',
    templateUrl: '../abstract-records/abstract-record.component.html',
    standalone: false
})
export class CategoriesComponent extends AbstractRecordComponent<CategoryRecordInterface, CategoryRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = CategoryModelConfiguration
  api = inject(HttpCategoryService)

  constructor(
  ) {
    super()
  }
}
