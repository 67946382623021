import { RecordDataInterface, RecordInterface, ServiceSlugProperty } from "../../models/main";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { HttpMarketService } from "./market.service";
import { HttpSubmarketService } from "./submarket.service";
import { Inject, Injectable } from "@angular/core";
import { HttpCategoryService } from "./category.service";
import { HttpCompanyService } from "./company.service";
import { HttpCompanyCategoryService } from "./company_category.service";
import { HttpGroupService } from "./group.service";
import { HttpProductService } from "./product.service";
import { HttpMotiveService } from "./motive.service";
import { HttpWasmuthMediaService } from "./wasmuth_media.service";
import { HttpWasmuthRatecardService } from "./wasmuth_ratecard.service";
import { HttpWasmuthRatecardNumberService } from "./wasmuth_ratecard_number.service";
import { HttpWasmuthMediaRatecardService } from "./wasmuth_media_ratecard.service";
import { HttpWasmuthPublisherService } from "./wasmuth_publisher.service";
import { HttpMediaTypeService } from "./mediy_type.service";
import { HttpFormatService } from "./format.service";
import { HttpPriceService } from "./price.service";
import { HttpAdvertisingFormService } from "./advertising_form.service";
import { HttpAdSpendingsService } from "./ad_spendings.service";
import { HttpProductCategoryService } from "./product_category.service";
import { HttpMagazineService } from "./magazine.service";
import { HttpColorService } from "./color.service";
import { HttpWasmuthAdService } from "./wasmuth_ad.service";
import { HttpWasmuthFktFormService } from "./wasmuth_fkt_form.service";
import { HttpWasmuthFktPlacementService } from "./wasmuth_fkt_placement.service";
import { HttpWasmuthFktColourService } from "./wasmuth_fkt_colour.service";
import { HttpWasmuthAdSizeService } from "./wasmuth_ad_size.service";
import { HttpWasmuthFktCostTypeService } from "./wasmuth_fkt_cost_type.service";
import { HttpWasmuthFktPageFormatCodeService } from "./wasmuth_fkt_page_format_code.service";
import { HttpWasmuthAdFormatService } from "./wasmuth_ad_format.service";
import { HttpWasmuthAdCostService } from "./wasmuth_ad_cost.service";
import { HttpWasmuthFktAppearingWeeklyService } from "./wasmuth_fkt_appearing_weekly.service";
import { HttpWasmuthFktFromToService } from "./wasmuth_fkt_from_to.service";
import { HttpWasmuthFktPriceTypeService } from "./wasmuth_fkt_price_type.service";
import { HttpWasmuthFktUnitService } from "./wasmuth_fkt_unit.service";
import { HttpEditionService } from "./edition.service";
import { HttpWasmuthAdspecialService } from "./wasmuth_adspecial.service";
import { HttpWasmuthAdspecialCostService } from "./wasmuth_adspecial_cost.service";
import { HttpWasmuthFktAdspecialTypeService } from "./wasmuth_fkt_adspecial_type.service";
import { HttpWasmuthFktAdspecialCostTypeService } from "./wasmuth_fkt_adspecial_cost_type.service";
import { HttpPriceTypeService } from "./price_type.service";
import { HttpTargetAudienceService } from "./target_audience.service";
import { HttpWasmuthMediaEditionService } from "./wasmuth_media_edition.service";
import { HttpPublisherService } from "./publisher.service";
import { HttpUserService } from "./user.service";
import { HttpApilogService } from "./apilog.service";
import { HttpWefraPubdateService } from "./wefra_pubdate.service";
import { HttpWefraRatecardService } from "./wefra_ratecard.service";
import { HttpWasmuthPublicationDateService } from "./wasmuth_publication_date.service";
import { HttpImportedCirculationService } from "./imported_circulation.service";
import { HttpPubdateAliasService } from "./pubdate_alias.service";
import { HttpPulloutService } from "./pullout.service";
import { HttpTargetAudiencePeriodService } from "./target_audience_period.service";
import { HttpTargetAudiencePeriodEntryService } from "./target_audience_period_entry.service";

@Injectable()
export class HttpServiceFactory {
  constructor(
    @Inject(HttpMarketService) private marketService: HttpMarketService,
    @Inject(HttpSubmarketService) private submarketService: HttpMarketService,
    @Inject(HttpCategoryService) private categoryService: HttpCategoryService,
    @Inject(HttpCompanyService) private companyService: HttpCompanyService,
    @Inject(HttpCompanyCategoryService) private companyCategoryService: HttpCompanyCategoryService,
    @Inject(HttpGroupService) private groupService: HttpGroupService,
    @Inject(HttpProductService) private productService: HttpProductService,
    @Inject(HttpMotiveService) private motiveService: HttpMotiveService,
    @Inject(HttpWasmuthMediaService) private wmService: HttpWasmuthMediaService,
    @Inject(HttpWasmuthMediaRatecardService) private wmrService: HttpWasmuthMediaRatecardService,
    @Inject(HttpWasmuthRatecardService) private wrService: HttpWasmuthRatecardService,
    @Inject(HttpWasmuthRatecardNumberService) private wrnService: HttpWasmuthRatecardNumberService,
    @Inject(HttpWasmuthPublisherService) private publisherService: HttpWasmuthPublisherService,
    @Inject(HttpMediaTypeService) private mediatypeService: HttpMediaTypeService,
    @Inject(HttpFormatService) private formatService: HttpFormatService,
    @Inject(HttpPriceService) private priceService: HttpPriceService,
    @Inject(HttpAdvertisingFormService) private advformService: HttpAdvertisingFormService,
    @Inject(HttpAdSpendingsService) private adspService: HttpAdSpendingsService,
    @Inject(HttpProductCategoryService) private prodcatService: HttpProductCategoryService,
    @Inject(HttpMagazineService) private magazineService: HttpMagazineService,
    @Inject(HttpColorService) private colorService: HttpColorService,
    @Inject(HttpWasmuthAdService) private wmadService: HttpWasmuthAdService,
    @Inject(HttpWasmuthFktFormService) private wmffService: HttpWasmuthFktFormService,
    @Inject(HttpWasmuthFktPlacementService) private wmfpService: HttpWasmuthFktPlacementService,
    @Inject(HttpWasmuthFktColourService) private wmcoService: HttpWasmuthFktColourService,
    @Inject(HttpWasmuthAdSizeService) private wmadsizeService: HttpWasmuthAdSizeService,
    @Inject(HttpWasmuthFktCostTypeService) private wmctService: HttpWasmuthFktCostTypeService,
    @Inject(HttpWasmuthFktPageFormatCodeService) private wmpfcService: HttpWasmuthFktPageFormatCodeService,
    @Inject(HttpWasmuthAdFormatService) private wmafmtService: HttpWasmuthAdFormatService,
    @Inject(HttpWasmuthAdCostService) private wmadcostService: HttpWasmuthAdCostService,
    @Inject(HttpWasmuthFktAppearingWeeklyService) private wmfktappService: HttpWasmuthFktAppearingWeeklyService,
    @Inject(HttpWasmuthFktFromToService) private wmfktfromtoService: HttpWasmuthFktFromToService,
    @Inject(HttpWasmuthFktPriceTypeService) private wmfktpriceService: HttpWasmuthFktPriceTypeService,
    @Inject(HttpWasmuthFktUnitService) private wmfktunitService: HttpWasmuthFktUnitService,
    @Inject(HttpEditionService) private editionService: HttpEditionService,
    @Inject(HttpPriceTypeService) private ptService: HttpPriceTypeService,
    @Inject(HttpWasmuthAdspecialService) private wmadspecialService: HttpWasmuthAdspecialService,
    @Inject(HttpWasmuthAdspecialCostService) private wmadspeccostService: HttpWasmuthAdspecialCostService,
    @Inject(HttpWasmuthFktAdspecialTypeService) private wmfktadsptService: HttpWasmuthFktAdspecialTypeService,
    @Inject(HttpWasmuthFktAdspecialCostTypeService) private wmfktadspecialctService: HttpWasmuthFktAdspecialCostTypeService,
    @Inject(HttpTargetAudienceService) private taaService: HttpTargetAudienceService,
    @Inject(HttpWasmuthMediaEditionService) private meeService: HttpWasmuthMediaEditionService,
    @Inject(HttpPublisherService) private wmpublisherService: HttpPublisherService,
    @Inject(HttpUserService) private userService: HttpUserService,
    @Inject(HttpApilogService) private apilogService: HttpApilogService,
    @Inject(HttpWefraPubdateService) private wepubService: HttpWefraPubdateService,
    @Inject(HttpWefraRatecardService) private wercService: HttpWefraRatecardService,
    @Inject(HttpWasmuthPublicationDateService) private wercasdService: HttpWasmuthPublicationDateService,
    @Inject(HttpImportedCirculationService) private impciService: HttpImportedCirculationService,
    @Inject(HttpPubdateAliasService) private pdaliasService: HttpPubdateAliasService,
    @Inject(HttpPulloutService) private pulloutService: HttpPulloutService,
    @Inject(HttpTargetAudiencePeriodService) private targetAudiencePeriodService: HttpTargetAudiencePeriodService,
    @Inject(HttpTargetAudiencePeriodEntryService) private targetAudiencePeriodEntryService: HttpTargetAudiencePeriodEntryService
  ) {
  }
  public getTypeInstance(
    httpServiceSlug: ServiceSlugProperty
  ): HttpAbstractRecordService<RecordInterface<RecordDataInterface>, RecordDataInterface> {
    switch (httpServiceSlug) {
      case "target_audience_periods":
        return this.targetAudiencePeriodService
      case "target_audience_period_entries":
        return this.targetAudiencePeriodEntryService
      case "pullouts":
        return this.pulloutService
      case "pubdate_aliases":
        return this.pdaliasService
      case "imported_circulations":
        return this.impciService
      case "wasmuth_publication_dates":
        return this.wercasdService
      case "wefra_pubdates":
        return this.wepubService
      case "wefra_ratecards":
        return this.wercService
      case "users":
        return this.userService
      case "apilogs":
        return this.apilogService
      case "publishers":
        return this.wmpublisherService
      case "wasmuth_media_editions":
        return this.meeService
      case 'target_audiences':
        return this.taaService
      case 'price_types':
        return this.ptService
      case 'wasmuth_adspecials':
        return this.wmadspecialService
      case 'wasmuth_fkt_adspecial_types':
        return this.wmfktadsptService
      case 'wasmuth_adspecial_costs':
        return this.wmadspeccostService
      case 'wasmuth_fkt_adspecial_cost_types':
        return this.wmfktadspecialctService
      case 'editions':
        return this.editionService
      case 'wasmuth_fkt_appearing_weekly':
        return this.wmfktappService
      case 'wasmuth_fkt_from_to':
        return this.wmfktfromtoService
      case 'wasmuth_fkt_price_types':
        return this.wmfktpriceService
      case 'wasmuth_fkt_units':
        return this.wmfktunitService
      case 'wasmuth_ad_formats':
        return this.wmafmtService
      case 'wasmuth_ad_costs':
        return this.wmadcostService
      case 'wasmuth_fkt_page_format_codes':
        return this.wmpfcService
      case 'wasmuth_fkt_cost_types':
        return this.wmctService
      case 'wasmuth_ad_sizes':
        return this.wmadsizeService
      case 'wasmuth_fkt_colours':
        return this.wmcoService
      case 'wasmuth_ads':
        return this.wmadService
      case 'wasmuth_fkt_forms':
        return this.wmffService
      case 'wasmuth_fkt_placements':
        return this.wmfpService
      case 'markets':
        return this.marketService
      case 'submarkets':
        return this.submarketService
      case 'categories':
        return this.categoryService
      case 'companies':
        return this.companyService
      case 'company_categories':
        return this.companyCategoryService
      case 'groups':
        return this.groupService
      case 'products':
        return this.productService
      case 'motives':
        return this.motiveService
      case 'wasmuth_media':
        return this.wmService
      case 'wasmuth_media_ratecards':
        return this.wmrService
      case 'wasmuth_ratecards':
        return this.wrService
      case 'wasmuth_publishers':
        return this.publisherService
      case 'wasmuth_ratecard_numbers':
        return this.wrnService
      case 'media_types':
        return this.mediatypeService
      case 'formats':
        return this.formatService
      case 'advertising_forms':
        return this.advformService
      case 'prices':
        return this.priceService
      case 'ad_spendings':
        return this.adspService
      case 'product_categories':
        return this.prodcatService
      case 'magazines':
        return this.magazineService
      case 'colors':
        return this.colorService
      case 'not_defined':
        throw new Error("The service-slug has not been defined in your component!")

      default: throw new Error("HTTPService for identifier " + httpServiceSlug + " not found")
    }
  }
}
