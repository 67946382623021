import { Component, effect, EventEmitter, input, Output } from '@angular/core';
import { ApilogsActionInterface, FiltersInterface, VirtualFilterComponent } from "../../models/main";
import { ModelConfigFactory } from "../../models/factory";

@Component({
    selector: 'wefra-apilogs-activity-filter-component',
    templateUrl: './apilogs-activity-filter.component.html',
    standalone: false
})
export class ApilogsActivityFilterComponent implements VirtualFilterComponent {

  filters = input<FiltersInterface>({})

  @Output("setFilters") setFiltersEvent: EventEmitter<FiltersInterface> = new EventEmitter()

  public value = ''
  public actions: ApilogsActionInterface[] = ModelConfigFactory.getLoggedApiCalls()

  constructor(
  ) {
    effect(() => {
      this.setValueByFilters(this.filters())
    })
  }

  onChange(e: Event) {

    const value = (e.target as HTMLSelectElement).selectedOptions.item(0)?.id

    if (! value || value.length == 0) {
      this.value = ""
      this.setFiltersEvent.emit(undefined)
      return
    }

    let [ method, slug ] = value.split('|')

    let filter: FiltersInterface = {
      uri: slug!,
      method: method!,
    }

    this.value = value

    this.setFiltersEvent.emit(filter)
  }

  setValueByFilters(filters: FiltersInterface) {

    let method: string | undefined = undefined
    let slug: string | undefined = undefined

    Object.entries(filters).forEach(([key, value]) => {
      if (key == "uri") {
        slug = value + ''
      }
      if (key == "method") {
        method = value + ''
      }
    })

    if (slug && method) {
      for (let action of this.actions) {
        if (action.slug == slug && action.method == method) {
          this.value = action.method + '|' + action.slug
        }
      }
    } else {
      this.value = ""
    }
  }
}
