import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ModelConfigurationInterface,
  RecordDataInterface,
  RecordHistoricizePreviewInterface,
  RecordInterface
} from "../../models/main";
import {
  AdSpendingModelConfiguration,
  AdSpendingRecordDataInterface,
  AdSpendingRecordInterface
} from "../../models/ad_spending";
import { EditionModelConfiguration, EditionRecordDataInterface, EditionRecordInterface } from "../../models/edition";

@Component({
    selector: 'wefra-crud-grid-historicize-preview',
    templateUrl: './preview.component.html',
    standalone: false
})
export class CrudGridHistoricizePreviewComponent<T extends RecordInterface<K>, K extends RecordDataInterface> implements OnInit {
  @Output("doHistoricize") doHistoricizeEvent = new EventEmitter<boolean>()

  @Input() historicizePreview?: RecordHistoricizePreviewInterface<RecordInterface<RecordDataInterface>>
  @Input() modelConfig?: ModelConfigurationInterface

  editionsModelConfig: ModelConfigurationInterface = EditionModelConfiguration
  adspendingsModelConfig: ModelConfigurationInterface = AdSpendingModelConfiguration
  editionsLimitToFields = ['capture_state', 'id', 'file_basename', 'wasmuth_edition_name', 'magazine_id', 'wasmuth_publication_date']
  adspendingsLimitToFields = ['headline_id', 'magazine_id', 'year', 'month', 'issue', 'placement']

  onSubmit() {
    this.doHistoricizeEvent.emit(true)
  }

  onCancel(recordToKeep?: RecordInterface<RecordDataInterface>) {
    this.doHistoricizeEvent.emit(false)
  }

  ngOnInit(): void {
  }
}
