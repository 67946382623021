import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ApiListResponseInterface,
  KeyValueInterface,
  RecordDataInterface,
  RecordHistoricizeInterface,
  RecordHistoricizePreviewInterface,
  RecordInterface,
  RecordReplaceInterface,
  SearchCriteriasInterface,
  ServiceSlugProperty
} from "../../models/main";
import { HttpBaseService } from "./base.service";
import { ModelConfigFactory } from "../../models/factory";
import { HttpResponse } from "@angular/common/http";

export interface XlsExportResponseInterface {
  success: boolean
  message: string
}

@Injectable()
export abstract class HttpAbstractRecordService<T extends RecordInterface<RecordDataInterface>, K extends RecordDataInterface> extends HttpBaseService {

  abstract slug: ServiceSlugProperty

  get(id: number): Observable<T> {
    const url: string = this.getMainApiBaseUrl() + this.slug + '/' + id
    return this.http.get<T>(url)
  }

  getFilterParamsFromSearchCriterias(searchCriterias: SearchCriteriasInterface): URLSearchParams {
    let params = new URLSearchParams()

    // check sorting
    if (searchCriterias.sorting) {
      let sortstring = ''
      for (let i = 0; i < searchCriterias.sorting.length; i++) {
        if (searchCriterias.sorting[i]!.dir) {
          sortstring += ((searchCriterias.sorting[i]!.dir == 'desc' ? '-' : '') + searchCriterias.sorting[i]!.field + ',')
        }
      }
      if (sortstring.length) params.set('sort', sortstring)
    }

    // check filters
    if (searchCriterias.filters) {
      Object.entries(searchCriterias.filters).forEach(([ prop, sword ]) => {
        params.set('filter[' + prop + ']', sword + '')
      })
    }
    return params
  }

  list(searchCriterias?: SearchCriteriasInterface, additionalParams?: KeyValueInterface): Observable<ApiListResponseInterface<T>> {
    let url: string = this.getMainApiBaseUrl() + this.slug

    if (searchCriterias) {
      let params = this.getFilterParamsFromSearchCriterias(searchCriterias)
      params.set('page', searchCriterias.paging ? searchCriterias.paging.page+'' : "1")
      params.set('pagesize', searchCriterias.paging ? searchCriterias.paging.size+'' : "20")

      url += '?' + params.toString()
    }

    if (additionalParams) {
      let params = new URLSearchParams()
      Object.entries(additionalParams).forEach((v) => {
        params.set(v[0], v[1]+'')
      })
      if (searchCriterias) {
        url += '&' + params.toString()
      } else {
        url += '?' + params.toString()
      }
    }

    return this.http.get<ApiListResponseInterface<T>>(url)
  }

  export(searchCriterias?: SearchCriteriasInterface): Observable<XlsExportResponseInterface> {
    let url: string = this.getMainApiBaseUrl() + this.slug + '/export/'

    if (searchCriterias) {
      let params = this.getFilterParamsFromSearchCriterias(searchCriterias)
      url += '?' + params.toString()
    }

    return new Observable<XlsExportResponseInterface>(subscriber => {
      this.http.get(url, { responseType: 'blob' }).subscribe(response => {
        const type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        let downloadLink = document.createElement('a')
        let filename = this.slug + '.xlsx'
        downloadLink.href = window.URL.createObjectURL(
          new Blob(
            [response],
            { type: type }
          )
        )
        downloadLink.setAttribute('download', filename)
        downloadLink.setAttribute('type', type)
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
        subscriber.next({success: true, message: 'Der Export wurde generiert!'})
      }, error => {
        subscriber.next({success: false, message: 'Es ist ein Fehler beim Generieren des Exports aufgetreten!'})
      })
    })
  }

  create(record: K): Observable<T> {
    const url: string = this.getMainApiBaseUrl() + this.slug
    return this.http.post<T>(url, record)
  }

  update(record: K): Observable<T> {
    const url: string = this.getMainApiBaseUrl() + this.slug + '/' + record["id"]
    this.undefinedToNull(record)
    return this.http.put<T>(url, record)
  }

  undefinedToNull(record: K) {
    for (let recordKey in record) {
      if (record[recordKey] === undefined) {
        // @ts-ignore
        record[recordKey] = null
      }
    }
  }

  deleteById(id: number): Observable<T>  {
    const url: string = this.getMainApiBaseUrl() + this.slug + '/' + id
    return this.http.delete<T>(url)
  }

  delete(record: T): Observable<T>  {
    return this.deleteById(record.data.id!)
  }

  replace(records: RecordReplaceInterface<K>) {
    const url: string = this.getMainApiBaseUrl() + this.slug + '/replace/'
    return this.http.post<T>(url, records)
  }

  historicize(records: RecordHistoricizeInterface<K>) {
    const url: string = this.getMainApiBaseUrl() + this.slug + '/historicize/'
    return this.http.post<T>(url, records)
  }

  historicizePreview(records: RecordHistoricizeInterface<K>) {
    const url: string = this.getMainApiBaseUrl() + this.slug + '/historicizePreview/'
    return this.http.post<RecordHistoricizePreviewInterface<T>>(url, records)
  }

  search(sword: string): Observable<ApiListResponseInterface<T>> {
    if (sword.length == 0) return this.list()

    let url: URL = new URL(this.getMainApiBaseUrl() + this.slug)
    url.searchParams.append("filter[" + this.getNameProperty() + "]", String(sword))
    return this.http.get<ApiListResponseInterface<T>>(url+'')
  }

  getEmptyRecord(preselection?: K): T {
    let modelConfig = ModelConfigFactory.getConfig(this.slug)
    let record: RecordInterface<RecordDataInterface> = {
      data: {},
      resolved: {}
    }

    for (const field of modelConfig.fields) {
      if (preselection?.[field.prop]) {
        record.data[field.prop] = preselection?.[field.prop]
      } else if (field.hasOwnProperty("defaultValue")) {
        record.data[field.prop] = field.defaultValue
      } else {
        record.data[field.prop] = undefined
      }
    }

    return record as T
  }

  getFilterProperty() {
    const modelConfig = ModelConfigFactory.getConfig(this.slug)
    let prop = undefined
    let searchProp = undefined
    for (let i = 0; i < modelConfig.fields.length; i++) {
      if (modelConfig.fields[i]?.isNameProperty) {
        prop = modelConfig.fields[i]?.prop
        searchProp = modelConfig.fields[i]?.filterProperty
      }
    }
    return searchProp ?? prop
  }

  getNameProperty() {
    const modelConfig = ModelConfigFactory.getConfig(this.slug)
    let prop = undefined
    for (let i = 0; i < modelConfig.fields.length; i++) {
      if (modelConfig.fields[i]?.isNameProperty) {
        prop = modelConfig.fields[i]?.prop
      }
    }
    return prop
  }

  getFileNameFromResponse(response: HttpResponse<Blob>) {
    let filename: string = this.slug + '.xlsx'
    try {
      const contentDisposition= response.headers.get('content-disposition')
      if (contentDisposition) {
        const r = /(?:filename=")(.+)(?:;")/
        // @ts-ignore
        if (r) filename = r.exec(contentDisposition)[1]
      }
    }
    catch (e) {
      filename = this.slug + '.xlsx'
    }
    return filename
  }
}
