import { Injectable } from "@angular/core";
import { HttpAbstractRecordService } from "./record.service.abstract";
import { ServiceSlugProperty } from "../../models/main";
import {
  TargetAudiencePeriodRecordDataInterface,
  TargetAudiencePeriodRecordInterface
} from "../../models/target_audience_period";

@Injectable()
export class HttpTargetAudiencePeriodService extends HttpAbstractRecordService<TargetAudiencePeriodRecordInterface, TargetAudiencePeriodRecordDataInterface> {
  slug: ServiceSlugProperty = 'target_audience_periods'
}
