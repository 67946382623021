<wefra-generic-form
  [record]="record"
  [modelConfig]="modelConfig"
  (saveRecord)="onPeriodRecordSave($event)"
  (closeClick)="onCloseClick($event)"
></wefra-generic-form>

<wefra-edit-grid *ngIf="record && record.data['id']"
  heading="Zielgruppen und ihre Anteile"
  [modelConfig]="targetAudiencePeriodEntryModelConfig"
  [records]="targetAudiencePeriodEntries"
  [limitToFields]="['targetaudience_id', 'quota_in_percent']"
  [editableFields]="['targetaudience_id', 'quota_in_percent']"
  [newRecordDefaults]="{ period_id: record.data['id'] }"
  [fixedFilters]="{ period_id: record.data['id'] }"
></wefra-edit-grid>
