import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FiltersInterface, RecordDataInterface, RecordInterface, ServiceSlugProperty } from "../../../models/main";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { HttpAbstractRecordService } from "../../../services/http/record.service.abstract";
import { HttpServiceFactory } from "../../../services/http/factory";
import { CrudGridComponent } from "../../../crud-grid/crud-grid.component";
import { ModalService } from "../../../services/modal.service";
import { ModelConfigFactory } from "../../../models/factory";
import { ToastService } from "../../../services/toast.service";

@Component({
    selector: 'wefra-record-value-select-form-field',
    templateUrl: './record-value-select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: RecordValueSelectComponent
        }
    ],
    standalone: false
})
export class RecordValueSelectComponent<T extends RecordInterface<RecordDataInterface>, K extends RecordDataInterface> implements OnInit, ControlValueAccessor {
  @Input() fixedFilters?: FiltersInterface
  @Input() gridPresetFilters?: FiltersInterface
  @Input() gridLimitToFields?: string[]
  @Input() value: number | string = ''
  @Input() serviceSlug: ServiceSlugProperty = 'not_defined'
  @Input() modalTitle: string = 'Wert wählen'
  @Input() valueProperty: string = 'id'
  @Input() nameProperty: string = 'name'

  @Output("selectRecord") selectRecordEvent = new EventEmitter<RecordInterface<RecordDataInterface>>()
  @Output("onChange") onChangeEvent = new EventEmitter<string | number | undefined>

  disabled: boolean = false
  recordService?: HttpAbstractRecordService<T, K>
  touched: boolean = false

  constructor(
    @Inject(HttpServiceFactory) private serviceFactory: HttpServiceFactory,
    @Inject(ModalService) private modalService: ModalService,
    @Inject(ToastService) private toastService: ToastService,
    private cd: ChangeDetectorRef
  ) {
  }

  openGridSelectorModal() {
    let that = this

    const modelconfig = ModelConfigFactory.getConfig(that.serviceSlug)
    let modal = this.modalService.show(CrudGridComponent, {
      inputs: {
        modelConfig: modelconfig,
        api: this.recordService,
        selectOnly: true,
        fixedFilters: this.fixedFilters,
        limitToFields: this.gridLimitToFields,
        presetFilters: this.gridPresetFilters
      },
      closeBtnText: undefined,
      title: this.modalTitle,
      modalOptions: {
        windowClass: 'grid-modal',
      },
      subscribers: {
        selectRecordEvent: function(record: T) {
          that.selectRecord(record)
          modal.close()
        }
      }
    })
  }

  onChange = (value: any) => {}

  onTouched = () => {}

  doChange(e: any) {
    this.onChangeEvent.emit(e.target.value)
  }


  ngOnInit(): void {
    // @ts-ignore
    this.recordService = this.serviceFactory.getTypeInstance(this.serviceSlug)
    const np = this.recordService?.getNameProperty()
    if (np) {
      this.nameProperty = np
    }
  }

  selectRecord(record: RecordInterface<RecordDataInterface>) {
    this.value = record.data[this.valueProperty]
    this.onChangeEvent.emit(this.value)
  }

  clearField() {
    this.value = ''
    this.onChangeEvent.emit(this.value)
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled
  }

  writeValue(id?: number | string): void {
    this.value = id ? id : ''
  }

  markAsTouched() {
    if (! this.touched) {
      this.onTouched()
      this.touched = true
    }
  }
}
