import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface PulloutRecordDataInterface extends RecordDataInterface {
  id: number
  edition_id: number
  pullout_id: number
  mutual_price: number
  unpayed_pages: number
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface PulloutRecordInterface extends RecordInterface<PulloutRecordDataInterface> {
}

export const PulloutModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "pullouts",
  title: "Beihefter",
  titleSingular: "Beihefter",
  defaultSort: [{ field: 'pullout_id', dir: 'asc' }],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },

    { type: "int", title: '# in Heft', prop: 'pullout_id', isNameProperty: true, validators: [ Validators.required ]},

    { type: "int", title: 'Heft', prop: 'edition_id', validators: [ Validators.required ]},
    { type: "int", title: 'Preis Beihefter', prop: 'mutual_price', validators: [ Validators.required ]},
    { type: "int", title: 'Unbezahlte Seiten', prop: 'unpayed_pages', validators: [ Validators.required ]},

    { prop: "created_by", ability: "spa-userinfo", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-userinfo", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-userinfo", type: "date", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-userinfo", type: "date", title: 'Bearbeitet am', edit: false},
  ]
}

