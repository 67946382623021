import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RecursiveJsonInterface } from "../../models/main";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
    selector: 'wefra-json-renderer',
    templateUrl: './json-renderer.component.html',
    standalone: false
})
export class JsonRendererComponent implements OnInit, OnChanges  {
  @Input({required: true}) value: string = "{}"

  html?: SafeHtml

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.render()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render()
  }

  private render() {
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.renderAsTableRecursive(JSON.parse(this.value)))
  }

  private renderAsTableRecursive(json: number | string | RecursiveJsonInterface): string {
    if (json === undefined || json === null) return ""
    let html = '<table class="json"><tbody>'
    let i = 1
    for (const jsonElement of Object.entries(json)) {
      const isobject = true //(typeof jsonElement == "object" && ! Array.isArray(jsonElement))

      html += '<tr class="' + (i % 2 == 0 ? 'even' : 'odd') + '">'
      html += '  <td' + (isobject ? ' class="op"' : '') + '>' + jsonElement[0] + '</td><td>'

      if (typeof jsonElement[1] == "object" && ! Array.isArray(jsonElement[1])) {
        html += this.renderAsTableRecursive(jsonElement[1])
      } else if (Array.isArray(jsonElement[1])) {
        if (jsonElement[1].length > 1) html += this.renderAsListInTableRecursive(jsonElement[1])
        else {
          if (jsonElement[1][0]) html += jsonElement[1][0]
          else html += '&nbsp;'
        }
      } else {
        if (jsonElement[1]) html += jsonElement[1]
        else html += '&nbsp;'
      }
      html += '</td></tr>'
      i++
    }
    html += '</tbody></table>'

    return html
  }

  private renderAsListInTableRecursive(a: any[]) {
    let html = '<ul class="json">'
    let i = 1
    for (const jsonElement of a) {
      html += '<li class="' + (i % 2 == 0 ? 'even' : 'odd') + '">'
      if (typeof jsonElement == "object" && ! Array.isArray(jsonElement)) {
        html += this.renderAsTableRecursive(jsonElement)
      } else if (Array.isArray(jsonElement)) {
        html += this.renderAsListInTableRecursive(jsonElement)
      } else {
        if (jsonElement) html += jsonElement
        else html += '&nbsp;'
      }
      html += '</li>'
      i++
    }
    html += '</ul>'

    return html
  }
}
