import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalChildComponentInputInterface, ModalChildComponentSubscribersInterface } from "./modal.service";

@Component({
    selector: 'ngbd-modal-content',
    template: `
    <div *ngIf="title" class="modal-header" ngxModalDraggable>
      <h4 class="modal-title {{ titleClass }}">{{ title }}</h4>
      <button type="button" class="btn-close" (click)="activeModal.close('manually closed')"></button>
    </div>
    <hr class="divider" *ngIf="title" />
    <div class="modal-body">
      <p class="m-5" *ngIf="infoText">{{ infoText }}</p>

      <ng-template *ngIf="content">
        <ng-template [ngTemplateOutlet]="content"></ng-template>
      </ng-template>

      <ng-template *ngIf="text">{{ text }}</ng-template>
      <div #container></div>
    </div>
    <div class="modal-footer" *ngIf="closeBtnText">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('manually closed')">{{ closeBtnText }}</button>
    </div>
  `,
    standalone: false
})
export class NgbdModalContent implements AfterViewInit, AfterViewChecked {
  // @see: ModalOptionsInterface
  @Input() title: string | null = null;
  @Input() titleClass: string = '';

  @Input() infoText: string | null = null;
  @Input() closeBtnText?: string = "Schließen";
  @Input() inputs?: ModalChildComponentInputInterface
  @Input() subscribers?: ModalChildComponentSubscribersInterface

  @Input() content: TemplateRef<any> | null = null
  @Input() comp: Component | null = null
  @Input() text: string | null = null
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef

  constructor(
    public activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    if (this.comp && this.container) {
      // @ts-ignore
      let ref = this.container.createComponent(this.comp)

      if (this.inputs) {
        for (const [inputProperty, inputValue] of Object.entries(this.inputs)) {
          ref.setInput(inputProperty, inputValue)
        }
      }
      if (this.subscribers) {
        for (const [outputName, callbackFn] of Object.entries(this.subscribers)) {
          // @ts-ignore
          if (ref.instance[outputName]) {
            // @ts-ignore
            ref.instance[outputName].subscribe(callbackFn)
          }
        }
      }
    }
  }
}
