import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import {
  WefraRatecardModelConfiguration,
  WefraRatecardRecordDataInterface,
  WefraRatecardRecordInterface
} from "../models/wefra_ratecard";
import { HttpWefraRatecardService } from "../services/http/wefra_ratecard.service";

@Component({
    selector: 'wefra-ratecards-component',
    templateUrl: '../abstract-records/abstract-record.component.html',
    standalone: false
})
export class WefraRatecardsComponent extends AbstractRecordComponent<WefraRatecardRecordInterface, WefraRatecardRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = WefraRatecardModelConfiguration
  api = inject(HttpWefraRatecardService)

  constructor(
  ) {
    super()
  }
}
