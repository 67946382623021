import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompaniesComponent } from "./companies/companies.component";
import { MarketsComponent } from "./markets/markets.component";
import { SubmarketsComponent } from "./submarkets/submarkets.component";
import { AdSpendingsComponent } from "./ad-spendings/ad-spendings.component";
import { AdSpendingsGridComponent } from "./ad-spendings/edition/grid.component";
import { WasmuthTablesMediaComponent } from "./wasmuth/tables/media/media.component";
import { WasmuthTablesMediaRatecardComponent } from "./wasmuth/tables/media_ratecard/media_ratecard.component";
import { PublishersComponent } from "./publishers/publishers.component";
import { MotivesComponent } from "./motives/motives.component";
import { UploadComponent } from "./ad-spendings/upload/upload.component";
import { AdSpendingsCaptureComponent } from "./ad-spendings/capture/capture.component";
import { CategoriesComponent } from "./categories/categories.component";
import { FormatsComponent } from "./formats/formats.component";
import { PricesComponent } from "./prices/prices.component";
import { ColorsComponent } from "./colors/colors.component";
import { AdvertisingFormsComponent } from "./advertising_forms/advertising_forms.component";
import { EditionShowComponent } from "./ad-spendings/edition/show.component";
import { ProductCategoriesComponent } from "./product_categories/product_categories.component";
import { CompanyCategoryComponent } from "./company_categories/company_categories.component";
import { MagazinesComponent } from "./magazines/magazines.component";
import { ProductsComponent } from "./products/products.component";
import { TargetAudiencesComponent } from "./target_audiences/target_audiences.component";
import { MonitorComponent } from "./monitor/monitor.component";
import { EditionsComponent } from "./editions/editions.component";
import { AdminComponent } from "./admin/admin.component";
import { WefraRatecardsComponent } from "./wefra_ratecards/wefra_ratecards.component";
import { WefraPubdatesComponent } from "./wefra_pubdates/wefra_pubdates.component";
import { GenericAdSpendingsComponent } from "./ad-spendings/generic.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ApilogsComponent } from "./admin/apilogs/apilogs.component";

let routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    title: "Pharmamonitor",
    // redirectTo: "/ad-spendings/editions",
    // pathMatch: "full",
    data: {  },
  }, {
    title: "Firmen",
    path: "companies",
    component: CompaniesComponent
  }, {
    title: "Printmedien",
    path: "editions",
    component: EditionsComponent
  }, {
    title: "Anzeigen",
    path: "adspendings",
    component: GenericAdSpendingsComponent
  }, {
    title: "Branchen",
    path: "markets",
    component: MarketsComponent
  }, {
    title: "Unterbranchen",
    path: "submarkets",
    component: SubmarketsComponent
  }, {
    title: "Motive",
    path: "motives",
    component: MotivesComponent
  }, {
    title: "Products",
    path: "products",
    component: ProductsComponent
  }, {
    title: "Company Categories",
    path: "company_categories",
    component: CompanyCategoryComponent
  }, {
    title: "Zielgruppen",
    path: "target_audiences",
    component: TargetAudiencesComponent
  }, {
    title: "Monitor",
    path: "monitor",
    component: MonitorComponent
  }, {
    title: "Kategorie",
    path: "categories",
    component: CategoriesComponent
  }, {
    title: "Produktkategorien",
    path: "product_categories",
    component: ProductCategoriesComponent
  }, {
    title: "Format",
    path: "formats",
    component: FormatsComponent
  }, {
    title: "Preise",
    path: "prices",
    component: PricesComponent
  }, {
    title: "Farbe",
    path: "colors",
    component: ColorsComponent
  }, {
    title: "Magazin",
    path: "magazines",
    component: MagazinesComponent
  }, {
    title: "Publisher",
    path: "publishers",
    component: PublishersComponent
  }, {
    title: "Werbeform",
    path: "advertising_forms",
    component: AdvertisingFormsComponent
  }, {
    title: "Wefra Ratecards",
    path: "wefra_ratecards",
    component: WefraRatecardsComponent
  }, {
    title: "Wefra Erscheinungsdaten",
    path: "wefra_pubdates",
    component: WefraPubdatesComponent
  },
  // Werbeträger
  {
    title: "Ad-Spendings Erfassung",
    path: "ad-spendings/edition/:editionRecordId",
    component: UploadComponent
  }, {
    title: "Printmedien",
    path: "ad-spendings/editions",
    component: AdSpendingsGridComponent
  }, {
    title: "Printmedien & Anzeigen",
    path: "edition-and-adspendings/:editionRecordId",
    component: EditionShowComponent
  }, {
    title: "Anzeigen",
    path: "ad-spendings/list",
    component: AdSpendingsComponent
  }, {
    title: "Anzeige erfassen",
    path: "ad-spendings/capture/:editionRecordId",
    component: AdSpendingsCaptureComponent
  },


  // Wasmuth
  {
    title: "Wasmuth Werbeträger",
    path: "wasmuth-tables/media",
    component: WasmuthTablesMediaComponent
  }, {
    title: "Wasmuth Ratecards",
    path: "wasmuth-tables/media-ratecards",
    component: WasmuthTablesMediaRatecardComponent
  },

  // Admin
  {
    title: "Adminbereich",
    path: "admin",
    component: AdminComponent
  }, {
    title: "Log",
    path: "admin/logs",
    component: ApilogsComponent
  }

]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
