import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import { PriceModelConfiguration, PriceRecordDataInterface, PriceRecordInterface } from "../models/price";
import { HttpPriceService } from "../services/http/price.service";

@Component({
    selector: 'wefra-Prices',
    templateUrl: '../abstract-records/abstract-record.component.html',
    standalone: false
})
export class PricesComponent extends AbstractRecordComponent<PriceRecordInterface, PriceRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = PriceModelConfiguration
  api = inject(HttpPriceService)

  constructor(
  ) {
    super()
  }
}
