import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdSpendingRecordInterface } from "../../models/ad_spending";

@Component({
    selector: 'wefra-ad-spendings-pull-out-question',
    templateUrl: './pull-out-question.component.html',
    standalone: false
})
export class AdSpendingsPullOutQuestionComponent {
  @Input({required: true}) public records: AdSpendingRecordInterface[] = []
  @Output("answer") answerEvent = new EventEmitter<boolean>()

  constructor(
  ) {
  }

  answer(answer: boolean) {
    this.answerEvent.emit(answer)
  }
}
