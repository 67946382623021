<div class="json-button" [class.expanded]="! isCollapsed">
  <button
    type="button"
    class="btn btn-sm btn-black"
    (click)="onClick()"
    [class.expanded]="! isCollapsed"
  >
    {{ isCollapsed ? 'Anzeigen' : 'Ausblenden' }}
  </button>
</div>
