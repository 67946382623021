<ng-container *ngIf="! this.readonly else readonlyTmpl">
  <div class="inputs-wrap" [class.show-time]="showTime" [class.show-second]="showSecond" [class.invalid]="invalid">
    <div class="input-wrap day" ngbTooltip="Tag des Monats">
      <input [value]="dateNumbers['day']" type="number" placeholder="T" min="1" max="31"
             (focusout)="onFocusOut($event, 'day')"
             (keyup)="onKeyUp($event, 'day')"
      >
    </div>
    <div class="input-wrap month" ngbTooltip="Monat">
      <input [value]="dateNumbers['month']" type="number" placeholder="M" min="1" max="12"
             (focusout)="onFocusOut($event, 'month')"
             (keyup)="onKeyUp($event, 'month')"
      >
    </div>
    <div class="input-wrap year" ngbTooltip="Jahr">
      <input [value]="dateNumbers['year']" type="number" placeholder="Y" min="10" max="3000"
             (focusout)="onFocusOut($event, 'year')"
             (keyup)="onKeyUp($event, 'year')"
      >
    </div>
    <ng-container *ngIf="showTime">
      <div class="input-wrap hour" ngbTooltip="Stunde des Tages">
        <input [value]="dateNumbers['hour']" type="number" placeholder="S" min="0" max="23"
               (focusout)="onFocusOut($event, 'hour')"
               (keyup)="onKeyUp($event, 'hour')"
        >
      </div>
      <div class="input-wrap minute" ngbTooltip="Minute der Stunde">
        <input [value]="dateNumbers['minute']" type="number" placeholder="M" min="1" max="59"
               (focusout)="onFocusOut($event, 'minute')"
               (keyup)="onKeyUp($event, 'minute')"
        >
      </div>
      <ng-container *ngIf="showSecond">
        <input [value]="dateNumbers['second']" type="number" placeholder="S" min="1" max="59"
               (focusout)="onFocusOut($event, 'second')"
               (keyup)="onKeyUp($event, 'second')"
        >
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<ng-template #readonlyTmpl>
  <input readonly="readonly" value="{{ value | mydatetime }}" type="text">
</ng-template>
