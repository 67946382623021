import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";

export interface PubdateAliasRecordDataInterface extends RecordDataInterface {
  id?: number

  alias_medium_id: number
  alias_ratecard_id: number
  alias_ratecard_number: string
  alias_publication_date: string
  alias_edition_number: number
  alias_format_key: number
  alias_format_number: number

  alias_of_medium_id: number
  alias_of_ratecard_id: number
  alias_of_ratecard_number: string
  alias_of_publication_date: string
  alias_of_edition_number: number
  alias_of_format_key: number
  alias_of_format_number: number

  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface PubdateAliasRecordInterface extends RecordInterface<PubdateAliasRecordDataInterface> {
}

export const PubdateAliasModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "pubdate_aliases",
  allowExportXlsx: false,
  allowReplace: false,
  title: "Pubication Date Duplikate",
  titleSingular: "Pubication Date Duplikate",
  defaultSort: [{ field: 'id', dir: 'asc' }],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false },
    // If needed, complete constraints
    // { prop: 'product_id', type: "constraint", title: 'Produkt', constraint: { table: 'products' }, validators: [ Validators.required ]},

    { prop: "created_by", ability: "spa-userinfo", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-userinfo", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-userinfo", type: "date", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-userinfo", type: "date", title: 'Bearbeitet am', edit: false},
  ]
}

