import { Component, inject } from '@angular/core';
import { ModelConfigurationInterface } from "../models/main";
import { AbstractRecordComponent } from "../abstract-records/abstract-record.component";
import {
  TargetAudienceModelConfiguration,
  TargetAudienceRecordDataInterface,
  TargetAudienceRecordInterface
} from "../models/target_audience";
import { HttpTargetAudienceService } from "../services/http/target_audience.service";

@Component({
    selector: 'wefra-target-audiences',
    templateUrl: '../abstract-records/abstract-record.component.html',
    standalone: false
})
export class TargetAudiencesComponent extends AbstractRecordComponent<TargetAudienceRecordInterface, TargetAudienceRecordDataInterface> {

  modelConfig: ModelConfigurationInterface = TargetAudienceModelConfiguration
  api = inject(HttpTargetAudienceService)

  constructor(
  ) {
    super()
  }
}
