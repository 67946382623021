<ng-container *ngIf="records == undefined; else loaded">
  <div class="heading" *ngIf="heading">
    {{ heading }}
  </div>
  <div class="load-mask">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
        </div>
      </div>
  </div>
</ng-container>
<ng-template #loaded>
  <div class="top-container"
       [class.with-controls]="controls && controls.length"
       [class.with-heading]="heading"
  >
    <div class="heading" *ngIf="heading">
      {{ heading }}
    </div>
    <div class="controls" *ngIf="controls && controls.length">
      <div class="control" *ngFor="let control of controls">
        <i-bs [ngbTooltip]="control.label" [name]="control.icon" class="pointer"
              width="1.5rem" height="1.5rem" (click)="control.method.call(control.scope)" />
      </div>
    </div>
  </div>
  <table class="table table-hover" [class]="modelConfig?.serviceSlug">
    <thead>
    <tr>
      <ng-container *ngFor="let field of getFields()">
        <th scope="col" [class]="field.prop" [class.name-property]="field.isNameProperty" [class.id-property]="field.isIdProperty" >
            {{ field.title }}
        </th>
      </ng-container>
      <th class="actions" *ngIf="actions && actions.length">
      </th>
    </tr>

    </thead>
    <tbody>
      <ng-container *ngIf="records?.length; else emptylist">
        <ng-container *ngFor="let record of records">
          <tr [class.loading]="updating && lastActionRecordId == record.data.id"
              [class.success]="updateSuccess === true && lastActionRecordId == record.data.id"
              [class.error]="updateSuccess === false && lastActionRecordId == record.data.id"
          >
            <ng-container *ngFor="let field of getFields()">
              <ng-container *ngIf="editableFields.includes(field.prop); else noedit">
                <td class="{{ field.prop }} pointer nopadding" (click)="onClickField(field.prop, record.data.id!)">
                  <wefra-edit-grid-field-renderer
                    (changeRecord)="this.onFieldChangeRecordEvent($event)"
                    (trySaveRecord)="this.onFieldTrySaveRecordEvent($event)"
                    [clickFieldSubject]="clickFieldSubject"
                    [record]="record"
                    [fieldConfig]="field"
                  >
                  </wefra-edit-grid-field-renderer>
                </td>
              </ng-container>
              <ng-template #noedit>
                <td class="{{ field.prop }} pointer">
                  <wefra-crud-grid-field-renderer [context]="'mini-grid'" [record]="record" [fieldConfig]="field"></wefra-crud-grid-field-renderer>
                </td>
              </ng-template>
            </ng-container>
            <td class="actions" *ngIf="actions && actions.length">
              <div class="wrapper">
              <ng-container *ngFor="let action of actions">
                <span class="pointer {{ action.identifier }}" (click)="action.method.call(action.scope, record)">
                  <i-bs [name]="action.icon" width="1.2em" height="1.2em" ngbTooltip="{{ action.label }}"></i-bs>
                </span>
              </ng-container>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #emptylist>
        <tr class="empty"><td [attr.colspan]="getFields().length">Es konnten keine {{ modelConfig?.title }} gefunden werden!</td></tr>
      </ng-template>
    </tbody>
  </table>
</ng-template>
