import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface MotiveRecordDataInterface extends RecordDataInterface {
  id: number
  name: string
  product_id: number
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface MotiveRecordInterface extends RecordInterface<MotiveRecordDataInterface> {
}

export const MotiveModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "motives",
  allowExportXlsx: true,
  allowReplace: true,
  title: "Motive",
  titleSingular: "Motiv",
  defaultSort: [{ field: 'id', dir: 'desc' }],
  fields: [
    { type: 'id', title: '#', prop: 'id', isIdProperty: true, edit: false, renderInMultiselect: true },
    { type: "text", title: 'Name', prop: 'name', isNameProperty: true, validators: [ Validators.required ], renderInMultiselect: true,
      constraintComponentFieldRenderer: (record) => {
        try {
          // @ts-ignore
          if (record.resolved['product_id'].data["name"]) {
            // @ts-ignore
            return record.data["name"] + ' (' + record.resolved['product_id'].data["name"] + ')'
          }
        } catch (e) {
        }

        return record.data["name"]
      }
    },
    { type: "constraint", title: 'Produkt', prop: 'product_id', constraint: { table: 'products' }, validators: [ Validators.required ], renderInMultiselect: true},
    { prop: 'company_id', type: 'virtual', title: 'Firma', edit: false, renderer: (record) => {
        try {
          // @ts-ignore
          if (record.resolved['product_id'].resolved['company_id'].data["name"]) {
            // @ts-ignore
            return record.resolved['product_id'].resolved['company_id'].data["name"]
          }
        } catch (e) {
          return '<keine Angabe>'
        }
        return '<keine Angabe>'
      }, filterProperty: 'productId.companyId.name', disableSort: true, renderInMultiselect: true },
    { prop: "created_by", ability: "spa-userinfo", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-userinfo", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-userinfo", type: "date", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-userinfo", type: "date", title: 'Bearbeitet am', edit: false},
  ]
}

