import { ModelConfigurationInterface, RecordDataInterface, RecordInterface } from "./main";
import { Validators } from "@angular/forms";

export interface TargetAudiencePeriodRecordDataInterface extends RecordDataInterface {
  id: number
  magazine_id: number
  valid_since: string
  valid_till: string
  modified_by?: string
  created_by?: string
  created_at?: string
  modified_at?: string
}

export interface TargetAudiencePeriodRecordInterface extends RecordInterface<TargetAudiencePeriodRecordDataInterface> {
}

export const TargetAudiencePeriodModelConfiguration: ModelConfigurationInterface = {
  serviceSlug: "target_audience_periods",
  allowExportXlsx: true,
  title: "Zielgruppenperioden",
  titleSingular: "Zielgruppenperiode",
  fields: [
    { prop: 'id', type: 'id', title: '#', isIdProperty: true, edit: false },
    { prop: 'magazine_id', type: "constraint", title: 'Magazin', constraint: { table: 'magazines' }, validators: [ Validators.required ]},
    { prop: "valid_since", type: "date", title: 'Gültig ab', edit: true, validators: [ Validators.required ] },
    { prop: "valid_till", type: "date", title: 'Gültig bis', edit: true },
    { prop: "created_by", ability: "spa-userinfo", type: "constraint", title: 'Erstellt von', constraint: { table: 'users' }, edit: false},
    { prop: "modified_by", ability: "spa-userinfo", type: "constraint", title: 'Bearbeitet von', constraint: { table: 'users' }, edit: false},
    { prop: "created_at", ability: "spa-userinfo", type: "date", title: 'Erstellt am', edit: false},
    { prop: "modified_at", ability: "spa-userinfo", type: "date", title: 'Bearbeitet am', edit: false},
  ]
}
